import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import TextArea from "../../../components/TextArea/TextArea";
import { SoundWaveIcon } from "../../../components/Icons/FaceSwapIcons";
import { setTextForAudio } from "../../../redux/actions/faceswapActions";
import { getFaceswapTargetLoading, getTextForAudio } from "../../../redux/reducers/faceswapReducer";

const TEXT_LIMITATION = 300;

const ManipulateCard = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getFaceswapTargetLoading);
  const textFieldValue = useSelector(getTextForAudio);

  const handleTextAreaChange = (e: any) => {
    const value = e.target.value;
    if (value.length <= TEXT_LIMITATION) {
      dispatch(setTextForAudio(value));
    }
  };

  return (
    <Card>
      <Title>
        <SoundWaveIcon />
        <Text>Manipulate</Text>
      </Title>
      <CardContent>
        <ActionsWrapper>Prompt</ActionsWrapper>
        <TextFieldWrapper>
          <TextArea
            value={textFieldValue}
            placeholder="What do you want to change..."
            onChange={handleTextAreaChange}
            disabled={isLoading}
            rows={9}
          />
          <Footer>
            <span>
              {textFieldValue.length}/{TEXT_LIMITATION}
            </span>
          </Footer>
        </TextFieldWrapper>
      </CardContent>
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  flex: 3;
  background: #15171a;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 17px 18px;
  margin-bottom: 18px;
  min-width: 578px;

  @media (max-width: 1200px) {
    min-width: auto;
    padding: 10px 18px 17px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  gap: 10px;

  & > svg {
    width: 25px;
    height: 25px;
  }

  & > :first-child {
    width: 32px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-right: auto;
`;

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 232px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  margin: 0 0 12px;
  width: 100%;
`;

const TextFieldWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  margin-bottom: 22px;

  textarea {
    border-radius: 32px;
    padding: 19px 25px;
    background: #1f2024;
  }
`;

const Footer = styled.div`
  padding: 0 16px 24px 0;
  display: flex;
  width: 90px;
  justify-content: end;
  margin-top: auto;
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: -10px;
  text-align: center;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.primaryText}66;
  }
`;

export default ManipulateCard;
