import Select, { components } from "react-select";
import styled from "styled-components";
import { FC, ReactNode, useState } from "react";
import Voice from "../../VideoTranslation/components/Voice";
import RadioButton from "../../../components/RadioButton/RadioButton";

interface OptionType {
  actorid: number;
  Name: string;
  src?: string;
}

interface Props {
  optionsList: OptionType[];
  value: string | number;
  onChange: (value: string | number) => void;
  disabled?: boolean;
  placeholder?: string;
  actionIcon?: ReactNode;
}

const VoiceSelect: FC<Props> = ({ optionsList, onChange, value, disabled, placeholder, actionIcon }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [activePlaying, setActivePlaying] = useState<number>();

  const customStyles = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 6,
    }),
    control: (provided: any) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: "none",
      border: "1px solid #23406b",
      backgroundColor: "#15171a",
      height: "44px",
      minWidth: "100%",
      maxWidth: "100%",
      cursor: "pointer",

      "&:hover": {
        border: "1px solid #23406b",
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: "16px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      paddingRight: "16px",

      div: {
        padding: 0,
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      svg: {
        transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s",
        path: {
          stroke: "#ffffff",
          strokeWidth: "2px",
        },
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: "12px",
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: "8px",
      maxHeight: "125px",
      border: "1px solid #23406b",
      backgroundColor: "#15171a",
      boxShadow: "none",
      paddingBottom: "2px",
      paddingTop: "2px",

      "@media (max-width: 1001px)": {
        "::-webkit-scrollbar": {
          display: "none",
        },
      },

      "::-webkit-scrollbar": {
        width: "2px",
      },

      "::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#ffffff",
      },

      "::-webkit-scrollbar-track": {
        margin: "15px 0",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#ffffff",

      span: {
        color: "#ffffff",
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#ffffff",
    }),
    input: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#E2E2E3",
    }),
    option: (provided: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      backgroundColor: "transparent",
      padding: "8px 16px",

      "&:active": {
        backgroundColor: "transparent",
        color: "#ffffff",
      },
    }),
  };

  const formatOptionLabel = (option: OptionType, { context }: { context: string }) => (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {context === "menu" && option.src ? (
        <Voice
          src={option?.src}
          title={option?.Name}
          active={option?.actorid === activePlaying}
          onClick={() => setActivePlaying(option?.actorid)}
        />
      ) : (
        option.Name
      )}
      {context === "menu" && (
        <RadioButtonWrapper>
          <RadioButton checked={option.actorid === value} onChange={() => onChange(option.actorid)} />
        </RadioButtonWrapper>
      )}
    </div>
  );

  const handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      onChange(selectedOption.actorid);
    }
  };

  const DropdownIndicator = (props: any) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>{actionIcon}</components.DropdownIndicator>
    );

  return (
    <Select
      value={optionsList.find((option) => option.actorid === value)}
      onChange={handleChange}
      options={optionsList}
      isDisabled={disabled}
      styles={customStyles}
      isSearchable={false}
      placeholder={placeholder}
      menuPlacement="auto"
      menuShouldBlockScroll={true}
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
      components={{ DropdownIndicator }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
};

const RadioButtonWrapper = styled.div`
  margin-left: auto;
`;

const OptionLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default VoiceSelect;
