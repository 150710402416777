import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";

import Tooltip from "../Tooltip/Tooltip";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import Alert from "../../modules/AIHumans/components/Alert";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { pages } from "../../lib/routeUtils";
import { numberWithCommas } from "../../lib/formatUtils";
import { MonitorPlayIcon } from "../Icons/MonitorPlayIcon";
import { getTheme } from "../../redux/reducers/themeReducer";
import { getProfile } from "../../redux/reducers/profileReducer";
import { Status } from "../../redux/actions/humansProjectActions";
import { CallIcon, FolderIcon, LogoIcon, NexoIcon, NotificateIcon } from "../Icons/Icons";

export const formatMinutes = (seconds: number) => {
  const integerMinutes = Math.floor(seconds / 60);
  const integerSeconds = seconds % 60;
  const minutesWording = `${integerMinutes} mins`;
  const secondsWording = integerSeconds > 0 ? `${integerSeconds} s` : ``;
  return `${minutesWording} ${secondsWording}`;
};

interface Props {
  children?: ReactNode;
  startAdornment?: ReactNode;
  tabActions?: ReactNode;
  withThemeSwitcher?: boolean;
}

const Navigation = ({ children, startAdornment, tabActions, withThemeSwitcher = true }: Props) => {
  const { pathname } = useLocation();
  const { profilePic, creditsAllowed = 0, creditsUsed = 0, roleId } = useSelector(getProfile);
  const isGuest = roleId === 3;
  const credits = creditsAllowed - creditsUsed;

  const [source, setSource] = useState("");

  const excludedPaths = ["/actors", "/ai-humans", "/ai-images"];
  const isExcludedPath = excludedPaths.includes(pathname);

  const handleLoadingError = () => {
    setSource("/images/placeholder.png");
  };

  const handleNotification = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.noticeable.do("widget:open", "HhSbAWQvk90PptShlFTC");
  };

  const handlePhone = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    synthesys.open("a09d59ac-6bef-4722-85ce-70b092ebaad1");
  };

  useEffect(() => {
    setSource(profilePic);
  }, [profilePic]);

  return (
    <>
      {isGuest && (
        <AlertWrapper>
          <Alert
            status={Status.isProgress}
            text={
              <>
                You are in guest mode. <Link to="/signup">Sign up</Link> to get access to all functionalities
              </>
            }
          />
        </AlertWrapper>
      )}
      <Wrapper className="navigation">
        {!isExcludedPath &&
        !pathname.includes(pages.video()) &&
        !pathname.includes(pages.transparentPricing()) &&
        !pathname.includes(pages.pricingSubscriptions()) ? (
          <LogoWrapper>
            <Link to="/">
              <LogoIcon />
            </Link>
          </LogoWrapper>
        ) : startAdornment ? (
          <LeftBar>
            {startAdornment}
            {tabActions}
          </LeftBar>
        ) : (
          <></>
        )}
        <Content className="navigation-content">
          {children}
          {withThemeSwitcher && <ThemeSwitcher />}
          {!isExcludedPath && (
            <Actions>
              <Link to="/my-projects">
                <IconButton iconButtonTheme={IconButtonThemes.Secondary} icon={<FolderIcon />} />
                <Tooltip
                  text="My Studio"
                  position="bottom"
                  style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
                  reverseColor
                  arrow
                />
              </Link>
              <Link to={pages.training()}>
                <IconButton iconButtonTheme={IconButtonThemes.Secondary} icon={<MonitorPlayIcon />} />
                <Tooltip
                  text="Training"
                  position="bottom"
                  style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
                  reverseColor
                  arrow
                />
              </Link>
              <div>
                <IconButton
                  onClick={handleNotification}
                  iconButtonTheme={IconButtonThemes.Secondary}
                  icon={<NotificateIcon />}
                  className="notificate"
                />
                <Tooltip
                  text="Updates"
                  position="bottom"
                  style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
                  reverseColor
                  arrow
                />
              </div>
              {/* <div>
                <IconButton
                  onClick={handlePhone}
                  iconButtonTheme={IconButtonThemes.Secondary}
                  icon={<CallIcon />}
                  className="phone"
                />
                <Tooltip
                  text="Call Support"
                  position="bottom"
                  style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
                  reverseColor
                  arrow
                />
              </div> */}
              <CreditsCard credits={credits || 0} />
              {!isGuest && (
                <Link to="/settings">
                  <ImageWrapper>
                    {source ? <img src={source} alt="" onError={handleLoadingError} /> : <div />}
                  </ImageWrapper>
                </Link>
              )}
            </Actions>
          )}
        </Content>
      </Wrapper>
    </>
  );
};

interface CreditsCardProps {
  credits: number;
}

const CreditsCard = ({ credits }: CreditsCardProps) => {
  const theme = useSelector(getTheme);

  return (
    <CreditsCard.Wrapper isLightTheme={theme === "light"}>
      <NexoIcon />
      <span>{numberWithCommas(credits)} Credits</span>
    </CreditsCard.Wrapper>
  );
};

CreditsCard.Wrapper = styled.div<{ isLightTheme?: boolean }>`
  padding: 9px 12px;
  background: ${({ theme }) => theme.iconRect};
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 12px;

  & svg > path {
    fill: ${({ isLightTheme }) => (isLightTheme ? "#4d4e54" : "#ffffff")};
  }

  & span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ isLightTheme }) => (isLightTheme ? "#4d4e54" : "#ffffff")};
  }
`;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > a > svg {
    display: block;
    width: 230px;

    @media (max-width: 500px) {
      width: 150px;
    }
  }

  @media (max-width: 1000px) {
    visibility: hidden;
    height: 0px;
  }
`;

const LogoWrapper = styled.div`
  svg {
    width: 173px;
    height: 37px;
    path {
      fill: #0090f0;
    }
  }

  @media (min-width: 1001px) {
    visibility: hidden;
    width: 0;
  }
`;

const Content = styled("div")`
  display: flex;
  align-items: center;
  height: 88px;
`;

const Actions = styled("div")`
  display: flex;
  align-items: center;
  gap: 12px;

  & > div,
  a {
    position: relative;

    &:hover > div {
      opacity: 1;
      visibility: visible;

      span {
        white-space: pre;
      }
    }
  }

  button {
    box-shadow: none;

    & > svg > rect {
      fill: ${({ theme }) => theme.iconRect};
    }
  }

  .notificate {
    padding: 0 !important;
  }

  .cello {
    position: relative;
  }

  .phone > svg > path {
    fill: #b42628;
  }

  @media (max-width: 1001px) {
    gap: 4px;
  }
`;

const LeftBar = styled.div`
  display: flex;
  column-gap: 105px;
  flex: 1;
`;

const ImageWrapper = styled("div")`
  width: 40px;
  height: 40px;

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d6d6d6;
  }
`;

const AlertWrapper = styled.div`
  position: relative;

  a {
    text-decoration: underline;
    color: #0288d1;
  }
`;

export default Navigation;
