import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../components/Modal/Modal";
import Tooltip from "../../../components/Tooltip/Tooltip";
import MenuItem from "../../../components/ActionMenu/MenuItem";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import CircularProgress from "../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { ArchiveTick, DropdownRepeat, EllipsisIcon, PopupUploadIcon } from "../../../components/Icons/Icons";
import { IImage } from "../../../types/image";
import { AIImagesContext } from "../../../App";
import { getPrefilled } from "../../../redux/reducers/popupsReducer";
import { createGalleryImageServer } from "../../../redux/actions/imagesActions";
import { getImageGalleryLoading } from "../../../redux/reducers/imagesReducer";

interface Props {
  open: boolean;
  onClose: () => void;
}

const EnlargedPicturePopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  // const prefilled = useSelector(getPrefilled);
  const { pic, isView } = useSelector(getPrefilled);
  const isLoading = useSelector(getImageGalleryLoading);
  const { handleUploadedImage } = useContext(AIImagesContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [image, setImage] = useState<IImage>({} as IImage);

  const handleOpenMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuOpen(true);
  };

  const handleCloseMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuOpen(false);
  };

  const handleSaveToAssets = () => {
    dispatch(createGalleryImageServer({ ...image }));
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const copyTextToClipboard = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(image.fileName);
    } else {
      return document.execCommand("copy", true, image.fileName);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard()
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setImage(pic);
  }, [pic]);

  return (
    <Modal maxWidth={480} maxHeight={592} onClose={handleClose} open={open} closeIcon={false}>
      <Wrapper>
        <ImageWrapper>
          <StyledImg src={image.path} alt={image.fileName} />
          {!isView && (
            <ActionWrapper>
              <ActionMenu
                position={{
                  x: "left",
                  y: "bottom",
                }}
                open={menuOpen}
                handleClose={handleCloseMenu}
                trigger={
                  <IconButton
                    iconButtonTheme={IconButtonThemes.Primary}
                    icon={<EllipsisIcon />}
                    onClick={handleOpenMenu}
                  />
                }
              >
                <MenuWrapper>
                  {options.map((option) => (
                    <MenuItem
                      key={option.id}
                      icon={option.icon}
                      onClick={(e) => {
                        e.preventDefault();

                        option.name === "Create variations" && handleUploadedImage(image.path);

                        handleClose();
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuWrapper>
              </ActionMenu>
            </ActionWrapper>
          )}
          {isLoading && (
            <Blur>
              <CircularProgress color="#009af7" />
            </Blur>
          )}
        </ImageWrapper>
        {image.fileName && (
          <Prompt onClick={handleCopyClick}>
            <Tooltip
              text={isCopied ? "Copied!" : "Copy"}
              position="top"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
              reverseColor
              arrow
            />
            <div>{image.fileName}</div>
          </Prompt>
        )}
        <ButtonWrapper>
          <a href={isLoading ? undefined : image.path} download onClick={(e) => isLoading && e.preventDefault()}>
            <DownloadButtonWrapper>
              <Button disabled={isLoading} icon={<PopupUploadIcon />} text="Download" />
            </DownloadButtonWrapper>
          </a>
          {/* {!isView && (
            <SavedButtonWrapper>
              <Button
                icon={<ArchiveTick />}
                disabled={isLoading}
                text={isLoading ? "Loading..." : "Save to assets"}
                onClick={handleSaveToAssets}
                buttonTheme={ButtonThemes.Secondary}
              />
            </SavedButtonWrapper>
          )} */}
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

const options = [
  {
    id: 1,
    name: "Create variations",
    icon: <DropdownRepeat />,
  },
  // {
  //   id: 2,
  //   name: "Rename",
  //   icon: <EditIcon />,
  // },
  // {
  //   id: 3,
  //   name: "Delete",
  //   icon: <TrashIcon />,
  // },
];

const Wrapper = styled.div`
  min-width: 400px;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
`;

const StyledImg = styled.img`
  max-width: 400px;
  height: 400px;
  object-fit: cover;
`;

const ButtonWrapper = styled.div``;

const DownloadButtonWrapper = styled.div`
  margin: 16px 0px 20px 0px;

  button svg {
    margin: 0px 10px 0px 0px;
  }
`;

const SavedButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button span {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 16px;

  button {
    width: 24px;
    height: 24px;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }
`;

const MenuWrapper = styled.div`
  width: 151px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  svg > path {
    fill: none;
    stroke: #0063b4;
  }
`;

const Prompt = styled.div`
  position: relative;
  max-width: 400px;
  margin: 4px auto;
  width: fit-content;

  & > div:last-of-type {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: ${({ theme }) => theme.primaryText};
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  & > div:first-of-type {
    width: max-content;
  }

  &:hover > div:first-of-type {
    opacity: 1;
    visibility: visible;
  }
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  z-index: 2;
`;

export default EnlargedPicturePopup;
