import { aiVoicesDarkTheme, aiVoicesLightTheme } from "./aiVoicesTheme";

export const lightTheme = {
  primaryBackground: "#F0F0F3",
  secondaryBackground: "#191B1F",
  videoTranslationBackground: "#ffffff",
  videoTranslationTabBackground: "#F9F9F9",
  primaryText: "#191B1F",
  secondaryText: "#FFFFFF",
  lightGreyText: "#C5C5C5",
  greyText: "#3B3D47",
  darkText: "#191B1F",
  sidebarIcon: "#3B3D47",
  sidebarMenuText: "#3B3D47",
  sidebarMenuBackground: "#FFFFFF",
  sidebarMenuBackgroundSecondary: "#F4F4F4",
  sidebarMenuActive: "#0286DC",
  sidebarMenuTextActive: "#FFFFFF",
  sidebarMenuShadow: "#FFFFFF",
  signInText: "#191B1F",
  signInputIcon: "#191B1F",
  signInBackground: "#F0F0F3",
  buttonSignIn: "#0286DC",
  reviewBlue: "#0063B4",
  reviewText: "#212122B5",
  activeMenu: "#009AF7",
  selectText: "#ABABB9",
  selectTextActive: "#FFFFFF",
  selectBackground: "#292937",
  button: "linear-gradient(142.13deg, #0063B4 16.78%, #009AF7 85.53%)",
  buttonGreyText: "#6F7074",
  buttonShadow:
    "-5px -5px 5px rgba(255, 255, 255, 0.4), 5px 5px 10px rgba(174, 174, 192, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF",
  secondaryButtonShadow: "inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #ffffff",
  iconButtonShadow:
    "-5px -5px 10px #FFFFFF, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF",
  switcher: "#59596B",
  themeSwitcherShadow: "inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.3)",
  inputShadow: "inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.3)",
  inputBackground: "#F0F0F3",
  inputText: "rgba(33, 33, 34, 0.71)",
  secondaryInputShadow: "inset -2px -2px 4px 2px rgba(35, 37, 41, 0.6), inset 2px 2px 4px rgba(0, 0, 0, 0.4)",
  productCard:
    "linear-gradient(219.73deg, rgba(240, 240, 243, 0) 22.7%, rgba(240, 240, 243, 0.4942) 43.17%, #f0f0f3 90.29%)",
  productCardFilter: "drop-shadow(-5px -5px 10px #ffffff) drop-shadow(5px 5px 10px rgba(174, 174, 192, 0.3))",
  cardShadow: "5px 5px 10px 0px rgba(174, 174, 192, 0.3)",
  secondaryCardShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  thirdCardShadow: "none",
  sliderShadow: "linear-gradient(270deg, #f0f0f3 9.38%, rgba(240, 240, 243, 0) 100%)",
  icon: "#231F20",
  disableIcon: "#23406B",
  enableIcon: "#FFFFFF",
  speedText: "#FF8C00",
  pauseText: "#E859FF",
  styleText: "#779930",
  toneText: "#FF6C76",
  accentText: "#31DFCA",
  emotionsText: "#5FB3FF",
  white: "#FFFFFF",
  scroll: "#f5f5f5",
  scrollShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
  orange: "#ff8c00",
  orangeLight: "#ffe4c4",
  purple: "#e859ff",
  purpleLight: "",
  green: "#779930",
  greenLight: "#b3d866",
  pink: "#ff6c76",
  pinkLight: "#fcc1c5",
  aqua: "#31dfca",
  aquaLight: "#a9f4eb",
  blue: "#5fb3ff",
  blueLight: "#c1e0fc",
  red: "#DC2020",
  sidebarShow: "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #f0f0f3 100%)",
  sidebarShowSecondary: "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F4F4F4 100%)",
  characterActionsBackground:
    "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, rgba(240, 240, 243, 0.6) 21.34%, rgba(240, 240, 243, 0.8126) 42.26%, #f0f0f3 100%)",
  humanCardBackground:
    "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, rgba(240, 240, 243, 0.6) 21.34%, rgba(240, 240, 243, 0.8126) 42.26%, #f0f0f3 100%)",
  characterCardShadow:
    "-5px -5px 10px #ffffff, 5px 5px 10px rgb(174 174 192 / 30%), inset -2px -2px 4px rgb(0 0 0 / 10%), inset 2px 2px 4px #ffffff",
  mainCardBackground: "#989795",
  mainCardBackgroundAfter:
    "linear-gradient(343.57deg, rgba(240, 240, 243, 0.5) 3.04%, rgba(240, 240, 243, 0.4) 39.24%, #f0f0f3 88.86%)",
  mainCardBackgroundAfterExists:
    "linear-gradient(345.82deg, rgba(240, 240, 243, 0) 17.23%, rgba(240, 240, 243, 0.2) 54.98%, rgba(240, 240, 243, 0.7) 90.58%)",
  mainCardPopupBackground:
    "linear-gradient(219.73deg, rgba(240, 240, 243, 0) 22.7%, rgba(240, 240, 243, 0.4942) 43.17%, #f0f0f3 90.29%)",
  mainCardShadow: "inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #ffffff",
  mainCardShadowPricing:
    "inset 4.78723px 4.78723px 3.82979px rgba(174, 174, 192, 0.2), inset -4.78723px -4.78723px 3.82979px rgba(255, 255, 255, 0.3)",
  mainCardFilter: "drop-shadow(-5px -5px 10px #FFFFFF) drop-shadow(5px 5px 10px rgba(174, 174, 192, 0.3))",
  chatHeaderBackground: "#FFFFFF",
  chatText: "#1F1F23",
  messageBackground: "#F7F7F8",
  chatBackground: "#FFFFFF",
  chatShadow:
    "-5px -5px 10px rgba(255, 255, 255, 0.1), 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF",
  chatTextfieldBorder: "#92929C",
  closeChatIcon: "#231f20",
  authBackgroundBefore:
    "linear-gradient(179.23deg, #f0f0f3 -25.59%, rgba(240, 240, 243, 0.7602) 30.82%, rgba(240, 240, 243, 0) 99.34%)",
  authBackgroundAfter:
    "linear-gradient(179.27deg, #f0f0f3 -7.57%, rgba(240, 240, 243, 0.7602) 40.72%, rgba(240, 240, 243, 0) 99.37%)",
  editor: "#ffffff",
  editorShadow:
    "-5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #ffffff",
  editorLineBorder: "#ededef",
  editorDropDownContent: "#f6f6f6",
  editorButtonShadow:
    "-3.7037px -3.7037px 7.40741px #ffffff, 3.7037px 3.7037px 7.40741px rgba(174, 174, 192, 0.3), inset -1.48148px -1.48148px 2.96296px rgba(0, 0, 0, 0.1), inset 1.48148px 1.48148px 2.96296px #ffffff",
  editorFileUpload: "#757679",
  editorMenuShadow: "10px 12px 60px rgba(52, 52, 57, 0.16), inset -2px -2px 4px rgba(0, 0, 0, 0.1)",
  menuListItemActive: "#F6F6F6",
  sidebarDropdownMenu: "#E7E7EB",
  visualiseSearchbar: "inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.4)",
  homeTabs: `linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, rgba(240, 240, 243, 0.5) 25.4%, rgba(240, 240, 243, 0.8) 46.98%, #F0F0F3 112.35%)`,
  homeTabsShadow: `5px 5px 10px 0px #aeaec04d, -5px -5px 10px 0px #ffffff, 2px 2px 4px 0px #ffffff inset,
    -2px -2px 4px 0px #0000001a inset`,
  homeCardContentShadow: `5px 5px 10px 0px #aeaec04d, -5px -5px 10px 0px #ffffff, 2px 2px 4px 0px #ffffff inset,
    -2px -2px 4px 0px #0000001a inset`,
  homeCardWrapperShadow: `-4.787234306335449px -4.787234306335449px 3.829787015914917px 0px #ffffff4d inset,
    4.787234306335449px 4.787234306335449px 3.829787015914917px 0px #aeaec033 inset`,
  homeTabsAditionalShadow: `5px 5px 10px 0px #aeaec04d, -5px -5px 10px 0px #ffffff, 2px 2px 4px 0px #ffffff inset,
        -2px -2px 4px 0px #0000001a inset`,
  tableTitleText: "#191B1F",
  tableText: "#191B1FBA",
  tableBackground: "#ffffff",
  tableDivider: "#DFDFDF",
  tooltipBackground: "rgba(97, 97, 97, 0.92)",
  createNewProjectPopupShadow: `5px 5px 10px 0px #aeaec04d, -5px -5px 10px 0px #ffffff, 2px 2px 4px 0px #ffffff inset,
        -2px -2px 4px 0px #0000001a inset`,
  createNewProjectPopupBackground: `linear-gradient(
        180.73deg,
        rgba(240, 240, 243, 0) 56.7%,
        rgba(240, 240, 243, 0.4942) 70.17%,
        #f0f0f3 90.29%
      )`,
  videoTranslationBorder: "1px solid #D2D2D9",
  videoTranslationInput: "#f9f9f9",
  videoTranslationGenerete: "#f9f9f9",
  videoTranslationProgressBar: "#dfdfe4",
  videoTranslationCanselBackground: `linear-gradient(
      241.81deg,
      rgba(240, 240, 243, 0) -102.09%,
      rgba(240, 240, 243, 0.4942) -62.52%,
      #f0f0f3 67.02%
    )`,
  videoTranslationCanselShadow: `5px 5px 10px 0px #AEAEC04D, -5px -5px 10px 0px #FFFFFF, 2px 2px 4px 0px #FFFFFF inset, -2px -2px 4px 0px #0000001A inset`,
  videoTranslationGray: "#D2D2D9",
  videoTransaltionUpload: "#F9F9F9",
  homeContentScroll: "#E2E6EE",
  homeContentCardBg: "#FFFFFF",
  homeContentCardBgHover: "#0063B4",
  homeContentCardText: "#191B1FBA",
  homeContentCardTextHover: "#FFFFFF",
  homeContentCardIcon: "#0063B4",
  homeContentCardIconHover: "#F0F0F3",
  homeContentCardIconInner: "#FFFFFF",
  homeContentCardIconInnerHover: "#0063B4",
  homeContentCardTitle: "#191B1FBA",
  homeSectionTabs: "#6F7074",
  homeSectionTabsShadow: "0px 4px 4px 0px #00000040",
  homePrimaryBackground: "#f6f6f6",
  homeSectionGradientButton: "#00B8E0",
  voiceCardBackground: "#FFFFFF",
  voiceCardShadow: "0px 4px 4px 0px #00000040",
  voiceProgressbarShadow: " -5px -5px 4px 0px #ffffff4d inset, 5px 5px 4px 0px #aeaec033 inset",
  voiceProgressbarBackground: "white",
  hoverVoiceCardBackground: "#0063B4",
  progressBarLineDisabled: "#35354A",
  myStudioSwitchBackground: "#FFFFFF",
  myStudioSwitchTextSecond: "#35354A",
  iconRect: "#fff",
  ...aiVoicesLightTheme,
};

export const darkTheme = {
  primaryBackground: "#191B1F",
  secondaryBackground: "#F0F0F3",
  videoTranslationBackground: "#191B1F",
  videoTranslationTabBackground: "#272A30",
  primaryText: "#FFFFFF",
  secondaryText: "#191B1F",
  lightGreyText: "#C5C5C5",
  greyText: "#6F7074",
  darkText: "#191B1F",
  sidebarIcon: "#FFFFFF",
  sidebarMenuText: "#FFFFFF",
  sidebarMenuBackground: "#212122B5",
  sidebarMenuBackgroundSecondary: "#F4F4F4",
  sidebarMenuActive: "#0286DC",
  sidebarMenuTextActive: "#FFFFFF",
  sidebarMenuShadow: "#00000040",
  signInText: "#191B1F",
  signInputIcon: "#231f20",
  signInBackground: "#212122",
  reviewBlue: "#0063B4",
  reviewText: "#212122B5",
  selectText: "#FFFFFF",
  selectTextActive: "#FFFFFF",
  selectBackground: "#ABABB9",
  buttonText: "#FFFFFF",
  button: "linear-gradient(142.13deg, #0063B4 16.78%, #009AF7 85.53%)",
  buttonSignIn: "#0286DC",
  buttonGreyText: "#6F7074",
  buttonShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  // secondaryButtonShadow: "inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #ffffff",
  iconButtonShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  switcher: "#767676",
  themeSwitcherShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  inputShadow: "inset -2px -2px 4px 2px rgba(35, 37, 41, 0.6), inset 2px 2px 4px rgba(0, 0, 0, 0.4)",
  inputBackground: "#F0F0F3",
  inputText: "rgba(33, 33, 34, 0.71)",
  secondaryInputShadow: "inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.3)",
  productCard:
    "linear-gradient(219.73deg, rgba(240, 240, 243, 0) 22.7%, rgba(240, 240, 243, 0.4942) 43.17%, #F0F0F3 90.29%)",
  productCardFilter: "drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.6)) drop-shadow(-4px -4px 8px rgba(73, 73, 73, 0.2))",
  cardShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  secondaryCardShadow:
    "-5px -5px 10px #FFFFFF, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF",
  thirdCardShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  sliderShadow: "linear-gradient(270deg, #191B1F 9.38%, rgba(25, 27, 31, 0) 100%)",
  icon: "#FFFFFF",
  disableIcon: "#35363A",
  enableIcon: "#FFFFFF",
  speedText: "#FF8C00",
  pauseText: "#E859FF",
  styleText: "#779930",
  toneText: "#FF6C76",
  accentText: "#31DFCA",
  emotionsText: "#5FB3FF",
  activeMenu: "#009AF7",
  white: "#FFFFFF",
  scroll: "#f5f5f5",
  scrollShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
  orange: "#ff8c00",
  orangeLight: "#ffe4c4",
  purple: "#e859ff",
  purpleLight: "",
  green: "#779930",
  greenLight: "#b3d866",
  pink: "#ff6c76",
  pinkLight: "#fcc1c5",
  aqua: "#31dfca",
  aquaLight: "#a9f4eb",
  blue: "#5fb3ff",
  blueLight: "#c1e0fc",
  red: "#DC2020",
  sidebarShow: "linear-gradient(180deg, rgba(25, 27, 31, 0) 0%, #191B1F 100%)",
  sidebarShowSecondary: "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F4F4F4 100%)",
  characterActionsBackground:
    "linear-gradient(180deg, rgba(25, 27, 31, 0) 0%, rgba(25, 27, 31, 0.6) 21.34%, rgba(25, 27, 31, 0.8126) 42.26%, #191B1F 100%)",
  characterCardShadow: "inset -2px -2px 4px 2px rgb(35 37 41 / 60%), inset 2px 2px 4px rgb(0 0 0 / 40%)",
  mainCardBackground: "#989795",
  mainCardBackgroundAfter:
    "linear-gradient(343.57deg, rgba(240, 240, 243, 0.5) 3.04%, rgba(240, 240, 243, 0.4) 39.24%, #f0f0f3 88.86%)",
  mainCardBackgroundAfterExists:
    "linear-gradient(345.82deg, rgba(240, 240, 243, 0) 17.23%, rgba(240, 240, 243, 0.2) 54.98%, rgba(240, 240, 243, 0.7) 90.58%)",
  mainCardPopupBackground:
    "linear-gradient(219.73deg, rgba(240, 240, 243, 0) 22.7%, rgba(240, 240, 243, 0.4942) 43.17%, #f0f0f3 90.29%)",
  mainCardShadow: "inset 2px 2px 10px rgba(56, 56, 67, 0.7)",
  mainCardShadowPricing: "inset 2px 2px 10px rgba(56, 56, 67, 0.7)",
  mainCardFilter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.3))",
  chatHeaderBackground: "#17181B",
  chatText: "#FFFFFF",
  messageBackground: "#232528",
  chatBackground: "#1A1C20",
  chatShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), 4px 4px 8px rgba(0, 0, 0, 0.6)",
  chatTextfieldBorder: "#2D2F33",
  closeChatIcon: "#FFFFFF",
  authBackgroundBefore: "transparent",
  authBackgroundAfter: "transparent",
  editor: "#191B1F",
  editorShadow: "inset -2px -2px 4px 2px rgba(35, 37, 41, 0.6), inset 2px 2px 4px rgba(0, 0, 0, 0.4)",
  editorLineBorder: "#292C32",
  editorDropDownContent: "#1F2125",
  editorButtonShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  editorFileUpload: "#515763",
  editorMenuShadow: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  menuListItemActive: "#25272C",
  sidebarDropdownMenu: "#25272C",
  visualiseSearchbar: "inset -2px -2px 4px 2px rgba(35, 37, 41, 0.6), inset 2px 2px 4px rgba(0, 0, 0, 0.4)",
  homeTabs: `linear-gradient(180deg, rgba(25, 27, 31, 0) 4.38%, rgba(25, 27, 31, 0.5) 63.25%, rgba(25, 27, 31, 0.8126) 109.99%, #191B1F 185.17%)`,
  homeTabsShadow: "nonе",
  homeCardContentShadow: `-4px -4px 8px 0px #49494933, 4px 4px 8px 0px #00000099`,
  homeCardWrapperShadow: ` 2px 2px 4px 0px #00000066 inset, -2px -2px 4px 2px #23252999 inset`,
  homeTabsAditionalShadow: ` -4px -4px 8px 0px #49494933, 4px 4px 8px 0px #00000099`,
  tableTitleText: "#FFFFFF",
  tableText: "#FFFFFF",
  tableBackground: "#292D32",
  tableDivider: "#4D4E54",
  tooltipBackground: "rgba(145,145,145,0.92)",
  createNewProjectPopupShadow: `4px -4px 8px 0px #49494933, 4px 4px 8px 0px #00000099`,
  createNewProjectPopupBackground: `linear-gradient( 180deg, rgba(25,27,31,0) 74.38%, rgba(25,27,31,0.5) 96.25%, rgba(25,27,31,0.8126) 109.99%, #191b1f 185.17% )`,
  videoTranslationBorder: "1px solid #3E4453",
  videoTranslationInput: "#272a30",
  videoTranslationGenerete: "#181b1f",
  videoTranslationProgressBar: "#3b3d47",
  videoTranslationCanselBackground: `#191B1F`,
  videoTranslationCanselShadow: `-4px -4px 8px 0px #49494933l, 4px 4px 8px 0px #00000099`,
  videoTranslationGray: "#3B3D47",
  videoTransaltionUpload: "#191B1F",
  homeContentScroll: "#212223",
  homeContentCardBg: "#212122B5",
  homeContentCardBgHover: "#231F20",
  homeContentCardText: "#FFFFFF",
  homeContentCardTextHover: "#FFFFFF",
  homeContentCardIcon: "#00B8E0",
  homeContentCardIconHover: "#00B8E0",
  homeContentCardIconInner: "#FFFFFF",
  homeContentCardIconInnerHover: "#FFFFFF",
  homeSectionTabs: "#FFFFFF",
  homeSectionTabsShadow: "-4px -4px 8px 0px #49494933, 4px 4px 8px 0px #00000099",
  homeSectionGradientButton: "#FFFFFF",
  homePrimaryBackground: "#2a2a2b",
  voiceCardBackground: "#292D32",
  voiceCardShadow: "-4px -4px 8px 0px #49494933, 4px 4px 8px 0px #00000099",
  voiceProgressbarShadow: "2px 2px 4px 0px #00000066 inset, -2px -2px 4px 2px #23252999 inset",
  //voiceProgressbarBackground: "white",
  hoverVoiceCardBackground: "#010307",
  progressBarLineDisabled: "#35354A",
  myStudioSwitchBackground: "#292D32",
  myStudioSwitchTextSecond: "#FFFFFF",
  iconRect: "#292D32",
  ...aiVoicesDarkTheme,
};
