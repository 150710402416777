import { put, select, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  CHANGE_PASSWORD_SERVER,
  CLONE_VOICE_SERVER,
  getMyProfileServer,
  GET_MY_PROFILE_SERVER,
  updateProfile,
  updateProfileLoading,
  UPDATE_PROFILE_SERVER,
  uploadProfilePic,
  UPLOAD_PROFILE_PIC_SERVER,
  GENERATE_API_KEY_SERVER,
  DELETE_ACCOUNT_SERVER,
  GET_USER_HISTORY_PROJECTS_SERVER,
  updateHasMoreUserHistoryProjects,
  DELETE_HISTORY_PROJECT_SERVER,
  getUserHistoryProjectsServer,
  UPDATE_HISTORY_PROJECT_SERVER,
} from "../actions/profileActions";
import { ProfileModules } from "../../types/profile";
import { Popups, updatePopup } from "../actions/popupsActions";
import { getProfile, getUserHistoryProjects } from "../reducers/profileReducer";
import { GET_CELLO_TOKEN_SERVER, Oauth, getCelloTokenServer, logout } from "../actions/authActions";
import { getOauth } from "../reducers/authReducer";
import { getHumansProject } from "../actions/humansProjectActions";

const profileSagas = [
  takeEvery(`${CHANGE_PASSWORD_SERVER}`, handleChangePassword),
  takeEvery(`${CHANGE_PASSWORD_SERVER}_FAIL`, handleChangePasswordFail),
  takeEvery(`${CHANGE_PASSWORD_SERVER}_SUCCESS`, handleChangePasswordSuccess),

  takeEvery(UPDATE_PROFILE_SERVER, updateProfileHandler),
  takeEvery(`${UPDATE_PROFILE_SERVER}_SUCCESS`, updateProfileSuccessHandler),
  takeEvery(`${UPDATE_PROFILE_SERVER}_FAIL`, updateProfileFailHandler),

  takeEvery(`${UPLOAD_PROFILE_PIC_SERVER}_SUCCESS`, uploadProfilePicSuccessHandler),
  takeEvery(`${UPLOAD_PROFILE_PIC_SERVER}_FAIL`, uploadProfilePicFailHandler),

  takeEvery(`${GET_MY_PROFILE_SERVER}`, getMyProfileServerHandler),
  takeEvery(`${GET_MY_PROFILE_SERVER}_SUCCESS`, getMyProfileServerSuccessHandler),
  takeEvery(`${GET_MY_PROFILE_SERVER}_FAIL`, getMyProfileServerFailHandler),
  // takeEvery(`${DELETE_PROFILE_PIC_SERVER}_SUCCESS`, deleteProfilePicSuccessHandler),
  // takeEvery(`${DELETE_PROFILE_PIC_SERVER}_FAIL`, deleteProfilePicFailHandler),
  takeEvery(CLONE_VOICE_SERVER, cloneVoiceServerHandler),
  takeEvery(`${CLONE_VOICE_SERVER}_FAIL`, cloneVoiceServerFailHandler),
  takeEvery(`${CLONE_VOICE_SERVER}_SUCCESS`, cloneVoiceServerSuccessHandler),

  takeEvery(`${GENERATE_API_KEY_SERVER}`, handleGenerateApiKey),
  takeEvery(`${GENERATE_API_KEY_SERVER}_FAIL`, handleGenerateApiKeyFail),
  takeEvery(`${GENERATE_API_KEY_SERVER}_SUCCESS`, handleGenerateApiKeySuccess),

  takeEvery(`${DELETE_ACCOUNT_SERVER}`, handleDeleteAccount),
  takeEvery(`${DELETE_ACCOUNT_SERVER}_FAIL`, handleDeleteAccountFail),
  takeEvery(`${DELETE_ACCOUNT_SERVER}_SUCCESS`, handleDeleteAccountSuccess),

  takeEvery(`${GET_USER_HISTORY_PROJECTS_SERVER}`, handleGetUserHistoryProjectsServer),
  takeEvery(`${GET_USER_HISTORY_PROJECTS_SERVER}_FAIL`, handleGetUserHistoryProjectsServerFail),
  takeEvery(`${GET_USER_HISTORY_PROJECTS_SERVER}_SUCCESS`, handleGetUserHistoryProjectsServerSuccess),

  takeEvery(`${DELETE_HISTORY_PROJECT_SERVER}_FAIL`, handleDeleteHistoryProjectServerFail),
  takeEvery(`${DELETE_HISTORY_PROJECT_SERVER}_SUCCESS`, handleDeleteHistoryProjectServerSuccess),

  takeEvery(UPDATE_HISTORY_PROJECT_SERVER, handleUpdateHistoryProjectServer),
  takeEvery(`${UPDATE_HISTORY_PROJECT_SERVER}_FAIL`, handleUpdateHistoryProjectServerFail),
  takeEvery(`${UPDATE_HISTORY_PROJECT_SERVER}_SUCCESS`, handleUpdateHistoryProjectServerSuccess),
];

function* handleChangePassword() {
  yield put(updateProfileLoading({ module: ProfileModules.changePassword, isLoading: true }));
}

function* handleChangePasswordFail() {
  yield toast.error("Changing password was failed");
  yield put(updateProfileLoading({ module: ProfileModules.changePassword, isLoading: false }));
}

function* handleChangePasswordSuccess(action: any) {
  const { succeeded, message } = action.payload.data;

  if (succeeded) {
    // yield toast.success(message);
    yield put(updateProfileLoading({ module: ProfileModules.changePassword, isLoading: false }));
  }
}

function* updateProfileHandler() {
  yield put(updateProfileLoading({ module: ProfileModules.updateProfile, isLoading: true }));
}

function* updateProfileSuccessHandler(action: any) {
  const { country, language, timePreference, name, email, contactNo, receiveUpdates } = action.payload.data.data;
  yield put(updateProfile({ country, language, timePreference, name, email, contactNo, receiveUpdates }));

  yield put(getMyProfileServer());
  yield put(updateProfileLoading({ module: ProfileModules.updateProfile, isLoading: false }));
  yield toast.success("Update profile successfully");
}

function* updateProfileFailHandler() {
  yield put(updateProfileLoading({ module: ProfileModules.updateProfile, isLoading: false }));
  yield toast.error("Update profile was failed");
}

function* uploadProfilePicSuccessHandler(action: any) {
  const profilePic = action.meta.previousAction.payload.request.data;
  yield put(uploadProfilePic(profilePic));

  yield put(getMyProfileServer());
  // yield toast.success("Upload profile picture successfully");
}

function* uploadProfilePicFailHandler() {
  yield toast.error("Upload profile picture was failed");
}

// function* deleteProfilePicSuccessHandler(action: any) {
//   const profilePic = action.meta.previousAction.payload.request.data;
//   yield put(uploadProfilePic(profilePic));

//   yield put(getMyProfileServer());
//   yield toast.success("Delete profile picture successfully");
// }

// function* deleteProfilePicFailHandler() {
//   yield toast.error("Delete profile picture was failed");
// }

function* getMyProfileServerHandler(action: any) {
  const { checkCloneVoice, checkCustomAvatar } = action.payload;

  if (checkCloneVoice) {
    yield put(updateProfileLoading({ module: ProfileModules.checkCloneVoice, isLoading: true }));
  }

  if (checkCustomAvatar) {
    yield put(updateProfileLoading({ module: ProfileModules.checkCustomAvatar, isLoading: true }));
  }

  yield put({ type: GET_CELLO_TOKEN_SERVER });
  yield put(updateProfileLoading({ module: ProfileModules.myProfile, isLoading: true }));
  yield put(updateProfileLoading({ module: ProfileModules.loadingPage, isLoading: true }));
}

function* getMyProfileServerFailHandler() {
  yield put(updateProfileLoading({ module: ProfileModules.myProfile, isLoading: false }));
  yield put(updateProfileLoading({ module: ProfileModules.loadingPage, isLoading: false }));
}

function* getMyProfileServerSuccessHandler(action: any) {
  const { checkCloneVoice, checkCustomAvatar } = action.meta.previousAction.payload;
  const { voiceCloneAllowed = 0, voiceCloneUsed = 0 } = getProfile(yield select());

  if (checkCloneVoice) {
    if (voiceCloneAllowed > voiceCloneUsed) yield put(updatePopup({ popup: Popups.addVoiceAudioPopup, status: true }));
    else yield toast.error("You already cloned voices for this account");

    yield put(updateProfileLoading({ module: ProfileModules.checkCloneVoice, isLoading: false }));
  }

  if (checkCustomAvatar) {
    yield put(updatePopup({ popup: Popups.addCustomAvatarPopup, status: true }));
    yield put(updateProfileLoading({ module: ProfileModules.checkCustomAvatar, isLoading: false }));
  }

  yield put(getCelloTokenServer());
  yield put(updateProfileLoading({ module: ProfileModules.myProfile, isLoading: false }));
  yield put(updateProfileLoading({ module: ProfileModules.loadingPage, isLoading: false }));
}

function* cloneVoiceServerHandler() {
  yield put(updateProfileLoading({ module: ProfileModules.cloneVoice, isLoading: true }));
}

function* cloneVoiceServerFailHandler() {
  yield toast.error("The cloning of the voice failed");
  yield put(updateProfileLoading({ module: ProfileModules.cloneVoice, isLoading: false }));
}

function* cloneVoiceServerSuccessHandler(action: any) {
  const { redirect_uri, state } = getOauth(yield select());
  const { name, isChatGpt } = action.meta.previousAction.payload;
  const { userToken } = getProfile(yield select());
  yield toast.success(action.payload.data.message);

  yield put(updatePopup({ popup: Popups.addVoiceAudioPopup, status: false }));
  yield put(updateProfileLoading({ module: ProfileModules.cloneVoice, isLoading: false }));

  yield put(
    updatePopup({
      popup: Popups.addVoiceAudioInfoPopup,
      status: true,
      prefilled: {
        actorName: action.payload.data.data.name,
        actorId: action.payload.data.data.actorId,
        isChatGpt,
      },
    }),
  );

  if (redirect_uri && state) {
    // window.location.href = `${redirect_uri}?state=A${state}&code=${userToken}`;
    yield Oauth({ redirect_uri: "", state: "" });
    return;
  }
}

function* handleGenerateApiKey() {
  yield put(updateProfileLoading({ module: ProfileModules.generateApiKey, isLoading: true }));
}

function* handleGenerateApiKeyFail() {
  yield toast.error("The api key generation failed");
  yield put(updateProfileLoading({ module: ProfileModules.generateApiKey, isLoading: false }));
}

function* handleGenerateApiKeySuccess() {
  yield put(updateProfileLoading({ module: ProfileModules.generateApiKey, isLoading: false }));
}

function* handleDeleteAccount() {
  yield put(updateProfileLoading({ module: ProfileModules.deleteAccount, isLoading: true }));
}

function* handleDeleteAccountFail() {
  yield toast.error("Failed to delete account");
  yield put(updateProfileLoading({ module: ProfileModules.deleteAccount, isLoading: false }));
}

function* handleDeleteAccountSuccess() {
  yield toast.success("Account deleted successfully");
  yield put(updateProfileLoading({ module: ProfileModules.deleteAccount, isLoading: false }));
  yield put(logout());
}

function* handleGetUserHistoryProjectsServer() {
  yield put(updateProfileLoading({ module: ProfileModules.userHistoryProjects, isLoading: true }));
}

function* handleGetUserHistoryProjectsServerSuccess(action: any) {
  const allUserHistoryProjects = getUserHistoryProjects(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allUserHistoryProjects.length >= totalLength) yield put(updateHasMoreUserHistoryProjects({ hasMore: false }));

  yield put(updateProfileLoading({ module: ProfileModules.userHistoryProjects, isLoading: false }));
}

function* handleGetUserHistoryProjectsServerFail() {
  yield put(updateProfileLoading({ module: ProfileModules.userHistoryProjects, isLoading: false }));
}

function* handleDeleteHistoryProjectServerFail(action: any) {
  const { previousFill } = action.meta.previousAction.payload;

  yield toast.error("Error while deleting project");
  if (previousFill) {
    yield put(updatePopup({ popup: Popups.historyPopup, status: true, prefilled: previousFill }));
  }
}

function* handleDeleteHistoryProjectServerSuccess(action: any) {
  const { option, pageSize } = action.meta.previousAction.payload;

  yield put(updatePopup({ popup: Popups.historyPopup, status: false, prefilled: {} }));
  yield put(getUserHistoryProjectsServer({ pageNumber: 1, pageSize: pageSize || 20, category: option || "" }));
}

function* handleUpdateHistoryProjectServer(action: any) {
  const { id, category, title } = action.payload.request.data;
  const { isHumanProject } = action.payload;

  if (isHumanProject) {
    const allProjects = getUserHistoryProjects(yield select());
    const project = allProjects.find((project) => project.id === id && project.category === category);

    if (project) {
      yield put(
        getHumansProject({
          projectId: project.id,
          title,
          coverImage: project.coverImage,
          insertDateTime: project.insertDateTime,
          output: project.output,
        }),
      );
    }
  }
}

function* handleUpdateHistoryProjectServerSuccess(action: any) {
  const { id, category, title } = action.meta.previousAction.payload.request.data;
  const { isHumanProject } = action.meta.previousAction.payload;

  if (isHumanProject) {
    const allProjects = getUserHistoryProjects(yield select());
    const project = allProjects.find((project) => project.id === id && project.category === category);

    if (project) {
      yield put(
        getHumansProject({
          projectId: project.id,
          title,
          coverImage: project.coverImage,
          insertDateTime: project.insertDateTime,
          output: project.output,
        }),
      );
    }
  }
}

function* handleUpdateHistoryProjectServerFail(action: any) {
  const { id, category } = action.meta.previousAction.payload.request.data;
  const { oldTitle, isHumanProject } = action.meta.previousAction.payload;

  if (isHumanProject) {
    const allProjects = getUserHistoryProjects(yield select());
    const project = allProjects.find((project) => project.id === id && project.category === category);

    if (project) {
      yield put(
        getHumansProject({
          projectId: project.id,
          title: oldTitle,
          coverImage: project.coverImage,
          insertDateTime: project.insertDateTime,
          output: project.output,
        }),
      );
    }
  }
}

export default profileSagas;
