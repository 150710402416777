import { FC, ReactNode, useState } from "react";
import styled from "styled-components";
import ArrowDown from "../Icons/ArrowDown";

interface ISelectProps {
  optionsList: Record<string, any>;
  defaultValueText: string;
  value: string | number | boolean | null;
  onChange: (value: any, type: string) => void;
  variant?: "settings" | "popup";
  disabled?: boolean;
  actionIcon?: ReactNode;
  secondaryLabel?: string;
}

const Select: FC<ISelectProps> = ({
  optionsList,
  defaultValueText,
  onChange,
  value,
  variant = "settings",
  disabled,
  actionIcon,
  secondaryLabel,
}) => {
  const [openList, setOpenList] = useState<boolean>(false);
  const currentOption = optionsList.data.find(({ value: item }: any) => item === value);

  const handleClick = () => {
    if (disabled) return;

    setOpenList(!openList);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();

    setOpenList(false);
  };

  return (
    <>
      <SelectorContainer
        isListOpen={openList}
        hasLabel={optionsList?.secondaryDropdownStyle ? false : !!optionsList.label}
        disabled={disabled}
        className="selector-container"
      >
        {(currentOption?.label || optionsList?.label) && (
          <SelectorLabelsWrapper className="selector-labels">
            <SelectorLabel variant={variant}>{optionsList.label}</SelectorLabel>
            {/* {(secondaryLabel || currentOption?.secondaryLabel) && (
              <SelectorLabel variant={variant}>{secondaryLabel || currentOption.secondaryLabel}</SelectorLabel>
            )} */}
          </SelectorLabelsWrapper>
        )}
        <Selector onClick={handleClick} disabled={disabled}>
          <Row>
            {currentOption?.icon && <Icon src={currentOption.icon} />}
            <SelectorDefaultText isValueExist={value !== ""}>
              {currentOption?.label || defaultValueText}
            </SelectorDefaultText>
          </Row>
        </Selector>
        {openList && (
          <SelectorOptionList className="select-option-list">
            {optionsList.data.map((option: Record<string, any>) => (
              <SelectorOptionItem
                active={option.value === value}
                onClick={() => {
                  onChange(option.value, optionsList.id);
                  setOpenList(!openList);
                }}
                key={option.id}
                id={option.value}
              >
                <Row>
                  {option?.icon && <Icon src={option.icon} />}
                  {option.label}
                  {option?.button}
                  {option.value === value && option?.endAddornment}
                </Row>
              </SelectorOptionItem>
            ))}
          </SelectorOptionList>
        )}
        {actionIcon ? actionIcon : <ArrowDown />}
      </SelectorContainer>
      {openList && <ClosePanel onClick={handleCloseMenu} />}
    </>
  );
};

const SelectorContainer = styled.div<{ isListOpen?: boolean; hasLabel: boolean; disabled?: boolean }>`
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    right: 15px;
    top: ${({ hasLabel }) => (hasLabel ? "32px" : "7px")};
    appearance: none;
    pointer-events: none;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    opacity: 0.5;
  `}

  ${({ isListOpen, hasLabel }) =>
    isListOpen &&
    `
    svg {
      position: absolute;
      right: 15px;
      top: ${hasLabel ? "32px" : "7px"};
      transform: rotate(180deg);
    }
  `}
`;

const SelectorOptionList = styled("div")`
  background-color: ${({ theme }) => theme.selectBackground};
  // box-shadow: ${({ theme }) => theme.inputShadow};
  height: auto;
  max-height: 150px;
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  z-index: 25;
  padding: 7px 16px;
  gap: 8px;
  align-items: flex-start;

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const SelectorOptionItem = styled.span<{ active?: boolean }>`
  position: relative;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.selectText};
  opacity: 0.7;
  cursor: pointer;
  ${({ active, theme }) =>
    active &&
    `
      //  border: 1px solid ${theme.reviewBlue};
       border-radius: 12px;
       color: ${theme.selectTextActive};
       opacity: 1;
       `}
`;

const SelectorLabel = styled("label")<{ variant?: "settings" | "popup" }>`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryText};
  display: flex;
  margin-bottom: 4px;

  ${({ variant }) =>
    variant === "popup" &&
    `
      font-weight: 500;
      opacity: 1;
  `}
`;

const SelectorDefaultText = styled.p<{ isValueExist?: boolean }>`
  opacity: 0.6;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};

  ${({ isValueExist }) =>
    isValueExist &&
    `
      opacity: 1;
  `}
`;

const Selector = styled("div")<{ disabled?: boolean }>`
  background-color: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 60px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: 48px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.5;
  `}
`;

const Row = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const Icon = styled.img`
  height: 12px;
`;

const SelectorLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ClosePanel = styled.div`
  position: fixed;
  z-index: 24;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default Select;
