import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Tabs from "./components/Tabs";
import Select from "../../components/Select/Select";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import CircularProgress from "../../components/Icons/CircularProgress";
import GalleryImageCard from "../../components/ProjectCard/GalleryImageCard";
import GalleryVideoCard from "../../components/ProjectCard/GalleryVideoCard";
import ProjectCardLarge from "../../components/ProjectCard/ProjectCardLarge";
import AIImagesGroupedCard from "../../components/ProjectCard/AIImagesGroupedCard";
import { Project } from "../../types/project";
import { useMyStudio } from "../../hooks/useMyStudio";
import { BlueArrowDown } from "../../components/Icons/Icons";
import { getProfile } from "../../redux/reducers/profileReducer";
import { AIImagesGroupedRecords, GalleryImage, GalleryVideo } from "../../types/image";

export enum AIHumansStatus {
  All = 0,
  Draft = 1,
  InProgress = 2,
  Completed = 3,
  Failure = 4,
}

export enum ProjectsDisplayMode {
  aiHuman = "ai-humans",
  aiVoices = "actors",
  aiImages = "ai-images",
  aiVideos = "ai-videos",
}

const projectDisplayModeTabs = [
  {
    value: ProjectsDisplayMode.aiHuman,
    title: "Humans",
  },
  {
    value: ProjectsDisplayMode.aiVoices,
    title: "Voices",
  },
  {
    value: ProjectsDisplayMode.aiImages,
    title: "Images",
  },
  {
    value: ProjectsDisplayMode.aiVideos,
    title: "Videos",
  },
];

const statusOptions = {
  data: [
    {
      id: "1",
      label: "All",
      value: AIHumansStatus.All,
    },
    {
      id: "2",
      label: "Completed",
      value: AIHumansStatus.Completed,
    },
    {
      id: "3",
      label: "Draft",
      value: AIHumansStatus.Draft,
    },
  ],
};

const sortByDateOptions = {
  data: [
    {
      id: "1",
      label: "Newest to Oldest",
      value: true,
    },
    {
      id: "2",
      label: "Oldest to Newest",
      value: false,
    },
  ],
};

const MyStudio = () => {
  const profile = useSelector(getProfile);

  const {
    sortByDesc,
    featuresList: { list, isLoading, hasMore, currentPage, isSort },
    isStatusLoading,
    activeProject,
    selectedStatus,
    activeProjectTypeId,
    onSelectedStatusChanges,
    onSortByDescChanges,
    handleActiveProject,
    getProjectListFunc,
    getAIImagesFunc,
    getGalleryVideosFunc,
    setActiveProject,
    handleActiveDisplayMode,
    activeDisplayMode,
  } = useMyStudio({ projectDisplayModeTabs });

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <MobileOnly>
            <MyStudioTitle>My Studio</MyStudioTitle>
          </MobileOnly>
          <div style={{ width: "100%" }}>
            <MobileOnly>
              <Sidebar mobile />
            </MobileOnly>
            <MyStudioTitleAndActionsWrapper>
              <DesktopOnly>
                <MyStudioTitle>My Studio</MyStudioTitle>
              </DesktopOnly>
              <TopActionWrapper>
                <TabsWrapper>
                  {profile.allowToAIHuman && (
                    <Tabs
                      data={projectDisplayModeTabs}
                      active={activeDisplayMode}
                      handleActive={handleActiveDisplayMode}
                    />
                  )}
                </TabsWrapper>
                <SelectWrapper
                  isValueExist={
                    (activeDisplayMode === ProjectsDisplayMode.aiHuman && selectedStatus !== null) ||
                    (activeDisplayMode !== ProjectsDisplayMode.aiHuman &&
                      (sortByDesc[activeDisplayMode] !== null || sortByDesc[activeDisplayMode] !== undefined))
                  }
                >
                  {activeDisplayMode === ProjectsDisplayMode.aiHuman ? (
                    <Select
                      value={selectedStatus}
                      onChange={onSelectedStatusChanges}
                      optionsList={statusOptions}
                      defaultValueText="Sort By"
                      actionIcon={<BlueArrowDown />}
                    />
                  ) : (
                    <Select
                      value={sortByDesc[activeDisplayMode]}
                      onChange={(value) => onSortByDescChanges(value, activeDisplayMode)}
                      optionsList={sortByDateOptions}
                      defaultValueText="Sort By"
                      actionIcon={<BlueArrowDown />}
                    />
                  )}
                </SelectWrapper>
              </TopActionWrapper>
            </MyStudioTitleAndActionsWrapper>
          </div>
          {!isLoading &&
            !list.length &&
            activeDisplayMode !== ProjectsDisplayMode.aiImages &&
            activeDisplayMode !== ProjectsDisplayMode.aiVideos && <EmptyCase>There are no projects yet</EmptyCase>}
          {!isLoading && !list.length && activeDisplayMode === ProjectsDisplayMode.aiImages && (
            <EmptyCase>There are no images yet</EmptyCase>
          )}
          {!isLoading && !list.length && activeDisplayMode === ProjectsDisplayMode.aiVideos && (
            <EmptyCase>There are no videos yet</EmptyCase>
          )}
          <MyStudioContentWrapper
            id="scrollableDiv"
            displayMode={activeDisplayMode}
            isLoading={isLoading && !list.length}
          >
            <MyStudioContent
              {...{
                sortByDesc,
                selectedStatus,
                selectedValue: activeDisplayMode,
                isStatusLoading,
                getProjectListFunc,
                currentPage,
                activeProjectTypeId,
                hasMore,
                isLoading,
                list,
                isSort,
                activeProject,
                setActiveProject,
                handleActiveProject,
                getAIImagesFunc,
                getGalleryVideosFunc,
              }}
            />
          </MyStudioContentWrapper>
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

interface MyStudioContentProps {
  sortByDesc: { [key: string]: boolean };
  selectedStatus?: AIHumansStatus | null;
  selectedValue: ProjectsDisplayMode;
  isStatusLoading: boolean;
  getProjectListFunc: ({ currentPage, activeProjectTypeId, sortByDesc }: any) => void;
  currentPage: number;
  activeProjectTypeId?: number;
  hasMore: boolean;
  isSort?: boolean;
  isLoading: boolean;
  list: Project[] | GalleryImage[] | GalleryVideo[] | AIImagesGroupedRecords[];
  activeProject?: number | null;
  setActiveProject: (activeProject: number | null) => void;
  handleActiveProject: (e: any, id?: number) => void;
  getAIImagesFunc: (currentPage: number, sortByDesc: boolean) => void;
  getGalleryVideosFunc: (currentPage: number, sortByDesc: boolean) => void;
  maxItems?: number;
  isHomePage?: boolean;
}

export const MyStudioContent = ({
  sortByDesc,
  selectedStatus,
  selectedValue,
  isStatusLoading,
  isSort,
  getProjectListFunc,
  currentPage,
  activeProjectTypeId,
  hasMore,
  isLoading,
  list,
  activeProject,
  setActiveProject,
  handleActiveProject,
  getAIImagesFunc,
  getGalleryVideosFunc,
  maxItems,
  isHomePage = false,
}: MyStudioContentProps) => {
  //const limitedList = list ? list.slice(0, maxItems) : [];
  const limitedList = maxItems !== undefined ? list?.slice(0, maxItems) || [] : list || [];

  switch (selectedValue) {
    case ProjectsDisplayMode.aiVoices:
      return isLoading && !limitedList.length ? (
        <CircularProgress color="#009af7" />
      ) : (
        <InfiniteScroll
          next={() =>
            !isSort &&
            getProjectListFunc({
              currentPage,
              activeProjectTypeId,
              sortByDesc: sortByDesc[ProjectsDisplayMode.aiVoices],
            })
          }
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as Project[])?.map((project) => (
              <Link key={project.projectId} to={`/actors?projectId=${project.projectId}`}>
                <ProjectCard
                  project={project}
                  title={project?.title}
                  description={project.subTitle}
                  projectTime={project?.updateDateTime}
                  image={project?.coverImage}
                  active={project.projectId === activeProject}
                  setActiveProject={setActiveProject}
                  sortByDesc={sortByDesc[ProjectsDisplayMode.aiVoices]}
                  handleActiveProject={(e: any) => handleActiveProject(e, project.projectId)}
                />
              </Link>
            ))}
        </InfiniteScroll>
      );
    case ProjectsDisplayMode.aiHuman:
      return isLoading && !limitedList.length ? (
        <CircularProgress color="#009af7" />
      ) : (
        <InfiniteScroll
          next={() => !isStatusLoading && getProjectListFunc({ currentPage, activeProjectTypeId })}
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as Project[])?.map((project) => (
              <ProjectCardWrapper
                key={project.projectId}
                active={project.projectId === activeProject}
                to={`/ai-humans?projectId=${project.projectId}`}
              >
                <ProjectCardLarge
                  project={project}
                  title={project?.title}
                  projectTime={project?.updateDateTime}
                  image={project?.coverImage}
                  status={project?.status}
                  output={project?.output}
                  active={project.projectId === activeProject}
                  selectedStatus={selectedStatus}
                  setActiveProject={setActiveProject}
                  handleActiveProject={(e: any) => handleActiveProject(e, project.projectId)}
                />
              </ProjectCardWrapper>
            ))}
        </InfiniteScroll>
      );
    case ProjectsDisplayMode.aiImages:
      return isLoading && !limitedList.length ? (
        <CircularProgress color="#009af7" />
      ) : (
        <InfiniteScroll
          next={() => !isSort && getAIImagesFunc(currentPage, sortByDesc[ProjectsDisplayMode.aiImages])}
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as AIImagesGroupedRecords[])?.map((data) =>
              isHomePage ? (
                <GalleryImageCard
                  isHomePage={isHomePage}
                  key={data.insertDateTime}
                  image={data.records[0]}
                  title={data.title}
                />
              ) : (
                <AIImagesGroupedCard key={data.insertDateTime} data={data} />
              ),
            )}
        </InfiniteScroll>
      );
    case ProjectsDisplayMode.aiVideos:
      return isLoading && !limitedList.length ? (
        <CircularProgress color="#009af7" />
      ) : (
        <InfiniteScroll
          next={() => !isSort && getGalleryVideosFunc(currentPage, sortByDesc[ProjectsDisplayMode.aiVideos])}
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as GalleryVideo[])?.map((video) => (
              <GalleryVideoCard isHomePage={isHomePage} key={video.insertDateTime} video={video} />
            ))}
        </InfiniteScroll>
      );
    default:
      return <></>;
  }
};

const ProjectCardWrapper = ({ children, active, to }: { children: ReactNode; active: boolean; to: string }) => {
  return active ? <div>{children}</div> : <Link to={to}>{children}</Link>;
};

const EmptyCase = styled.div`
  text-align: center;
  margin-top: 40px;
  color: ${({ theme }) => theme.primaryText};
`;

const TopActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TabsWrapper = styled.div`
  display: flex;
  margin-left: 0px;
  margin-right: auto;
  height: 38px;
  border-radius: 60px;
  box-shadow: 0px 4px 4px 0px #00000040;

  & > div {
    background: ${({ theme }) => theme.myStudioSwitchBackground};
    box-shadow: none;
    height: 38px;

    button {
      width: 130px;
      height: 30px;
      box-shadow: none;
      background: #0286dc;

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
      }
      &.not-active {
        opacity: 1;
      }
      &.not-active span {
        font-family: Mulish;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${({ theme }) => theme.myStudioSwitchTextSecond};
        opacity: 0.4;
        margin: 0px 6px 0px 0px;
      }
    }
  }

  @media (max-width: 1001px) {
    margin-top: 16px;
  }
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  .container {
    max-width: 1208px;
  }

  @media (max-width: 1441px) {
    .container {
      max-width: 966px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const DesktopOnly = styled.div`
  flex: 1;

  @media (max-width: 1001px) {
    display: none;
  }
`;

const MyStudioTitleAndActionsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: 1001px) {
    margin-top: 0;
  }
`;

const MyStudioTitleAndActionsContent = styled.div<{ selectedValue?: ProjectsDisplayMode }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:last-of-type {
    display: flex;
    column-gap: 24px;
    align-items: center;
  }

  @media (max-width: 1001px) {
    flex-direction: column;
    align-items: start;
    gap: 10px;

    & > div:last-of-type {
      width: 100%;
    }
  }

  ${({ selectedValue }) =>
    selectedValue === ProjectsDisplayMode.aiVoices &&
    `
     justify-content: end;

      @media (max-width: 1150px) {
        flex-direction: row;
        & > div:last-of-type {
          width: auto;
        }
      }

     @media (max-width: 1001px) {
        & > div:last-of-type {
          width: 100%;
        }
      }
    `}
`;

const MyStudioTitle = styled("h1")`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.primaryText};
  flex: 1;

  @media (max-width: 1001px) {
    margin-bottom: 16px;
  }
`;

const SelectWrapper = styled.div<{ isValueExist: boolean }>`
  width: 180px;

  .selector-container {
    div {
      box-shadow: none;
    }

    .selector-labels {
      display: ${({ isValueExist }) => (isValueExist ? "none" : "flex")};
    }

    align-items: center;
    .select-option-list {
      background-color: ${({ theme }) => theme.secondaryText};
    }
    span {
      padding: 3px;
      border: none;
      color: ${({ theme }) => theme.primaryText};
    }
    svg {
      background: none !important;
      top: 16px;
    }
  }
`;

const MyStudioContentWrapper = styled("div")<{ displayMode: ProjectsDisplayMode; isLoading?: boolean }>`
  position: relative;
  display: flex;
  align-content: stretch;
  overflow: hidden auto;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  max-height: 100%;
  gap: ${({ displayMode }) => (displayMode === ProjectsDisplayMode.aiVoices ? "16px" : "14px")};
  margin-top: 14px;
  padding: 8px 8px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  .project-card {
    width: 226px;
  }

  .project-card-large {
    width: 269px;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    `}

  @media (max-width: 1345px) {
    .project-card {
      width: 220px;
    }

    .project-card-large {
      width: 236px;
    }
  }

  @media (max-width: 550px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: ${({ displayMode }) =>
      displayMode === ProjectsDisplayMode.aiVoices
        ? "repeat(auto-fit, minmax(220px, 1fr))"
        : "repeat(auto-fit, minmax(236px, 1fr))"};
  }

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
    border: 5px solid transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    margin: 17px 0;
  }
`;

export default withPrivateRoute(MyStudio);
