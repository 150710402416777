import React from "react";

export const ShareLinkIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.25 9.75C4.49264 9.75 5.5 8.74264 5.5 7.5C5.5 6.25736 4.49264 5.25 3.25 5.25C2.00736 5.25 1 6.25736 1 7.5C1 8.74264 2.00736 9.75 3.25 9.75Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 14C12.9926 14 14 12.9926 14 11.75C14 10.5074 12.9926 9.5 11.75 9.5C10.5074 9.5 9.5 10.5074 9.5 11.75C9.5 12.9926 10.5074 14 11.75 14Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 5.5C12.9926 5.5 14 4.49264 14 3.25C14 2.00736 12.9926 1 11.75 1C10.5074 1 9.5 2.00736 9.5 3.25C9.5 4.49264 10.5074 5.5 11.75 5.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.25977 6.5L9.73977 4.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25977 8.5L9.73977 10.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const DownloadBoxIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1285_11003)">
        <path
          d="M2.5 13.5H1.5C1.23478 13.5 0.98043 13.3946 0.792893 13.2071C0.605357 13.0196 0.5 12.7652 0.5 12.5V4.5H13.5V12.5C13.5 12.7652 13.3946 13.0196 13.2071 13.2071C13.0196 13.3946 12.7652 13.5 12.5 13.5H11.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M4.5 11L7 13.5L9.5 11" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 13.5V7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M11.29 1C11.2045 0.851738 11.0823 0.727908 10.9353 0.640363C10.7882 0.552818 10.6211 0.504488 10.45 0.5H3.55C3.3789 0.504488 3.21182 0.552818 3.06474 0.640363C2.91766 0.727908 2.79552 0.851738 2.71 1L0.5 4.5H13.5L11.29 1Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7 0.5V4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1285_11003">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UploadBoxIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1285_10950)">
        <path
          d="M3.5 13.5H1.5C1.23478 13.5 0.98043 13.3946 0.792893 13.2071C0.605357 13.0196 0.5 12.7652 0.5 12.5V4.5H13.5V12.5C13.5 12.7652 13.3946 13.0196 13.2071 13.2071C13.0196 13.3946 12.7652 13.5 12.5 13.5H10.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M4.5 10L7 7.5L9.5 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 7.5V13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M11.29 1C11.2045 0.851738 11.0823 0.727908 10.9353 0.640363C10.7882 0.552818 10.6211 0.504488 10.45 0.5H3.55C3.3789 0.504488 3.21182 0.552818 3.06474 0.640363C2.91766 0.727908 2.79552 0.851738 2.71 1L0.5 4.5H13.5L11.29 1Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M7 0.5V4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1285_10950">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FaceIcon = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.9997 19.4614V23.1537C25.9997 23.6433 25.797 24.113 25.4364 24.4591C25.0759 24.8053 24.5866 24.9999 24.0766 24.9999H20.2305"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2305 1H24.0766C24.5866 1 25.0759 1.19451 25.4364 1.54073C25.797 1.88695 25.9997 2.35652 25.9997 2.84615V6.53846"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.53846V2.84615C1 2.35652 1.20261 1.88695 1.56326 1.54073C1.9239 1.19451 2.41304 1 2.92308 1H6.76923"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.76923 25H2.92308C2.41304 25 1.9239 24.8054 1.56326 24.4593C1.20261 24.1131 1 23.6435 1 23.1539V19.4615"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.5 8.38461V14.8462H10.6154" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.69141 18.5386C9.98875 19.7216 11.7098 20.3812 13.4991 20.3812C15.2884 20.3812 17.0094 19.7216 18.3068 18.5386"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.73077 8.84614C7.46525 8.84614 7.25 8.6395 7.25 8.3846C7.25 8.12971 7.46525 7.92307 7.73077 7.92307"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.73047 8.84614C7.99599 8.84614 8.21124 8.6395 8.21124 8.3846C8.21124 8.12971 7.99599 7.92307 7.73047 7.92307"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2679 8.84642C19.0024 8.84642 18.7871 8.63978 18.7871 8.38488C18.7871 8.12998 19.0024 7.92334 19.2679 7.92334"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2676 8.84642C19.5332 8.84642 19.7483 8.63978 19.7483 8.38488C19.7483 8.12998 19.5332 7.92334 19.2676 7.92334"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MediaIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1285_10935)">
        <path
          d="M23.2143 11.6071H16.0714C15.5783 11.6071 15.1786 12.0069 15.1786 12.5V23.2143C15.1786 23.7073 15.5783 24.1071 16.0714 24.1071H23.2143C23.7073 24.1071 24.1071 23.7073 24.1071 23.2143V12.5C24.1071 12.0069 23.7073 11.6071 23.2143 11.6071Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.2143 0.892853H16.0714C15.5783 0.892853 15.1786 1.2926 15.1786 1.78571V5.375C15.1786 5.8681 15.5783 6.26785 16.0714 6.26785H23.2143C23.7073 6.26785 24.1071 5.8681 24.1071 5.375V1.78571C24.1071 1.2926 23.7073 0.892853 23.2143 0.892853Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.92857 0.892853H1.78571C1.2926 0.892853 0.892853 1.2926 0.892853 1.78571V12.5C0.892853 12.9931 1.2926 13.3929 1.78571 13.3929H8.92857C9.42167 13.3929 9.82142 12.9931 9.82142 12.5V1.78571C9.82142 1.2926 9.42167 0.892853 8.92857 0.892853Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.92857 18.7321H1.78571C1.2926 18.7321 0.892853 19.1318 0.892853 19.625V23.2143C0.892853 23.7073 1.2926 24.1071 1.78571 24.1071H8.92857C9.42167 24.1071 9.82142 23.7073 9.82142 23.2143V19.625C9.82142 19.1318 9.42167 18.7321 8.92857 18.7321Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1285_10935">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LiveVideoIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.64453 16.8927V10.1068C9.64453 9.32294 10.5655 8.82748 11.3149 9.20815L17.9947 12.6011C18.7658 12.9928 18.7658 14.0067 17.9947 14.3984L11.3149 17.7913C10.5655 18.172 9.64453 17.6765 9.64453 16.8927Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.09462 11.8445C1.03216 12.3464 1 12.8574 1 13.376C1 13.8945 1.03216 14.4057 1.09462 14.9075"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.95898 3.49771C6.36646 3.19323 6.79718 2.91007 7.25051 2.65081C7.70383 2.39154 8.16672 2.16357 8.63664 1.96625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.98633 8.55605C2.1855 8.09053 2.41562 7.63197 2.67736 7.1829C2.93908 6.73382 3.22489 6.30713 3.53227 5.90347"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5243 1C20.4148 1 26.0006 6.59644 26.0006 13.5C26.0006 20.4037 20.4148 26 13.5243 26C8.77605 26 4.64731 23.3425 2.53906 19.431"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00002 12.8333C10.2084 12.8333 12.8334 10.2083 12.8334 6.99999C12.8334 3.79166 10.2084 1.16666 7.00002 1.16666C3.79169 1.16666 1.16669 3.79166 1.16669 6.99999C1.16669 10.2083 3.79169 12.8333 7.00002 12.8333Z"
        stroke="#FAFAFD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 4.375V7.29167" stroke="#FAFAFD" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 9.625H7.00524" stroke="#FAFAFD" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.14288 36.6431L13.8429 51.6857C14.2377 52.1987 14.7436 52.6157 15.3225 52.9054C15.9015 53.1947 16.5385 53.3494 17.1857 53.3571C17.8226 53.3649 18.453 53.2299 19.0313 52.9633C19.6095 52.6963 20.121 52.3037 20.5286 51.8143L57.8572 6.64307"
        stroke="#B0DBFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SoundWaveIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#15171A" />
      <rect x="8" y="11" width="4" height="10" rx="2" fill="white" />
      <rect x="14" y="8" width="4" height="16" rx="2" fill="white" />
      <rect x="20" y="11" width="4" height="10" rx="2" fill="white" />
    </svg>
  );
};

export const LibraryIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C0.895431 8 0 8.89543 0 10V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V10C24 8.89543 23.1046 8 22 8H2ZM9.5 13.5002C9.5 12.7323 10.3295 12.251 10.9961 12.6319L14.9806 14.9087C15.6524 15.2926 15.6524 16.2613 14.9806 16.6452L10.9961 18.9221C10.3295 19.303 9.5 18.8216 9.5 18.0538V13.5002Z"
        fill="#B0DBFF"
      />
      <rect x="1" y="4" width="22" height="2" rx="1" fill="#B0DBFF" />
      <rect x="2" width="20" height="2" rx="1" fill="#B0DBFF" />
    </svg>
  );
};
