import React from "react";
import styled from "styled-components";
import { AIHumansStatus } from "..";
import { CheckMarkIcon } from "../../../components/Icons/Icons";

const total = 100;

interface Props {
  percent: number;
  status?: AIHumansStatus;
}

const parsePercent = (percent: number) => {
  if (percent >= total) {
    return 99;
  } else return percent.toFixed(0);
};

const ProgressBarCircle = ({ percent, status }: Props) => (
  <Wrapper percent={status === AIHumansStatus.Completed ? total : percent}>
    <svg>
      <circle cx="20" cy="20" r="18"></circle>
      <circle cx="20" cy="20" r="18"></circle>
    </svg>
    <MiddleCircle>{status === AIHumansStatus.Completed ? <CheckMarkIcon /> : `${parsePercent(percent)}%`}</MiddleCircle>
  </Wrapper>
);

const Wrapper = styled.div<{ percent: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  svg {
    position: relative;
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
    z-index: 5;

    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: #dddee0;
      stroke-width: 4;
      stroke-linecap: round;

      &:last-of-type {
        stroke-dasharray: 110px;
        stroke-dashoffset: ${({ percent }) => 100 - (percent < 100 ? percent : 100)}px;
        stroke: #009af7;
      }
    }
  }
`;

const MiddleCircle = styled.div`
  position: absolute;
  z-index: 4;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #191b1f;

  svg {
    transform: translate(0);
    width: 20px;
    height: 20px;
  }
`;

export default ProgressBarCircle;
