import { useDispatch, useSelector } from "react-redux";

import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import {
  getAIHumansPreviewPopupIsOpen,
  getAddVoiceAudioPopupIsOpen,
  getAddVoiceAudioInfoPopupIsOpen,
  getCharacterPopupIsOpen,
  getConfirmationPopupIsOpen,
  getCreateNewProjectPopupIsOpen,
  getEnlargedImagePopupOpen,
  getCropImagePopupIsOpen,
  getShareVideoLinkPopupIsOpen,
  getSignUpPopupIsOpen,
  getTemplatePopupIsOpen,
  getTrimImagePopupOpen,
  getVimeoPopupIsOpen,
  getHistoryPopupIsOpen,
  getAIImageRemoveAreaPopupOpen,
  getUploadAIImageAssetPopupOpen,
  getNotEnoughCreditsPopupIsOpen,
  getCancelSubscriptionPopupIsOpen,
  getSubscriptionCanceledPopupIsOpen,
  getChangeSubscriptionPopupIsOpen,
  getPaymentMethodPKSPopupIsOpen,
  getChangedSubscriptionPopupIsOpen,
  getDeleteAccountPopupIsOpen,
  getAddCustomAvatarPopupIsOpen,
  getSelectTypeCustomAvatarPopup,
  getEmbedOnWebsitePopup,
  getText2VideoPopupOpen,
  getAIVoicesText2SpeechReplaceProjectPopupIsOpen,
  getFaceswapReplaceProjectPopupIsOpen,
} from "../../redux/reducers/popupsReducer";
import AddVoiceAudioPopup from "../Popups/AddVoiceAudioPopup";
import CharacterPopup from "../Popups/CharacterPopup";
import ConfirmationPopup from "../Popups/ConfirmationPopup";
import CreateNewProjectPopup from "../Popups/CreateNewProjectPopup";
import ShareVideoLinkPopup from "../Popups/ShareVideoLinkPopup";
import TemplatesPopup from "../Popups/TemplatesPopup";
import VimeoPopup from "../Popups/VimeoPopup";
import AIHumansPreviewPopup from "../Popups/AIHumansPreviewPopup";
import SignUpPopup from "../Popups/SignUpPopup";
import EnlargedPicturePopup from "../../modules/AIImages/components/EnlargedPicturePopup";
import TrimImaegePopup from "../Popups/TrimImaegePopup";
import CropImagePopup from "../Popups/CropImagePopup";
import RemoveAreaPopup from "../Popups/RemoveAreaPopup";
import AIImagesUploadImagePopup from "../Popups/AIImagesUploadImagePopup";
import NotEnoughCreditsPopup from "../Popups/NotEnoughCreditsPopup";
import CancelSubscriptionPopup from "../Popups/CancelSubscriptionPopup";
import SubscriptionCanceledPopup from "../Popups/SubscriptionCanceledPopup";
import SelectSubscriptionPopup from "../Popups/SelectSubscriptionPopup";
import PaymentMethodPKSPopup from "../Popups/PaymentMethodPKSPopup";
import ChangedSubscriptionPopup from "../Popups/ChangedSubscriptionPopup";
import DeleteAccountPopup from "../Popups/DeleteAccountPopup";
import AddCustomAvatarPopup, { AlertWrapper } from "../Popups/AddCustomAvatarPopup";
import SelectTypeCustomAvatarPopup from "../Popups/SelectTypeCustomAvatarPopup";
import { getCustomHumatar } from "../../redux/reducers/humatarReducer";
import { Status } from "../../redux/actions/humansProjectActions";
import SucessUploadAlert from "../SuccessUploadAlert/SuccessUploadAlert";
import EmbedOnWebsitePopup from "../Popups/EmbedOnWebsitePopup";
import Text2VideoPopup from "../../modules/AIImages/components/Text2VideoPopup";
import AddVoiceAudioInfoPopup from "../Popups/AddVoiceAudioInfoPopup";
import AIVoicesText2SpeechReplaceProjectPopup from "../Popups/AIVoicesText2SpeechReplaceProjectPopup";
import FaceswapReplaceProjectPopup from "../Popups/FaceswapReplaceProjectPopup";
import HistoryPopup from "../Popups/HistoryPopup";

const AppDialogs = () => {
  const createNewProjectPopupIsOpen = useSelector(getCreateNewProjectPopupIsOpen);
  const characterPopupIsOpen = useSelector(getCharacterPopupIsOpen);
  const templatePopupIsOpen = useSelector(getTemplatePopupIsOpen);
  const shareVideoLinkPopupIsOpen = useSelector(getShareVideoLinkPopupIsOpen);
  const confirmationPopupOpen = useSelector(getConfirmationPopupIsOpen);
  const isVimeoPopupVisible = useSelector(getVimeoPopupIsOpen);
  const isHistoryPopupVisible = useSelector(getHistoryPopupIsOpen);
  const addVoiceAudioPopupIsOpen = useSelector(getAddVoiceAudioPopupIsOpen);
  const addVoiceAudioInfoPopupIsOpen = useSelector(getAddVoiceAudioInfoPopupIsOpen);
  const addCustomAvatarPopupIsOpen = useSelector(getAddCustomAvatarPopupIsOpen);
  const aiHumansPreviewPopupIsOpen = useSelector(getAIHumansPreviewPopupIsOpen);
  const signUpPopupIsOpen = useSelector(getSignUpPopupIsOpen);
  const enlargedImagePopupOpen = useSelector(getEnlargedImagePopupOpen);
  const text2VideoPopup = useSelector(getText2VideoPopupOpen);
  const trimImagePopupOpen = useSelector(getTrimImagePopupOpen);
  const cropImagePopupIsOpen = useSelector(getCropImagePopupIsOpen);
  const isRemoveAreaOpen = useSelector(getAIImageRemoveAreaPopupOpen);
  const isAIImageUploadImageOpen = useSelector(getUploadAIImageAssetPopupOpen);
  const notEnoughCreditsPopupIsOpen = useSelector(getNotEnoughCreditsPopupIsOpen);
  const cancelSubscriptionPopupIsOpen = useSelector(getCancelSubscriptionPopupIsOpen);
  const subscriptionCanceledPopupIsOpen = useSelector(getSubscriptionCanceledPopupIsOpen);
  const changeSubscriptionPopupIsOpen = useSelector(getChangeSubscriptionPopupIsOpen);
  const paymentMethodPKSPopupIsOpen = useSelector(getPaymentMethodPKSPopupIsOpen);
  const changedSubscriptionPopupIsOpen = useSelector(getChangedSubscriptionPopupIsOpen);
  const deleteAccountPopupIsOpen = useSelector(getDeleteAccountPopupIsOpen);
  const selectTypeCustomAvatarPopupIsOpen = useSelector(getSelectTypeCustomAvatarPopup);
  const embedOnWebsitePopupIsOpen = useSelector(getEmbedOnWebsitePopup);
  const aiVoicesText2SpeechReplaceProjectPopupIsOpen = useSelector(getAIVoicesText2SpeechReplaceProjectPopupIsOpen);
  const faceswapReplaceProjectPopupIsOpen = useSelector(getFaceswapReplaceProjectPopupIsOpen);
  const customHumatar = useSelector(getCustomHumatar);

  const dispatch = useDispatch();

  const handleClosePopup = (popup: Popups) => {
    switch (popup) {
      case Popups.createNewProjectPopup: {
        dispatch(updatePopup({ popup: Popups.createNewProjectPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.characterPopup: {
        dispatch(updatePopup({ popup: Popups.characterPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.templatesPopup: {
        dispatch(updatePopup({ popup: Popups.templatesPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.shareVideoLinkPopup: {
        dispatch(updatePopup({ popup: Popups.shareVideoLinkPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.addVoiceAudioPopup: {
        dispatch(updatePopup({ popup: Popups.addVoiceAudioPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.addVoiceAudioInfoPopup: {
        dispatch(updatePopup({ popup: Popups.addVoiceAudioInfoPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.aIHumansPreviewPopup: {
        dispatch(updatePopup({ popup: Popups.aIHumansPreviewPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.signUpPopup: {
        dispatch(updatePopup({ popup: Popups.signUpPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.enlargedImagePopup: {
        dispatch(updatePopup({ popup: Popups.enlargedImagePopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.text2VideoPopup: {
        dispatch(updatePopup({ popup: Popups.text2VideoPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.trimImagePopup: {
        dispatch(updatePopup({ popup: Popups.trimImagePopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.cropImagePopup: {
        dispatch(updatePopup({ popup: Popups.cropImagePopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.aIImageRemoveAreaPopup: {
        dispatch(updatePopup({ popup: Popups.aIImageRemoveAreaPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.uploadAIImageAssetPopup: {
        dispatch(updatePopup({ popup: Popups.uploadAIImageAssetPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.notEnoughCreditsPopup: {
        dispatch(updatePopup({ popup: Popups.notEnoughCreditsPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.confirmationPopup: {
        dispatch(updatePopup({ popup: Popups.confirmationPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.cancelSubscriptionPopup: {
        dispatch(updatePopup({ popup: Popups.cancelSubscriptionPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.subscriptionCanceledPopup: {
        dispatch(updatePopup({ popup: Popups.subscriptionCanceledPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.changeSubscriptionPopup: {
        dispatch(updatePopup({ popup: Popups.changeSubscriptionPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.paymentMethodPKSPopup: {
        dispatch(updatePopup({ popup: Popups.paymentMethodPKSPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.changedSubscriptionPopup: {
        dispatch(updatePopup({ popup: Popups.changedSubscriptionPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.deleteAccountPopup: {
        dispatch(updatePopup({ popup: Popups.deleteAccountPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.addCustomAvatarPopup: {
        dispatch(updatePopup({ popup: Popups.addCustomAvatarPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.selectTypeCustomAvatarPopup: {
        dispatch(updatePopup({ popup: Popups.selectTypeCustomAvatarPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.embedOnWebsitePopup: {
        dispatch(updatePopup({ popup: Popups.embedOnWebsitePopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.aiVoicesText2SpeechReplaceProjectPopup: {
        dispatch(updatePopup({ popup: Popups.aiVoicesText2SpeechReplaceProjectPopup, status: false, prefilled: {} }));
        break;
      }
      case Popups.faceswapReplaceProjectPopup: {
        dispatch(updatePopup({ popup: Popups.faceswapReplaceProjectPopup, status: false, prefilled: {} }));
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      {createNewProjectPopupIsOpen && (
        <CreateNewProjectPopup
          open={createNewProjectPopupIsOpen}
          onClose={() => handleClosePopup(Popups.createNewProjectPopup)}
        />
      )}
      {characterPopupIsOpen && (
        <CharacterPopup open={characterPopupIsOpen} onClose={() => handleClosePopup(Popups.characterPopup)} />
      )}

      {templatePopupIsOpen && (
        <TemplatesPopup open={templatePopupIsOpen} onClose={() => handleClosePopup(Popups.templatesPopup)} />
      )}

      {shareVideoLinkPopupIsOpen && (
        <ShareVideoLinkPopup
          open={shareVideoLinkPopupIsOpen}
          onClose={() => handleClosePopup(Popups.shareVideoLinkPopup)}
        />
      )}
      {confirmationPopupOpen && (
        <ConfirmationPopup open={confirmationPopupOpen} onClose={() => handleClosePopup(Popups.confirmationPopup)} />
      )}
      {isVimeoPopupVisible && <VimeoPopup />}
      {isHistoryPopupVisible && <HistoryPopup />}
      {addVoiceAudioPopupIsOpen && (
        <AddVoiceAudioPopup
          open={addVoiceAudioPopupIsOpen}
          onClose={() => handleClosePopup(Popups.addVoiceAudioPopup)}
        />
      )}
      {addVoiceAudioInfoPopupIsOpen && (
        <AddVoiceAudioInfoPopup
          open={addVoiceAudioInfoPopupIsOpen}
          onClose={() => handleClosePopup(Popups.addVoiceAudioInfoPopup)}
        />
      )}
      {addCustomAvatarPopupIsOpen && (
        <AddCustomAvatarPopup
          open={addCustomAvatarPopupIsOpen}
          onClose={() => handleClosePopup(Popups.addCustomAvatarPopup)}
        />
      )}
      {aiHumansPreviewPopupIsOpen && (
        <AIHumansPreviewPopup
          open={aiHumansPreviewPopupIsOpen}
          onClose={() => handleClosePopup(Popups.aIHumansPreviewPopup)}
        />
      )}
      {signUpPopupIsOpen && (
        <SignUpPopup open={signUpPopupIsOpen} onClose={() => handleClosePopup(Popups.signUpPopup)} />
      )}
      {enlargedImagePopupOpen && (
        <EnlargedPicturePopup
          open={enlargedImagePopupOpen}
          onClose={() => handleClosePopup(Popups.enlargedImagePopup)}
        />
      )}
      {text2VideoPopup && (
        <Text2VideoPopup open={text2VideoPopup} onClose={() => handleClosePopup(Popups.text2VideoPopup)} />
      )}
      {trimImagePopupOpen && (
        <TrimImaegePopup open={trimImagePopupOpen} onClose={() => handleClosePopup(Popups.trimImagePopup)} />
      )}
      {cropImagePopupIsOpen && (
        <CropImagePopup open={cropImagePopupIsOpen} onClose={() => handleClosePopup(Popups.cropImagePopup)} />
      )}
      {isRemoveAreaOpen && (
        <RemoveAreaPopup open={isRemoveAreaOpen} onClose={() => handleClosePopup(Popups.aIImageRemoveAreaPopup)} />
      )}
      {isAIImageUploadImageOpen && (
        <AIImagesUploadImagePopup
          open={isAIImageUploadImageOpen}
          onClose={() => handleClosePopup(Popups.uploadAIImageAssetPopup)}
        />
      )}
      {notEnoughCreditsPopupIsOpen && (
        <NotEnoughCreditsPopup
          open={notEnoughCreditsPopupIsOpen}
          onClose={() => handleClosePopup(Popups.notEnoughCreditsPopup)}
        />
      )}
      {cancelSubscriptionPopupIsOpen && (
        <CancelSubscriptionPopup
          open={cancelSubscriptionPopupIsOpen}
          onClose={() => handleClosePopup(Popups.cancelSubscriptionPopup)}
        />
      )}
      {subscriptionCanceledPopupIsOpen && (
        <SubscriptionCanceledPopup
          open={subscriptionCanceledPopupIsOpen}
          onClose={() => handleClosePopup(Popups.subscriptionCanceledPopup)}
        />
      )}
      {changeSubscriptionPopupIsOpen && (
        <SelectSubscriptionPopup
          open={changeSubscriptionPopupIsOpen}
          onClose={() => handleClosePopup(Popups.changeSubscriptionPopup)}
        />
      )}
      {paymentMethodPKSPopupIsOpen && (
        <PaymentMethodPKSPopup
          open={paymentMethodPKSPopupIsOpen}
          onClose={() => handleClosePopup(Popups.paymentMethodPKSPopup)}
        />
      )}
      {changedSubscriptionPopupIsOpen && (
        <ChangedSubscriptionPopup
          open={changedSubscriptionPopupIsOpen}
          onClose={() => handleClosePopup(Popups.changedSubscriptionPopup)}
        />
      )}
      {deleteAccountPopupIsOpen && (
        <DeleteAccountPopup
          open={deleteAccountPopupIsOpen}
          onClose={() => handleClosePopup(Popups.deleteAccountPopup)}
        />
      )}
      {selectTypeCustomAvatarPopupIsOpen && (
        <SelectTypeCustomAvatarPopup
          open={selectTypeCustomAvatarPopupIsOpen}
          onClose={() => handleClosePopup(Popups.selectTypeCustomAvatarPopup)}
        />
      )}
      {customHumatar.isSuccess && (
        <AlertWrapper>
          <SucessUploadAlert status={Status.isCompleted} text={customHumatar.filename} />
        </AlertWrapper>
      )}
      {embedOnWebsitePopupIsOpen && (
        <EmbedOnWebsitePopup onClose={() => handleClosePopup(Popups.embedOnWebsitePopup)} />
      )}
      {aiVoicesText2SpeechReplaceProjectPopupIsOpen && (
        <AIVoicesText2SpeechReplaceProjectPopup
          open={aiVoicesText2SpeechReplaceProjectPopupIsOpen}
          onClose={() => handleClosePopup(Popups.aiVoicesText2SpeechReplaceProjectPopup)}
        />
      )}
      {faceswapReplaceProjectPopupIsOpen && (
        <FaceswapReplaceProjectPopup
          open={faceswapReplaceProjectPopupIsOpen}
          onClose={() => handleClosePopup(Popups.faceswapReplaceProjectPopup)}
        />
      )}
    </>
  );
};

export default AppDialogs;
