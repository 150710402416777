import styled from "styled-components";
import { ReactNode } from "react";

import HistoryTable from "./components/HistoryTable";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import { useHistory } from "../../hooks/useHistory";
import { ArrowsDownUp } from "../../components/Icons/Icons";

export interface Option {
  label: string;
  value: string;
}

export interface Column {
  id: number;
  minWidth: number;
  header: string;
  icon?: ReactNode;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  menuOptions?: Option[];
}

const HistoryPage = () => {
  const {
    data,
    option,
    isLoading,
    totalPages,
    currentPage,
    menuOptions,
    totalRecords,
    selectedHistoryLenghtPerPage,
    handlePaginate,
    handleOptionChanges,
    handleSelectHistoryLengthPerPage,
  } = useHistory();

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <Title>History</Title>
          <MobileOnly>
            <Sidebar mobile />
          </MobileOnly>
          <HistoryTableWrapper>
            <HistoryTable
              data={data}
              option={option}
              columns={columns}
              isLoading={isLoading}
              totalPages={totalPages}
              totalRecords={totalRecords}
              currentPage={currentPage}
              menuOptions={menuOptions}
              paginate={handlePaginate}
              handleOptionChanges={handleOptionChanges}
              selectedHistoryLenghtPerPage={selectedHistoryLenghtPerPage}
              handleSelectHistoryLengthPerPage={handleSelectHistoryLengthPerPage}
            />
          </HistoryTableWrapper>
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

const columns: Column[] = [
  {
    id: 1,
    header: "Name",
    icon: <ArrowsDownUp />,
    minWidth: 350,
  },
  {
    id: 2,
    header: "Type",
    icon: <ArrowsDownUp />,
    align: "left",
    minWidth: 200,
    menuOptions: [
      { label: "Type", value: "" },
      { label: "Faceswap", value: "Faceswap" },
      { label: "Text to speech", value: "Text to speech" },
      { label: "Speech to speech", value: "Speech to speech" },
      { label: "Speech translate", value: "Speech translate" },
      { label: "Transform", value: "Transform" },
      { label: "Talking image", value: "Talking image" },
      { label: "AI Human", value: "AI Human" },
      { label: "AI Image", value: "AI Image" },
      { label: "AI Video", value: "AI Video" },
      { label: "AI Video Lipsync", value: "Lipsync" },
      { label: "Video translate", value: "Video translate" },
    ],
  },
  {
    id: 3,
    header: "Created",
    icon: <ArrowsDownUp />,
    align: "left",
    minWidth: 150,
  },
  {
    id: 4,
    header: "Favourite",
    icon: <ArrowsDownUp />,
    align: "center",
    minWidth: 100,
  },
  {
    id: 5,
    header: "",
    align: "right",
    minWidth: 70,
  },
];

const Title = styled("div")`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.primaryText};
  margin-bottom: 16px;
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  .container {
    max-width: 1208px;
    padding-bottom: 20px;
  }

  @media (max-width: 1000px) {
    & .navigation {
      visibility: unset;
      height: 88px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const HistoryTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1001px) {
    margin-top: 16px;
  }
`;

export default withPrivateRoute(HistoryPage);
