import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import Button, { ButtonThemes } from "../Button/Button";
import { pages } from "../../lib/routeUtils";
import { products } from "../../mocks/products";
import { HomeIcon, SettingsIcon, LogoIcon, Logo, AssetsIcon, PlanIcon, ApiIcon } from "../Icons/Icons";
import { logout } from "../../redux/actions/authActions";
import { getProfile } from "../../redux/reducers/profileReducer";
import { useEffect, useRef, useState } from "react";
import ArrowDown from "../Icons/ArrowDown";
import { SupportSidebarIcon } from "../Icons/SupportSidebarIcon";
import { DollarSidebarIcon } from "../Icons/DollarSidebarIcon";
import { SquaresFourIcon } from "../Icons/SquaresFourIcon";
import { ClockCounterClockwiseIcon } from "../Icons/ClockCounterClockwiseIcon";
import { CelloNoCircleIcon } from "../Icons/CelloIcon";
import { getCelloToken } from "../../redux/reducers/authReducer";

interface Props {
  mobile?: boolean;
}

type FolderState = {
  [key: string]: { name: string; isOpen: boolean; subFolders: string[] };
};

const Sidebar = ({ mobile }: Props) => {
  const isFirstRender = useRef(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const { isSynthesysXEnabled } = useSelector(getProfile);
  const [folders, setFolders] = useState<FolderState>({
    mystudio: {
      name: "My studio",
      isOpen: false,
      subFolders: ["/my-projects", "/history"],
    },
    // voiceapi: {
    //   name: "Voice API",
    //   isOpen: false,
    //   subFolders: ["/api-access", "/pricing"],
    // },
  });
  const [activePage, setActivePage] = useState<string>("");
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const token = useSelector(getCelloToken);

  const modifiedProducts = products.map((product) => {
    if (!profile.allowToAIHuman && product.title === "AI Humans") {
      return {
        ...product,
        link: "",
      };
    }

    if (!profile.allowToAIVoice && product.title === "AI Voices") {
      return {
        ...product,
        link: "",
      };
    }

    return product;
  });

  const handleOpen = (section: keyof FolderState) => {
    setFolders((prevOpen) => ({
      ...prevOpen,
      [section]: {
        ...prevOpen[section],
        isOpen: !prevOpen[section].isOpen,
      },
    }));
  };

  const handleActivePage = (link: string) => setActivePage(link);

  useEffect(() => {
    if (
      isFirstRender.current &&
      (location.pathname === "/pricing" ||
        // location.pathname === "/api-access" ||
        location.pathname === "/my-projects" ||
        location.pathname === "/history")
    ) {
      handleActivePage(location.pathname);
      if (location.pathname === "/my-projects" || location.pathname === "/history") {
        setFolders({ ...folders, mystudio: { ...folders.mystudio, isOpen: true } });
      }
      //  else if (location.pathname === "/api-access" || location.pathname === "/pricing") {
      //   setFolders({ ...folders, voiceapi: { ...folders.voiceapi, isOpen: true } });
      // }
      isFirstRender.current = false;
    }
  }, [location]);

  const navigationList = [
    { id: 1, title: "Home", icon: <HomeIcon />, link: pages.main() },
    {
      id: 2,
      title: "My Studio",
      icon: <SquaresFourIcon />,
      link: pages.myProjects(),
      svgColorVariant: "stroke",
    },
    {
      id: 3,
      title: "History",
      icon: <ClockCounterClockwiseIcon />,
      link: pages.history(),
      svgColorVariant: "stroke",
    },
    // {
    //   id: 4,
    //   title: "My assets",
    //   icon: <AssetsIcon />,
    //   link: pages.assets(),
    //   svgColorVariant: "stroke",
    // },
    {
      id: 5,
      title: "My plan",
      icon: <PlanIcon />,
      link: pages.myPlan(),
      disabled: false,
      hidden: profile.hideMyPlans,
    },
    {
      id: 6,
      title: "Voice API",
      icon: <ApiIcon />,
      link: pages.apiAccess(),
    },
    { id: 7, title: "Account", icon: <SettingsIcon />, link: pages.settings() },
  ];

  // const navigationList = [
  //   { id: 1, title: "Home", icon: <HomeIcon />, link: pages.main() },
  //   {
  //     id: 2,
  //     title: "My plan",
  //     icon: <PlanIcon />,
  //     link: pages.myPlan(),
  //     disabled: false,
  //     hidden: profile.hideMyPlans,
  //   },
  //   {
  //     id: 3,
  //     title: "My studio",
  //     icon: <StudioIcon />,
  //     link: "#",
  //     options: [
  //       { id: "My projects", title: "My projects", link: pages.myProjects() },
  //       { id: "History", title: "History", link: pages.history() },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     title: "AI Video Translate",
  //     icon: <StudioIcon />,
  //     link: pages.videoTranslation(),
  //   },
  //   {
  //     id: 5,
  //     title: "Add Synthesys X to Chrome",
  //     icon: <GoogleChromeIcon />,
  //     target: "_blank",
  //     link: "https://chrome.google.com/webstore/detail/synthesys-x/jckigdhmponckmebbhbfenjmofkklnif?hl=en-GB&authuser=4",
  //     className: "desktopOnly",
  //     hidden: !isSynthesysXEnabled,
  //   },
  //   {
  //     id: 8,
  //     title: "Voice API",
  //     icon: <ApiIcon />,
  //     link: "#",
  //     options: [
  //       { id: "API Access", title: "API Access", link: pages.apiAccess() },
  //       { id: "Pricing", title: "Pricing", link: pages.pricing() },
  //     ],
  //   },
  //   { id: 9, title: "Settings", icon: <SettingsIcon />, link: pages.settings() },
  //   { id: 10, title: "Logout", icon: <LogoutIcon />, link: "" },
  // ];

  const namesToDelete = ["My plan", "Support", "Voice API", "Settings"];

  const modifiedNavigationList = navigationList.filter((list) =>
    isGuest ? !namesToDelete.includes(list.title) : list,
  );

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Wrapper mobile={mobile}>
      <LogoWrapper>
        <Link to="/">
          <LogoIcon />
        </Link>
      </LogoWrapper>
      <Content>
        {/* <MinutesButtonWrapper>
          <Link to={pages.pricingSubscriptions()}>
            <Button buttonTheme={ButtonThemes.Transparent}>
              <Logo />
              <span>More minutes?</span>
            </Button>
          </Link>
        </MinutesButtonWrapper> */}
        {modifiedNavigationList.map(
          ({ id, title, icon, link, disabled, hidden, options, target, className, svgColorVariant = "fill" }: any) =>
            hidden ? (
              <></>
            ) : // ) : title !== "Voice API" && title !== "My studio" ? (
            title !== "My studio" ? (
              <Link key={`${title} - ${id}`} to={link ? link : "/"} target={target}>
                <ListItemButton
                  active={link === location.pathname}
                  disabled={disabled}
                  className={className}
                  onClick={() => {
                    !link && handleLogout();
                    // title === "Support" && window?.open("https://flipcast.freshdesk.com/a/", "_blank")?.focus();
                    title === "Download Synthesys X Chrome extension" &&
                      window
                        ?.open(
                          "https://chrome.google.com/webstore/detail/synthesys-x/jckigdhmponckmebbhbfenjmofkklnif?hl=en-GB&authuser=4",
                          "_blank",
                        )
                        ?.focus();
                    // title === "Training" && dispatch(updatePopup({ popup: Popups.vimeoPopup, status: true }));
                    // title === "Voice Cloning" && dispatch(getMyProfileServer(true));
                  }}
                >
                  <ListItemIcon active={link === location.pathname} svgColorVariant={svgColorVariant || "fill"}>
                    {icon}
                  </ListItemIcon>
                  <ListItemTitle active={link === location.pathname}>{title}</ListItemTitle>
                  {/* {checkCloneVoiceLoading && title === "Voice Cloning" && (
                    <CircularProgressWrapper>
                      <CircularProgress color="#009af7" />
                    </CircularProgressWrapper>
                  )} */}
                </ListItemButton>
              </Link>
            ) : (
              <Link key={`${title} - ${id}`} to={link ? link : "/"} className={title ? title : "/"}>
                <ListItemButtonWrapper
                  active={
                    activePage
                      ? folders[title.toLowerCase().replace(/\s/g, "") as keyof FolderState].subFolders.includes(
                          activePage,
                        )
                      : false
                  }
                >
                  <ListItemButton onClick={() => handleOpen(title.toLowerCase().replace(/\s/g, ""))}>
                    <ListItemIcon
                      active={folders[title.toLowerCase().replace(/\s/g, "") as keyof FolderState].isOpen}
                      svgColorVariant={svgColorVariant || "fill"}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemTitle active={folders[title.toLowerCase().replace(/\s/g, "") as keyof FolderState].isOpen}>
                      {title}
                    </ListItemTitle>
                    <ListItemIcon
                      active={folders[title.toLowerCase().replace(/\s/g, "") as keyof FolderState].isOpen}
                      isOpen={folders[title.toLowerCase().replace(/\s/g, "") as keyof FolderState].isOpen}
                      variant="dropdown"
                      svgColorVariant={svgColorVariant || "fill"}
                    >
                      <ArrowDown />
                    </ListItemIcon>
                  </ListItemButton>
                  {folders[title.toLowerCase().replace(/\s/g, "") as keyof FolderState].isOpen &&
                    options?.map(({ id, title, link }: any) => (
                      <Link key={`${title} - ${id}`} to={link ? link : "/"}>
                        <ListItemButton
                          active={link === activePage}
                          variant="dropdown"
                          onClick={() => handleActivePage(link)}
                        >
                          <ListItemTitle active={link === location.pathname}>{title}</ListItemTitle>
                        </ListItemButton>
                      </Link>
                    ))}
                </ListItemButtonWrapper>
              </Link>
            ),
        )}
        {token && (
          <ListItemButton className="cello-synthesys-launcher cello">
            <ListItemIcon active={false} svgColorVariant={"fill"}>
              <CelloNoCircleIcon />
            </ListItemIcon>
            <ListItemTitle active={false}>Earn commissions</ListItemTitle>
          </ListItemButton>
        )}

        <ButtonWrapper>
          {/* {!profile.hidePricing && ( */}
          <Link to={pages.pricingSubscriptions()}>
            <BottomButton buttonTheme={ButtonThemes.Transparent}>
              <DollarSidebarIcon />
              <span>Pricing</span>
            </BottomButton>
          </Link>
          {/* )} */}
          <Link to="https://flipcast.freshdesk.com/support/home" target="_blank">
            <BottomButton buttonTheme={ButtonThemes.Transparent}>
              <SupportSidebarIcon />
              <span>Support</span>
            </BottomButton>
          </Link>
          {/* <Button text="Create new project" onClick={handleOpenPopup} /> */}
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ mobile?: boolean }>`
  background-color: ${({ theme }) => theme.sidebarMenuBackground};
  border-radius: 0 20px 20px 0;
  max-height: 1114px;
  width: 252px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1001px) {
    display: none;
    background-color: ${({ theme }) => theme.primaryBackground};
  }

  ${({ mobile }) =>
    mobile &&
    `
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    box-shadow: none;
    padding: 0;
  `};
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 173px;
  height: 37px;
  padding: 28px 22px 60px;

  svg {
    width: 173px;
    height: 37px;
    path {
      fill: #0090f0;
    }
  }

  @media (max-width: 1001px) {
    display: none;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 26px 12px 12px;
  gap: 8px;
  box-shadow: 0px 4px 4px 0px ${({ theme }) => theme.sidebarMenuShadow};

  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;

  .cello {
    position: relative;
  }

  @media (max-height: 720px) {
    gap: 6px;
  }

  @media (max-width: 1001px) {
    background-color: ${({ theme }) => theme.primaryBackground};
    flex-direction: row;
    box-shadow: none;
    border-radius: 0;
    gap: 8px;
    padding: 0;
    height: auto;
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ListItemButtonWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.primaryBackground};
  border-radius: 12px;
  border: 1px solid transparent;
  overflow: hidden;

  & > div,
  & > a > div {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
  }

  ${({ active, theme }) =>
    active &&
    `
      background: ${theme.button};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      border: 1px solid ${theme.activeMenu};
      text-fill-color: transparent;
  `}

  @media (max-width: 1001px) {
    flex-direction: row;
  }
`;

const ListItemButton = styled("div")<{ active?: boolean; disabled?: boolean; variant?: string }>`
  height: 44px;
  background: ${({ theme }) => theme.sidebarMenuBackground};
  border-radius: 12px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 13px;
  gap: 8.5px;
  cursor: pointer;

  @media (max-width: 1001px) {
    background: ${({ theme }) => theme.primaryBackground};
  }

  @media (max-width: 1001px) {
    background: ${({ theme }) => theme.primaryBackground};
  }

  &.desktopOnly {
    display: flex;
    background-color: ${({ theme }) => theme.activeMenu};

    span {
      color: ${({ theme }) => theme.white};
    }

    svg > path {
      fill: ${({ theme }) => theme.white};
    }

    @media (max-width: 1001px) {
      display: none;
    }
  }

  ${({ variant, active, theme }) =>
    variant !== "dropdown" &&
    active &&
    `
    color: ${theme.sidebarMenuTextActive};
    background: ${theme.sidebarMenuActive};
    border: 1px solid ${theme.sidebarMenuActive};

    @media (max-width: 1001px) {
       background: ${theme.sidebarMenuActive};
    }
  `}

  ${({ variant, active, theme }) =>
    variant === "dropdown" &&
    active &&
    `
     background: ${theme.sidebarDropdownMenu} !important;
    `}

  ${({ disabled, theme }) =>
    disabled &&
    `
      opacity: 0.5;
  `}

  @media (max-width: 1001px) {
    width: max-content;
    height: 44px;
  }
`;

const ListItemIcon = styled("div")<{
  active: boolean;
  isOpen?: boolean;
  variant?: string;
  svgColorVariant: string;
}>`
  display: flex;
  align-items: center;

  ${({ variant }) =>
    variant === "dropdown" &&
    `
      margin-left: auto;

      @media (max-width: 1001px) {
        transform: rotate(-90deg);
      }
    `}

  ${({ variant, isOpen }) =>
    variant === "dropdown" &&
    isOpen &&
    `
      svg {
        transform: rotate(-180deg);
      }
    `}

  svg > path,
  svg > g,
  svg > g > path {
    fill: ${({ theme }) => theme.sidebarIcon};

    ${({ active, theme, svgColorVariant }) =>
      active &&
      `
        ${svgColorVariant}: ${theme.sidebarMenuTextActive};
        opacity: 1;
    `};
  }
`;

const ListItemTitle = styled("span")<{ active: boolean }>`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.sidebarMenuText};
  opacity: 1;

  ${({ active, theme }) =>
    active &&
    `
        color: ${theme.sidebarMenuTextActive};
        opacity: 1;
    `}
`;

const MinutesButtonWrapper = styled("div")`
  width: 100%;
  margin: 0px 0px 50px;
  background: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.sidebarMenuActive};
  border-radius: 12px;

  button {
    height: 40px;
    padding: 16px 0;

    & span {
      color: ${({ theme }) => theme.reviewBlue};
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  svg {
    width: 40px;
    height: 32px;

    path {
      fill: ${({ theme }) => theme.reviewBlue};
    }
  }

  @media (max-width: 1001px) {
    display: none;
  }
  @media (max-height: 720px) {
    margin: 0px 0px 40px;
  }
`;

const ButtonWrapper = styled("div")`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    height: 44px;
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-height: 720px) {
    button {
      height: 36px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media (max-width: 1001px) {
    flex-direction: row;
    margin-top: 0px;

    button {
      display: flex;
      gap: 8.5px;
      height: 44px;
      padding: 0px 14px 0px 13px;

      > span:first-child {
        display: none;
      }
    }
  }
`;

const BottomButton = styled(Button)`
  gap: 8px;
  padding: 12px 16px;

  & span {
    color: ${({ theme }) => theme.sidebarMenuText};
  }

  @media (max-width: 1001px) {
    display: none;
  }
`;

export default Sidebar;
