import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { deleteProjectserver } from "../../redux/actions/projectAction";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { getIsDeleteLoading } from "../../redux/reducers/projectReducer";

import Button, { ButtonThemes } from "../Button/Button";
import Modal from "../Modal/Modal";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import { deleteUserAssetServer } from "../../redux/actions/assetAction";
import { getAssets } from "../../redux/reducers/assetReducer";
import {
  deleteAIImagesGroupedRecordServer,
  deleteGalleryImageServer,
  deleteGalleryVideoServer,
} from "../../redux/actions/imagesActions";
import {
  getAIImagesGroupedRecordDeleteLoading,
  getGalleryImageDeleteLoading,
  getGalleryVideoDeleteLoading,
} from "../../redux/reducers/imagesReducer";
import { getCloneVoiceDeleting } from "../../redux/reducers/actorReducer";
import { deleteCloneVoiceServer } from "../../redux/actions/actorActions";
import { CharacterPrefilled } from "../../types/actor";
import { getCustomHumatarDeleting } from "../../redux/reducers/humatarReducer";
import { deleteCustomHumatarServer } from "../../redux/actions/humatarActions";
import { HumatarPrefilled } from "../../types/humatar";
import { getUserHistoryProjectsIsDeleteLoading } from "../../redux/reducers/profileReducer";
import { deleteHistoryProjectServer } from "../../redux/actions/profileActions";
import { UserHistoryProjectPrefilled } from "../../types/userHistoryProjects";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ConfirmationPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const {
    title,
    description,
    id,
    typeId,
    type,
    previousFill,
    status,
    redirectTo,
    pageSize,
    sortByDesc,
    category,
    option,
  }: ConfirmationDelete = useSelector(getPrefilled);

  const assets = useSelector(getAssets);
  const assetDeleteLoading =
    type === DeleteType.Asset || type === DeleteType.UploadImage ? assets[typeId].isDelete : false;
  const isDeleteLoading = useSelector(getIsDeleteLoading);
  const isDeleteGalleryImageLoading = useSelector(getGalleryImageDeleteLoading);
  const isDeleteCloneVoiceLoading = useSelector(getCloneVoiceDeleting);
  const isDeleteCustomHumatarLoading = useSelector(getCustomHumatarDeleting);
  const isDeleteGalleryVideoLoading = useSelector(getGalleryVideoDeleteLoading);
  const isDeleteAIImageLoading = useSelector(getAIImagesGroupedRecordDeleteLoading);
  const isDeleteUserHistoryProjectLoading = useSelector(getUserHistoryProjectsIsDeleteLoading);

  const [isButtonClick, setIsButtonClick] = useState(false);

  const deleteParams = {
    [DeleteType.Project]: isDeleteLoading,
    [DeleteType.Asset]: assetDeleteLoading,
    [DeleteType.GalleryImage]: isDeleteGalleryImageLoading,
    [DeleteType.UploadImage]: assetDeleteLoading,
    [DeleteType.CloneVoice]: isDeleteCloneVoiceLoading,
    [DeleteType.CustomHumatar]: isDeleteCustomHumatarLoading,
    [DeleteType.GalleryVideo]: isDeleteGalleryVideoLoading,
    [DeleteType.AIImages]: isDeleteAIImageLoading,
    [DeleteType.HistoryProject]: isDeleteUserHistoryProjectLoading,
  };

  const isDelete = deleteParams[type];

  const handleDelete = () => {
    if (type === DeleteType.Project) {
      setIsButtonClick(true);
      dispatch(
        deleteProjectserver({
          projectId: id.toString(),
          projectTypeId: typeId,
          status,
          redirectTo,
          pageSize,
          sortByDesc,
          previousFill: previousFill as UserHistoryProjectPrefilled,
        }),
      );
    } else if (type === DeleteType.Asset || type === DeleteType.UploadImage) {
      dispatch(deleteUserAssetServer({ id, assetTypeId: typeId }));
    } else if (type === DeleteType.CloneVoice) {
      dispatch(deleteCloneVoiceServer({ id, previousFill: previousFill as CharacterPrefilled }));
    } else if (type === DeleteType.CustomHumatar) {
      dispatch(deleteCustomHumatarServer({ id, previousFill: previousFill as HumatarPrefilled }));
    } else if (type === DeleteType.GalleryImage) {
      dispatch(deleteGalleryImageServer(id));
    } else if (type === DeleteType.AIImages) {
      dispatch(deleteAIImagesGroupedRecordServer(id));
    } else if (type === DeleteType.HistoryProject) {
      setIsButtonClick(true);
      dispatch(
        deleteHistoryProjectServer({
          id,
          category: category || "",
          isDeleted: true,
          pageSize: pageSize || 20,
          option: option || "",
          previousFill: previousFill as UserHistoryProjectPrefilled,
        }),
      );
    } else {
      dispatch(deleteGalleryVideoServer(id));
    }
  };

  const handleClose = () => {
    if (isDelete) return;

    onClose();

    if (type === DeleteType.UploadImage) {
      dispatch(
        updatePopup({
          popup: Popups.uploadAIImageAssetPopup,
          status: true,
          prefilled: previousFill,
        }),
      );
    } else if (type === DeleteType.CloneVoice) {
      dispatch(
        updatePopup({
          popup: Popups.characterPopup,
          status: true,
          prefilled: previousFill,
        }),
      );
    } else if (type === DeleteType.CustomHumatar) {
      dispatch(
        updatePopup({
          popup: Popups.aIHumansPopup,
          status: true,
          prefilled: previousFill,
        }),
      );
    } else if ((type === DeleteType.HistoryProject || type === DeleteType.Project) && previousFill && !isButtonClick) {
      dispatch(
        updatePopup({
          popup: Popups.historyPopup,
          status: true,
          prefilled: previousFill,
        }),
      );
    }
  };

  useEffect(() => {
    if (!isFirstRender.current && !isDelete) {
      handleClose();
    }

    isFirstRender.current = false;
  }, [isDelete]);

  return (
    <Wrapper type={type}>
      <Modal
        className="download-popup"
        title={<TitleWrapper>{title}</TitleWrapper>}
        open={open}
        onClose={handleClose}
        closeIcon={false}
        description={<>{description}</>}
      >
        <Form>
          <ButtonWrapper>
            <Button text="Cancel" buttonTheme={ButtonThemes.Outline} onClick={handleClose} />
            <Button text={isDelete ? "Loading..." : "Delete"} onClick={isDelete ? () => null : handleDelete} />
          </ButtonWrapper>
        </Form>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ type?: DeleteType }>`
  .modal-wrapper {
    z-index: 103;
  }

  .modal-darkener {
    z-index: 102;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Form = styled.form`
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: 25%;
  }
`;

export default ConfirmationPopup;
