import styled from "styled-components";
import { ReactNode } from "react";
import { TableCell } from "@mui/material";

import HistoryTableHeadSelect from "./HistoryTableHeadSelect";
import { Option } from "..";

interface Props {
  id: number;
  minWidth: number;
  header: string;
  icon?: ReactNode;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  menuOptions?: Option[];
  option: string;
  onClick: () => void;
  handleOptionChanges: (newOption: string) => void;
}

const HistoryTableHeadCell = ({
  align,
  minWidth,
  header,
  icon,
  menuOptions,
  option,
  onClick,
  handleOptionChanges,
}: Props) => (
  <StyledTableCell align={align} style={{ minWidth: minWidth }}>
    {!menuOptions?.length ? (
      <div style={{ justifyContent: align }} onClick={onClick}>
        {header} {icon}
      </div>
    ) : (
      <HistoryTableHeadSelect
        optionsList={menuOptions}
        value={option}
        placeholder={header}
        onChange={handleOptionChanges}
      />
    )}
  </StyledTableCell>
);

const StyledTableCell = styled(TableCell)`
  position: sticky;
  top: 0;
  padding: 12px 12px 0;
  height: 47px;
  font-family: Mulish;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  background-color: ${({ theme }) => theme.tableBackground};
  color: ${({ theme }) => theme.tableTitleText};
  border-bottom: 1px solid ${({ theme }) => theme.tableDivider};

  & > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 4px;

    & svg {
      width: 14px;
      height: 14px;
      fill: rgb(111, 112, 116);
    }
  }
`;

export default HistoryTableHeadCell;
