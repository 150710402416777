import styled from "styled-components";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ContentState, EditorState } from "draft-js";

import Editor from "../Editor/Editor";
import Dropdown from "../Dropdown/Dropdown";
import TextArea from "../TextArea/TextArea";
import Button, { ButtonThemes } from "../Button/Button";
import CircularProgress from "../Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { IActor } from "../../types/actor";
import { PlusIcon } from "../Icons/PlusIcon";
import { Paragraphs } from "../../types/project";
import { EditorSelect } from "../../mocks/actors";
import { ModalCloseIcon, SearchbarFlash } from "../Icons/Icons";
import { getProfile } from "../../redux/reducers/profileReducer";

const AI_VOICES_LIMITATION_ADVANCED = 20000;
const AI_VOICES_LIMITATION_SIMPLE = 2000;

interface Props {
  projectId?: number;
  route?: string;
  actors: IActor[];
  paragraphs?: Paragraphs[];
  setParagraphs?: any;
  actorActive?: number;
  setActorActive: (actorActive: IActor[]) => void;
  paragraphActive?: number;
  setParagraphActive: (paragraphActive: number) => void;
  isLoading: boolean;
  editorContent: any;
  setEditorContent: any;
  setFeatureActive: any;
  lastSel: any;
  setLastSel: any;
  calcParagraphsLength: number[];
  styleMap: any;
  editorSelect: string;
  speechCategory: number;
}

const TextBlock = ({
  projectId,
  route,
  actors,
  paragraphs,
  setParagraphs,
  actorActive,
  setActorActive,
  paragraphActive,
  setParagraphActive,
  isLoading,
  editorContent,
  setEditorContent,
  setFeatureActive,
  lastSel,
  setLastSel,
  calcParagraphsLength,
  styleMap,
  editorSelect,
  speechCategory,
}: Props) => {
  const { Simple, Advanced } = EditorSelect;
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => setIsOpen(false);

  const { aiVoiceFreeUser } = useSelector(getProfile);

  const textLength = calcParagraphsLength.reduce((start, current) => start + current, 0);
  const addParagraph = (id?: number) => {
    setEditorContent((prev: any) => [
      ...prev,
      EditorState.moveFocusToEnd(EditorState.createWithContent(ContentState.createFromText(""))),
    ]);
    setParagraphActive(Number(paragraphs?.length) + 1);
    if (paragraphs && paragraphActive) {
      const newActor = paragraphs?.[paragraphActive - 1]?.actor;
      const newActorsList = paragraphs?.[paragraphActive - 1]?.actorsList;
      setParagraphs((prev: any) => [
        ...prev,
        {
          actor: newActor,
          actorsList: newActorsList,
          actorId: actorActive,
          order: id,
          data: [
            {
              text: "",
              features: [
                {
                  key: "",
                  value: "",
                },
              ],
            },
          ],
        },
      ]);
    }
  };

  const handleEditorContent = (index: number) => (newEditorContent: any) => {
    setEditorContent(
      editorContent.map((content: any, order: number) => (order === index ? newEditorContent : content)),
    );
  };

  const handleTextParagraph = (order: number, type: EditorSelect) => (payload: any | any[]) => {
    const newText = paragraphs?.map((paragraph) =>
      paragraph.order === order
        ? {
            ...paragraph,
            data: type === Simple ? [{ text: payload.target.value, features: [] }] : payload,
          }
        : paragraph,
    );
    setParagraphs(newText);
  };

  const handleDelete = (e: any, id: number) => {
    e.stopPropagation();

    const newParagraphs = paragraphs
      ?.filter((paragraph) => paragraph.order !== id)
      .map((paragraph, index) => ({ ...paragraph, order: index + 1 }));
    const editorIndex = paragraphs?.findIndex((paragraph) => paragraph.order === id);
    const newEditorContent = editorContent.filter((editor: EditorState, index: number) => index !== editorIndex);
    setParagraphs(newParagraphs);
    setEditorContent(newEditorContent);
    setParagraphActive(1);
  };

  const actor = actors?.find((actor) => actor?.actorId === paragraphs?.[0].actorId);

  const limit =
    actor?.actorTypeId == 10
      ? 300
      : editorSelect === Simple
      ? AI_VOICES_LIMITATION_SIMPLE
      : AI_VOICES_LIMITATION_ADVANCED;

  return (
    <Wrapper>
      {isLoading ? (
        <CircularProgress color="#009af7" />
      ) : (
        paragraphs &&
        paragraphs?.map((paragraph, order) => {
          const actor = actors?.find((actor) => actor?.actorId === paragraph.actorId);

          const limit =
            actor?.actorTypeId == 10
              ? 300
              : editorSelect === Simple
              ? AI_VOICES_LIMITATION_SIMPLE
              : AI_VOICES_LIMITATION_ADVANCED;

          const editor = {
            [Advanced]: (
              <Editor
                index={order}
                projectId={projectId}
                route={route}
                data={paragraph.data}
                editorContent={editorContent[order]}
                setEditorContent={handleEditorContent(order)}
                handleTextParagraph={handleTextParagraph(order + 1, Advanced)}
                setFeatureActive={setFeatureActive}
                lastSel={lastSel}
                setLastSel={setLastSel}
                addParagraph={addParagraph}
                paragraphs={paragraphs}
                styleMap={styleMap}
                textLength={textLength}
                limitation={AI_VOICES_LIMITATION_ADVANCED}
                defaultPause={0}
                placeholder={"Please enter text"}
              />
            ),
            [Simple]: (
              <TextAreaWrapper>
                <TextArea
                  value={paragraph.data[0]?.text}
                  placeholder="Please enter text"
                  onChange={(e) => {
                    if (e.target.value.length > limit) {
                      toast.error(
                        `Please make sure your project doesn’t exceed the maximum amount of ${limit} characters, this is done to achieve high quality and rendering speed of the output`,
                      );
                      return;
                    }
                    handleTextParagraph(1, Simple)(e);
                  }}
                />
              </TextAreaWrapper>
            ),
          };

          return (
            <ParagraphWrapper
              key={paragraph.order}
              isSimple={editorSelect === Simple}
              onClick={() => setParagraphActive(paragraph.order)}
            >
              <Dropdown
                photo={actor?.photo || paragraph?.actor?.photo}
                name={actor?.name || paragraph?.actor?.name}
                actors={actors}
                currentParagraphActor={actor || paragraph?.actor}
                currentParagraphActorsList={paragraph?.actorsList || []}
                active={actor?.actorId || paragraph?.actor?.actorId}
                setActorActive={setActorActive}
                paragraphs={paragraphs}
                deleteDisplay={paragraphs.length === 1 ? false : true}
                speechCategory={speechCategory}
                onDelete={(e: any) => handleDelete(e, paragraph.order)}
              />
              {editor[editorSelect as EditorSelect]}
            </ParagraphWrapper>
          );
        })
      )}
      <Footer>
        {editorSelect === Advanced ? (
          <Button
            buttonTheme={ButtonThemes.Transparent}
            icon={
              <PlusIconWrapper>
                <div>
                  <PlusIcon />
                </div>
              </PlusIconWrapper>
            }
            text="Add paragraph"
            onClick={() => addParagraph((paragraphs?.length as number) + 1)}
          />
        ) : (
          <div />
        )}
        {aiVoiceFreeUser && isOpen && (
          <BannerWrapper>
            <SearchbarFlash />
            <span>
              <a href="/pricing/subscriptions" target="_blank">
                Upgrade your plan now
              </a>
            </span>
            <IconButton
              iconButtonTheme={IconButtonThemes.Transparent}
              icon={<ModalCloseIcon />}
              onClick={handleClose}
            />
          </BannerWrapper>
        )}
        <TextWrapper>
          <a
            href="https://flipcast.freshdesk.com/support/solutions/articles/13000100260-tips-for-life-like-voices-"
            target="_blank"
            rel="noreferrer"
          >
            <Tips>Tips</Tips>
          </a>
          <Text>
            {textLength}/{editorSelect === Advanced ? AI_VOICES_LIMITATION_ADVANCED : limit}
          </Text>
        </TextWrapper>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.secondaryBackgroundAIVoices};
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #0063b4;
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ParagraphWrapper = styled.div<{ isSimple?: boolean }>`
  ${({ isSimple }) =>
    isSimple &&
    `
      height: calc(100% - 80px);
    `}

  .dropdown {
    span {
      color: ${({ theme }) => theme.primaryTextAIVoices};
    }
  }

  .dropdown-menu {
    background: ${({ theme }) => theme.secondaryBackgroundAIVoices};
    box-shadow: ${({ theme }) => theme.primaryShadowAIVoices};

    & > div {
      color: ${({ theme }) => theme.primaryTextAIVoices};
    }

    button > div {
      box-shadow: ${({ theme }) => theme.primaryShadowAIVoices};
    }
  }

  .RichEditor-editor
    .DraftEditor-root
    .DraftEditor-editorContainer
    .public-DraftEditor-content
    div
    div:nth-child(odd)
    div
    > span {
    color: ${({ theme }) => theme.primaryTextAIVoices};
  }

  .public-DraftEditorPlaceholder-inner {
    color: #6a6a7c;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  position: sticky;
  background-color: ${({ theme }) => theme.secondaryBackgroundAIVoices};
  z-index: 5;
  bottom: -1px;
  padding-top: 5px;
  padding-bottom: 20px;

  button > span {
    color: ${({ theme }) => theme.primaryTextAIVoices};
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  a {
    display: flex;
  }
`;

const Tips = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline ${({ theme }) => theme.activeMenu};
  background: ${({ theme }) => theme.button};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Text = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryTextAIVoices};
  opacity: 0.4;
`;

const PlusIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(91.46deg, rgba(0, 0, 0, 0.05) -0.9%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 50%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme }) => theme.button};
    box-shadow: ${({ theme }) => theme.primaryShadowAIVoices};
  }

  svg > path {
    fill: ${({ theme }) => theme.white};
  }
`;

const BannerWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  display: flex;
  padding: 6px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--Linear, #0063b4);
  background: ${({ theme }) => theme.secondaryBackgroundAIVoices};
  z-index: 5;

  svg:first-of-type {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
  }

  & > button {
    margin-left: 4px;

    & > svg:last-of-type {
      width: 16px;
      height: 16px;

      path {
        fill: #9a9b9e;
      }
    }
  }

  span {
    color: ${({ theme }) => theme.primaryTextAIVoices};
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Mulish", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    a {
      text-align: center;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.41px;
      text-decoration: underline ${({ theme }) => theme.activeMenu};
      background: ${({ theme }) => theme.button};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
    }
  }

  @media (max-width: 401px) {
    white-space: wrap;
    left: 0;
    transform: translateX(0);
  }
`;

const TextAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  textarea {
    height: 100%;
  }
`;

export default TextBlock;
