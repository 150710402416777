import styled, { keyframes } from "styled-components";
import { ChangeEvent } from "react";
import { Link } from "react-router-dom";

import Tabs from "./components/Tabs";
import Textfield from "../../components/Textfield/Textfield";
import Button, { ButtonThemes } from "../../components/Button/Button";
import { Project } from "../../types/project";
import { DiskButtonIcon } from "../../components/Icons/DiskButtonIcon";
import { SearchFilterIcon, SearchIcon } from "../../components/Icons/Icons";
import { AIVoicesMainTabs, AiVoicesMainTabs } from "../../hooks/useAIVoices";
import CircularProgress from "../../components/Icons/CircularProgress";

export const navActions = (
  search: string,
  activeTab: string,
  isLoading: boolean,
  project: Project | null,
  autoSaveLoading: boolean,
  isProjectLoading: boolean,
  rightSidebarOpen: boolean,
  createProject: () => void,
  updateProject: () => void,
  handleOpenPopup: () => void,
  handleActiveMainTab: (tab: string) => void,
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void,
  disabledMainTabsLabels?: AIVoicesMainTabs[],
) => {
  const { Text2Speech } = AIVoicesMainTabs;

  const mainTabs = AiVoicesMainTabs.map((tab) =>
    disabledMainTabsLabels?.includes(tab.value) ? { ...tab, disabled: true } : { ...tab },
  );

  return (
    <DesktopOnly>
      <NavRow>
        <NavLeftColumn>
          <Link to="/my-projects?filter=actors" style={{ marginRight: "30px", marginTop: "10px" }}>
            <Button
              buttonTheme={ButtonThemes.Secondary}
              icon={<img src="/images/arrow-left.svg" />}
              style={{
                position: "relative",
                zIndex: "100",
              }}
              text="Back"
            />
          </Link>
          <SearchTitle>Select voice</SearchTitle>
          <SearchBox>
            <Textfield
              value={search}
              onChange={handleSearchChange}
              placeholder="Search voice actors"
              startAdornment={<SearchIcon />}
              endAdornment={<SearchFilterIcon />}
              endAdornmentClick={handleOpenPopup}
            />
          </SearchBox>
        </NavLeftColumn>
        <NavRightColumn>
          <Controls rightSidebarOpen={rightSidebarOpen}>
            <TabsWrapper rightSidebarOpen={rightSidebarOpen}>
              <Tabs
                data={mainTabs}
                active={activeTab}
                handleActive={handleActiveMainTab}
                disabled={isLoading || isProjectLoading}
              />
            </TabsWrapper>
            <DesktopOnly>
              {activeTab === Text2Speech && (
                <SaveButtonWrapper>
                  <Button
                    onClick={project?.projectId ? updateProject : createProject}
                    buttonTheme={ButtonThemes.Primary}
                    icon={autoSaveLoading ? <CircularProgress color="#fff" /> : <DiskButtonIcon />}
                  />
                </SaveButtonWrapper>
              )}
            </DesktopOnly>
          </Controls>
        </NavRightColumn>
      </NavRow>
    </DesktopOnly>
  );
};

export const Wrapper = styled("div")<{ active: boolean }>`
  background-color: ${({ theme }) => theme.primaryBackgroundAIVoices};
  width: 100%;
  height: 100vh;
  padding: 12px;
  display: flex;
  position: relative;
  overflow: hidden;

  ${({ active }) =>
    active &&
    `
      gap: 8px;      
  `};

  @media (max-width: 1000px) {
    padding: 0;
    align-items: initial;
    justify-content: initial;

    ${({ active }) =>
      active &&
      `
      gap: 0;      
  `}
  }
`;

export const PageWrapper = styled("div")<{ active: boolean }>`
  background-color: ${({ theme }) => theme.pageWrapperAIVoices};
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  position: relative;

  .navigation-content {
    width: 100%;

    > div {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    border-radius: 0;
    visibility: visible;

    ${({ active }) =>
      active &&
      `
      width: 0%;
      min-width: 0%;
      height: 0;
      visibility: hidden;
      overflow: hidden auto;
      opacity: 1;
      transition: 0.2s;      
  `}
  }
`;

export const NavRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Controls = styled.div<{ rightSidebarOpen: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 19px;
  flex-flow: row wrap;

  > div:last-child {
    margin-left: auto;
    margin-right: 0px;
    width: 58px;

    @media (max-width: 1085px) {
      ${({ rightSidebarOpen }) =>
        rightSidebarOpen &&
        `
      order: 1;     
  `}
    }
  }

  @media (max-width: 1085px) {
    ${({ rightSidebarOpen }) =>
      rightSidebarOpen &&
      `
      justify-content: end;   
  `}
  }
`;

export const NavLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 14px;
`;

export const NavRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const SearchTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 52px;
  text-align: left;
  color: ${({ theme }) => theme.primaryTextAIVoices};
`;

export const TabsWrapper = styled.div<{ rightSidebarOpen: boolean }>`
  display: flex;
  margin-left: 0px;
  margin-right: auto;
  height: 38px;
  border-radius: 60px;
  background: ${({ theme }) => theme.secondaryBackgroundAIVoices};

  & > div {
    background: ${({ theme }) => theme.secondaryBackgroundAIVoices};
    box-shadow: none;

    & > div {
      width: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      border: 2px;
      color: ${({ theme }) => theme.secondaryTextAIVoices};
    }

    button {
      box-shadow: none;
      background: #106cb6;

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
      }
      &.not-active {
        opacity: 1;
      }
      &.not-active span {
        font-family: Mulish;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${({ theme }) => theme.secondaryTextAIVoices};
        opacity: 1;
        margin: 0px 6px 0px 0px;
      }
    }
  }

  @media (max-width: 1085px) {
    ${({ rightSidebarOpen }) =>
      rightSidebarOpen &&
      `
      order: 2;     
  `}
  }
`;

export const Content = styled("div")`
  display: flex;
  gap: 8px;
  flex: 1;
  overflow: hidden;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ContentBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  background: ${({ theme }) => theme.secondaryBackgroundAIVoices};
  border-radius: 20px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: initial;
  }

  @media (max-width: 1000px) {
    position: absolute;
    z-index: 1;
    top: 43px;
    left: 100px;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  width: 280px;

  input {
    background: ${({ theme }) => theme.secondaryBackgroundAIVoices};
    width: 252px;
    height: 34px;
    border-radius: 60px;
    border: 1px solid;
    border-image-source: linear-gradient(91.14deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: none;

    &::placeholder {
      color: ${({ theme }) => theme.secondaryTextAIVoices};
      opacity: 1;
    }
  }

  button {
    width: 152px;
  }

  & svg > path {
    fill: #0063b4aa;
  }

  & div:first-of-type > svg > path {
    opacity: 1;
  }

  @media (max-width: 1000px) {
    input {
      order: 1;
    }

    button {
      order: 3;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  width: 100%;
  height: calc(100% - 100px);

  & > div:first-child {
    min-height: 300px;
  }
`;

export const IconButtonWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  top: calc(50% - 18px);
  right: -16px;
  transform: rotate(-180deg);
  transition: 0.2s;
  width: 48px;
  z-index: 1;

  & > button {
    box-shadow: none;
    border: none !important;
  }

  ${({ active }) =>
    active &&
    `
    transform: rotate(0);
    right: -36px;  
  `}

  @media (max-width: 1000px) {
    right: 0;

    & > button {
      width: 40px;
      height: 40px;
      border: none !important;
      border-radius: 0 12px 12px 0;
    }

    ${({ active }) =>
      active &&
      `
      right: auto;
      left: 0;  
  `}
  }
`;

export const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

export const DesktopOnly = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;

  button {
    width: 152px;
  }

  @media (max-width: 1000px) {
    margin-left: auto;

    button {
      width: 152px;
    }
  }
`;
export const MobileRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
`;

export const MobileCol = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 14px;
`;

export const PricingButtonWrapper = styled.div`
  & button {
    height: 38px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.secondaryBackgroundAIVoices};
    column-gap: 3px;
    padding: 16px 12px;

    & span {
      color: ${({ theme }) => theme.secondaryTextAIVoices};
    }
  }

  & path {
    fill: #0063b4 !important;
  }
`;

export const DragAndDropWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;

  & > h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryTextAIVoices};
    margin-top: 8px;
    text-align: center;
  }

  & > input {
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
  }

  & > svg {
    width: 25px;
    height: 26px;
  }

  @media (max-width: 1001px) and (max-height: 775px) {
    gap: 4px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    & > h3 {
      font-size: 14px;
      margin-top: 0;
    }
  }

  @media (max-width: 500px) {
    gap: 4px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    & > h3 {
      font-size: 12px;
      margin-top: 0;
    }
  }
`;

export const SampleFormat = styled.p<{ sizeError?: boolean }>`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.secondaryTextAIVoices};

  @media (max-width: 500px) {
    font-size: 10px;
  }

  ${({ sizeError }) =>
    sizeError &&
    `
    color: #ff6c76;
    `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 16px;

  button {
    position: relative;
    z-index: 1;
    width: 134px;
    height: 36px;
    padding: 8px 25px;
    border-radius: 12px;
    background: #9e9eae59;
    cursor: pointer;

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.4px;
      text-align: center;
    }
  }
`;

export const SpeechButtonsWrapper = styled.div`
  width: 180px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  button {
    height: 84px;
    width: 84px;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const waveAnimation = keyframes`
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.3);
  }
`;

export const WaveWrapper = styled.div`
  width: 84px;
  height: 32px;
  display: flex;
  justify-content: center;

  .rect-1 {
    animation: ${waveAnimation} 1.5s ease-in-out infinite;
  }

  .rect-2 {
    animation: ${waveAnimation} 1.5s ease-in-out infinite;
    animation-delay: 0.3s;
  }

  .rect-3 {
    animation: ${waveAnimation} 1.5s ease-in-out infinite;
    animation-delay: 0.6s;
  }
`;

export const FileListWrapper = styled.div`
  width: 100%;
  align-self: start;
  padding: 18px 10px;
`;

export const FileItem = styled.div`
  width: 100%;
  padding: 3px 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.fileItemAIVoices};
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 18px;

  p {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.primaryTextAIVoices};
  }
  span {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.primaryTextAIVoices};
  }
`;

export const FileActions = styled.div`
  display: flex;
  gap: 1px;

  button {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const SidebarOpener = styled.div<{ active?: boolean }>`
  width: 0%;
  min-width: 0%;
  visibility: hidden;
  overflow: hidden auto;
  opacity: 1;
  transition: 0.2s;

  ${({ active }) =>
    active &&
    `
        min-width: 255px;
        width: 255px;
        height: 100%;
        visibility: visible;      
        overflow: visible;
`}

  @media (max-width: 1000px) {
    ${({ active }) =>
      active &&
      `
        padding: 40px 52px 32px;
        min-width: 100%;
        width: 100%;   
  `}
  }
`;

export const SidebarContent = styled.div`
  width: 255px;
  color: #ffffff;
  height: 100%;
  overflow: hidden;
`;

export const SidebarTabsWrapper = styled.div`
  padding-bottom: 19px;

  & > div {
    background: ${({ theme }) => theme.primaryBackgroundAIVoices};
    border: 1px solid #0063b4;
    border-radius: 60px;
    box-shadow: none;
    width: 203px;
    align-items: center;
  }

  button {
    background: #0063b4;
    box-shadow: none;
    height: 22px;

    span {
      font-family: Mulish;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
    }
    &.not-active {
      opacity: 1;
    }
    &.not-active span {
      font-family: Mulish;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      color: ${({ theme }) => theme.secondaryTextAIVoices};
      opacity: 1;
    }
    &:disabled {
      opacity: 0.6;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;

  button {
    pointer-events: none;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: ${({ theme }) => theme.primaryBackground}4d;
  backdrop-filter: blur(6px);

  svg {
    height: 100px;
    width: 100px;
  }
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    width: 54px;
    height: 36px;
    border-radius: 12px;
    background: #0063b4;
    box-shadow: none;

    & > div {
      display: flex;
    }
  }

  & svg {
    width: 24px;
    height: 24px;
  }
`;
