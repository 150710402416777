import { IActor } from "./actor";
import { Paragraphs, Zone } from "./project";
import { ActorPositionTypes } from "./scene";
import { Status } from "../redux/actions/humansProjectActions";

export interface ShapeDetail {
  shapeDetailId: number;
  shapeId: number;
  shapePositionX: number;
  shapePositionY: number;
  shapeHeight: number;
  shapeWidth: number;
  layerOrder: number;
  extraStyleData: { key: string; value: string }[];
}

export interface CustomText {
  customTextId: number;
  text: string;
  customTextType: string;
  fontFamily: string;
  fontSize: string;
  lineSpacing: string;
  fontStyle: string;
  textAlign: string;
  fontColor: string;
  opacity: number;
  textPositionX: number;
  textPositionY: number;
  layerOrder: number;
  extraStyleData: { key: string; value: string }[];
}

export interface CustomImageDetails {
  customImageHeight: number;
  customImageWidth: number;
  customImagePositionX: number;
  customImagePositionY: number;
  customImageDetailId: number;
  customImageId: number;
  extraStyleData: { key: string; value: string }[];
  layerOrder: number;
}

export interface Feature {
  key: string;
  value: string;
}

export interface HumansParagraph {
  actorId: number;
  order: number;
  data: Zone[];
  outputAudio?: string;
  projectParagraphId?: number;
  sceneId: number;
  actor?: IActor;
  actorsList?: IActor[];
}

export interface BackgroundAsset {
  path: string;
  assetTypeId: number;
}

interface AiHumanActor {
  photo: string;
  allowCircle: boolean;
  category: string;
}

export interface Slide {
  slideId: number;
  order: number;
  audioPath: string;
  backGroundAssetId: number;
  backGroundColor: string | null;
  slideBackgroundColor?: string;
  backgroundAsset?: BackgroundAsset;
  aiHumanActorId: number;
  aiHumanActor: AiHumanActor;
  actorPositionType: ActorPositionTypes;
  actorPositionX: number;
  actorPositionY: number;
  actorSizeHeight: number;
  actorSizeWidth: number;
  slideTransitionEffectId: number;
  shapeDetails: ShapeDetail[];
  customTexts: CustomText[];
  customImageDetails: CustomImageDetails[];
  projectParagraphs: Paragraphs[];
  humatarLayerOrder: number;
  faceImage: string | null;
  startBackgroundVideoTime: number | null;
  endBackgroundVideoTime: number | null;
  extraStyleJsonText: string | null;
}

export interface Project {
  totalDuration: number;
  projectId: number;
  projectTypeId: number;
  title: string;
  projectCategoryId: number;
  subTitleLanguageId: number;
  resolution: string;
  isDraft: boolean;
  isTemplate: boolean;
  isHorizontal: boolean;
  slides: Slide[];
  status: Status;
  backgroundSoundTrackId: number | null;
  canvasWidth: number;
  output: string;
  completeDateTime: string;
  enableCaption: boolean;
  coverImage?: string;
  insertDateTime?: string;
}

export enum AiHumansProjectModules {
  "project" = "project",
  "generateAudio" = "generateAudio",
  "generateVideo" = "generateVideo",
  "loadingPage" = "loadingPage",
}
