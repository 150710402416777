import { put, select, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import {
  DELETE_PROJECT_SERVER,
  getProjectListServer,
  GET_PROJECT_LIST_SERVER,
  GET_PROJECT_SERVER,
  updateHasMoreProjects,
  updateProjectLoading,
  UPDATE_PROJECT_SERVER,
  CREATE_PROJECT_SERVER,
  setCachedZones,
  GetProjectListServerProps,
  GET_PROJECT_LIST_REFRESH_SERVER,
  clearProjectList,
} from "../actions/projectAction";
import { ProjectModules, Zone } from "../../types/project";
import { Popups, updatePopup } from "../actions/popupsActions";
import { getProjectList } from "../reducers/projectReducer";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { getAllZones } from "../../lib/editorUtils";
import Navigate from "../../lib/Navigate";
import { getVoiceoverHistoryServer } from "../actions/actorActions";

const projectSagas = [
  takeEvery(GET_PROJECT_LIST_SERVER, handleGetProjectList),
  takeEvery(`${GET_PROJECT_LIST_SERVER}_FAIL`, handleGetProjectListFail),
  takeEvery(`${GET_PROJECT_LIST_SERVER}_SUCCESS`, handleGetProjectListSuccess),

  takeEvery(GET_PROJECT_LIST_REFRESH_SERVER, handleGetProjectList),
  takeEvery(`${GET_PROJECT_LIST_REFRESH_SERVER}_FAIL`, handleGetProjectListFail),
  takeEvery(`${GET_PROJECT_LIST_REFRESH_SERVER}_SUCCESS`, handleGetProjectListSuccess),

  takeEvery(`${GET_PROJECT_SERVER}`, handleGetProject),
  takeEvery(`${GET_PROJECT_SERVER}_FAIL`, handleGetProjectFail),
  takeEvery(`${GET_PROJECT_SERVER}_SUCCESS`, handleGetProjectSuccess),

  takeEvery(`${UPDATE_PROJECT_SERVER}`, handleUpdateProject),
  takeEvery(`${UPDATE_PROJECT_SERVER}_FAIL`, handleUpdateProjectFail),
  takeEvery(`${UPDATE_PROJECT_SERVER}_SUCCESS`, handleUpdateProjectSuccess),

  takeEvery(CREATE_PROJECT_SERVER, handleUpdateProject),
  takeEvery(`${CREATE_PROJECT_SERVER}_FAIL`, handleUpdateProjectFail),
  takeEvery(`${CREATE_PROJECT_SERVER}_SUCCESS`, handleCreateProjectSuccess),

  takeEvery(`${DELETE_PROJECT_SERVER}_FAIL`, handleDeleteProjectFail),
  takeEvery(`${DELETE_PROJECT_SERVER}_SUCCESS`, handleDeleteProjectSuccess),
];

function* handleGetProjectList() {
  yield put(updateProjectLoading({ module: ProjectModules.projectList, isLoading: true }));
}

function* handleGetProjectListFail() {
  yield toast.error("Error while getting projects");
  yield put(updateProjectLoading({ module: ProjectModules.projectList, isLoading: false }));
}

function* handleGetProjectListSuccess(action: any) {
  const allProjects = getProjectList(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allProjects.length >= totalLength) yield put(updateHasMoreProjects({ hasMore: false }));

  yield put(updateProjectLoading({ module: ProjectModules.projectList, isLoading: false }));
}

function* handleGetProject() {
  yield put(updateProjectLoading({ module: ProjectModules.project, isLoading: true }));
}

function* handleGetProjectFail() {
  yield toast.error("Error while getting project");
  yield put(updateProjectLoading({ module: ProjectModules.project, isLoading: false }));
}

function* handleGetProjectSuccess(action: any) {
  const zones = getAllZones(action.payload.data.data.paragraphs).filter((zone: Zone) => zone.audioPath);
  // const zones = getAllZonesFromSlides(action?.payload?.data?.data?.slides || []).filter((zone: Zone) => zone.audioPath);
  // we should add manual cache
  yield put(setCachedZones({ zones }));
  yield put(updateProjectLoading({ module: ProjectModules.project, isLoading: false }));
}

function* handleUpdateProject() {
  yield put(updateProjectLoading({ module: ProjectModules.autoSave, isLoading: true }));
}

function* handleUpdateProjectFail(action: any) {
  const project = action?.meta?.previousAction?.payload?.request?.data;

  yield toast.error("Error while saving project");

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_SAVING_PROJECT,
    details: {
      responseFromApi: action?.error?.response?.data || null,
      project: JSON.stringify(project || []),
    },
  });

  yield put(updateProjectLoading({ module: ProjectModules.autoSave, isLoading: false }));
}

function* handleUpdateProjectSuccess(action: any) {
  yield toast.success("Project updated");
  yield put(getVoiceoverHistoryServer(1, true));
  yield put(updateProjectLoading({ module: ProjectModules.autoSave, isLoading: false }));
  if (action?.meta?.previousAction?.payload?.event !== "projectSavingEvent") {
    yield put(getProjectListServer({ keyword: "" }));
  }
}

function* handleCreateProjectSuccess() {
  yield toast.success("Project created");
  yield put(getVoiceoverHistoryServer(1, true));
  yield put(updateProjectLoading({ module: ProjectModules.autoSave, isLoading: false }));
}

function* handleDeleteProjectFail(action: any) {
  const { previousFill } = action.meta.previousAction.payload;

  yield toast.error("Error while deleting project");

  if (previousFill) {
    yield put(updatePopup({ popup: Popups.historyPopup, status: false }));
  }

  yield put(updatePopup({ popup: Popups.confirmationPopup, status: false }));
}

function* handleDeleteProjectSuccess(action: any) {
  const { projectTypeId, status, redirectTo, pageSize, sortByDesc } = action.meta.previousAction.payload;

  let projectList: GetProjectListServerProps = {
    keyword: "",
    projectTypeId,
    pageSize: pageSize || 40,
  };

  if (status) {
    projectList = {
      ...projectList,
      status,
    };
  }

  if (sortByDesc !== undefined) {
    projectList = {
      ...projectList,
      sortByDesc,
    };
  }

  yield put(clearProjectList());
  yield put(getProjectListServer(projectList));
  yield put(updatePopup({ popup: Popups.historyPopup, status: false }));
  yield put(updatePopup({ popup: Popups.confirmationPopup, status: false }));

  if (redirectTo) {
    yield Navigate.push("/my-projects?filter=ai-humans");
  }
}

export default projectSagas;
