import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SourceFaceCard from "../SourceFaceCard";
import VideoCard from "../VideoCard";
import AudioCard from "../AudioCard";
import { FaceswapProjectTab } from "../..";
import {
  clearFaceswap,
  getAIServiceByIdServer,
  getAIServiceListServer,
} from "../../../../redux/actions/faceswapActions";
import { clearActors, getActorsServer } from "../../../../redux/actions/actorActions";

const TalkingAvatarScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const fetchData = (isLoading?: boolean) => dispatch(getAIServiceListServer({ serviceTypeID: 2, isLoading }));

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => fetchData(false), 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    dispatch(clearActors());
    dispatch(
      getActorsServer({
        keyword: "",
        pageNumber: 1,
        categoryType: [""],
        voiceAge: [],
        isFeMale: null,
        mood: [],
        content: [],
        region: [],
        language: [],
        country: [],
        popular: true,
        SpeechCategory: 2,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(clearFaceswap());

    if (id) dispatch(getAIServiceByIdServer({ id: parseInt(id), isProjectRestored: true }));
  }, [id]);

  return (
    <>
      <SourceFaceCard cardTitle="Character" />
      <AudioCard />
      <VideoCard type={FaceswapProjectTab["Talking Avatar"]} generateButtonText="Generate video" activeTab="Video" />
    </>
  );
};

export default TalkingAvatarScreen;
