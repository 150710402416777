import React, { FC } from "react";
import styled from "styled-components";
import { PlayIcon, PauseIcon } from "../../../components/Icons/Icons";

type Props = {
  isPlay: boolean;
  handlePlayChange: (e?: any) => void;
};

const PlayPauseButton: FC<Props> = ({ isPlay, handlePlayChange }) => {
  return (
    <Wrapper>
      <StyledButton onClick={handlePlayChange}>{!isPlay ? <PlayIcon /> : <PauseIcon />}</StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const StyledButton = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 65px;
  background: #0063b4;

  svg {
    width: 25px;
    height: 25px;
  }
`;

export default PlayPauseButton;
