import { all } from "redux-saga/effects";

import authSaga from "./authSaga";
import profileSagas from "./profileSaga";
import actorSagas from "./actorSaga";
import projectSagas from "./projectSaga";
import chatSagas from "./chatSaga";
import humatarSagas from "./humatarSaga";
import humansProjectSaga from "./humansProjectSaga";
import transitionSaga from "./transitionSaga";
import assetSaga from "./assetSaga";
import shapesSaga from "./shapesSaga";
import templateSaga from "./templateSaga";
import languageSaga from "./languageSaga";
import imagesSaga from "./imagesSaga";
import sumoSagas from "./sumoSaga";
import plansSaga from "./plansSaga";
import videoTranslationSaga from "./videoTranslationSaga";
import faceswapSaga from "./faceswapSaga";

function* rootSaga() {
  yield all([
    ...authSaga,
    ...assetSaga,
    ...profileSagas,
    ...actorSagas,
    ...projectSagas,
    ...chatSagas,
    ...humatarSagas,
    ...templateSaga,
    ...transitionSaga,
    ...humansProjectSaga,
    ...shapesSaga,
    ...languageSaga,
    ...imagesSaga,
    ...sumoSagas,
    ...plansSaga,
    ...videoTranslationSaga,
    ...faceswapSaga,
  ]);
}

export default rootSaga;
