import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ActorCard, { YourVoiceCard } from "../ActorCard/ActorCard";
import Button, { ButtonThemes } from "../Button/Button";
import CircularProgress from "../Icons/CircularProgress";
import { IActor } from "../../types/actor";
import { pages } from "../../lib/routeUtils";
import { Paragraphs } from "../../types/project";
import { PricingIcon } from "../Icons/PricingIcon";
import { getProfile } from "../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";

interface Props {
  actors: IActor[];
  paragraphs: Paragraphs[];
  active?: number;
  onClick?: (actor: any) => void;
  setActorActive?: (actor: any) => void;
  currentParagraphActor?: IActor;
  currentParagraphActorsList?: IActor[];
  isSingleActorSelectionOnly?: boolean;
  speechCategory: number;
  isLoading: boolean;
  isNoFilters?: boolean;
}

const ActorsSidebar = ({
  actors,
  paragraphs,
  active,
  currentParagraphActor,
  currentParagraphActorsList,
  isSingleActorSelectionOnly,
  speechCategory,
  setActorActive,
  onClick,
  isLoading,
  isNoFilters,
}: Props) => {
  const dispatch = useDispatch();

  const profile = useSelector(getProfile);

  const handleClick = () => {
    dispatch(
      updatePopup({
        popup: Popups.characterPopup,
        status: true,
        prefilled: {
          actors,
          paragraphs,
          active,
          setActorActive,
          currentParagraphActor,
          currentParagraphActorsList,
          speechCategory,
          isSingleActorSelectionOnly,
          isNoFilters,
        },
      }),
    );
  };

  return (
    <Wrapper>
      <Content>
        <ActorsWrapper>
          <ActorCardWrapper>
            <YourVoiceCard />
            {isLoading ? (
              <CircularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CircularProgressWrapper>
            ) : actors.length > 0 ? (
              actors?.map((actor) => (
                <ActorCard
                  key={actor?.actorId}
                  actor={actor}
                  active={actor?.actorId === active}
                  onClick={onClick ? () => onClick(actor) : undefined}
                />
              ))
            ) : (
              <EmptyState>
                <span>No data</span>
              </EmptyState>
            )}
          </ActorCardWrapper>
          {!!actors.length && (
            <ShowAll
              buttonTheme={ButtonThemes.Transparent}
              isLoading={isLoading}
              onClick={handleClick}
              text="Listen to more voices"
            />
          )}
        </ActorsWrapper>
        {!!actors.length && (
          <MobileOnly>
            <MobileShowAll
              className="mob-show"
              isLoading={isLoading}
              onClick={handleClick}
              text="Listen to more voices"
            />
          </MobileOnly>
        )}
        {!profile.hidePricing && (
          <DesktopOnlyPricing>
            <Bottom>
              <Link to={pages.pricingSubscriptions()}>
                <PricingButtonWrapper>
                  <Button buttonTheme={ButtonThemes.Transparent}>
                    <PricingIcon />
                    <span>Pricing</span>
                  </Button>
                </PricingButtonWrapper>
              </Link>
            </Bottom>
          </DesktopOnlyPricing>
        )}
      </Content>
    </Wrapper>
  );
};

const Content = styled("div")`
  // position: fixed;
  // top: 45px;
  // left: 64px;
  height: 100%;
  /* overflow: hidden; */
  width: 280px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    position: static;
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
`;

const Wrapper = styled("div")`
  width: 280px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  @media (max-width: 1000px) {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
`;

const ActorsWrapper = styled.div`
  background-color: ${({ theme }) => theme.actorSidebarAIVoices};
  border-radius: 16px;
  position: relative;
  padding-bottom: 68px;
  order: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 1000px) {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
    order: 3;
    min-width: 100px;
    padding-bottom: 0px;
    padding-top: 20px;
  }
`;

const ActorCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  gap: 6px;
  overflow: hidden;
  position: relative;
  flex: 1;
  align-content: flex-start;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* box-shadow: ${({ theme }) => theme.cardShadow}; */
    z-index: 6;
    pointer-events: none;
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 16px;
    width: 248px;
    background: ${({ theme }) => theme.sidebarGradientAIVoices};
    height: 156px;
    z-index: 5;
    pointer-events: none;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
  @media (max-width: 1000px) {
    flex-wrap: nowrap;
    min-height: 68px;
    max-height: 68px;
    padding: 0;
    min-width: 100px;
    width: 100%;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    ::after {
      display: none;
    }
    ::before {
      display: none;
    }
  }
`;

const CircularProgressWrapper = styled.div`
  width: 100%;
  height: calc(100% - 46px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: calc(100% - 344px);
    height: 100%;
  }
`;

const ShowAll = styled(Button)<{ isLoading: boolean }>`
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  line-height: 20px;
  letter-spacing: -0.41px;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 6;
  height: 36px;
  background: ${({ theme }) => theme.button};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({ isLoading }) =>
    isLoading &&
    `
    display: none;
  `}

  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileShowAll = styled(ShowAll)`
  position: absolute !important;
  top: 100px;
  right: 16px;
  // z-index: 6;
  border-radius: 50px;
  box-shadow: none;
  -webkit-text-fill-color: unset;

  span {
    color: ${({ theme }) => theme.sidebarMenuActive};
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;

    & > button {
      display: flex;
      order: 2;
      width: 150px;
      position: initial;
    }
  }
`;

export const DesktopOnlyPricing = styled.div`
  display: contents;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const Bottom = styled.div`
  order: 3;
`;

const PricingButtonWrapper = styled.div`
  margin-top: 24px;

  & button {
    height: 60px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.secondaryBackgroundAIVoices};
    box-shadow: ${({ theme }) => theme.cardShadow};
    column-gap: 8px;
    padding: 16px 21px;

    & span {
      color: ${({ theme }) => theme.secondaryTextAIVoices};
    }
  }

  & path {
    fill: #0063b4 !important;
  }

  @media (max-width: 1000px) {
    margin: 24px 0;
  }
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 46px);

  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.41px;
    background: ${({ theme }) => theme.button};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 1000px) {
    margin: 13px 0;
    width: calc(100% - 344px);
    height: 100%;

    span {
      font-size: 16px;
    }
  }
`;

export default ActorsSidebar;
