import React from "react";

const CloseIconV4 = () => (
  <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.31433 5.02545L20.6856 14.9745" stroke="#C5C5C5" strokeWidth="5" strokeLinecap="round" />
    <path d="M9.35291 14.9662L20.7146 5.02545" stroke="#C5C5C5" strokeWidth="5" strokeLinecap="round" />
  </svg>
);

export default CloseIconV4;
