export interface User {
  userId: number;
  name: string;
  email: string;
  password?: any;
  status: number;
  roleId: number;
  role?: any;
  insertDateTime: Date;
  updateDateTime: Date;
  profilePic: string;
  country?: any;
  language?: any;
  timePreference?: any;
  contactNo?: any;
  receiveUpdates?: any;
  aiVoicesAllowed: number;
  aiVoicesUsed: number;
  hasUnlimited?: boolean;
  hasUnlimitedAIVoices?: boolean;
  hasUnlimitedAIHuman?: boolean;
  voiceCloneAllowed?: number;
  voiceCloneUsed?: number;
  apiKey?: string;
  isAPIKey: boolean;
  userToken?: string;
  aiVoicesApiUsed: number;
  aiVoicesApiAllowed: number;
  allowToAIHuman: boolean;
  allowToAIVoice: boolean;
  aiHumanUsed: number;
  aiHumanAllowed: number;
  aiHumanMaxSlidesAllowed: number;
  aiImagesAllowed: number;
  aiImagesUsed: number;
  aiVideosAllowed: number;
  aiVideosUsed: number;
  isChatEnabled: boolean;
  isAppSumoUser?: boolean;
  isSynthesysXEnabled: boolean;
  isUltraHDAIImageEnabled: boolean;
  isPhotorealisticEnabled: boolean;
  isFaceswapHumatarEnabled: boolean;
  isFullHDVideoEnabled: boolean;
  isUploadVoiceEnabled: boolean;
  isEmotiveTextsEnabled: boolean;
  isJvzooUser: boolean;
  hidePricing: boolean;
  hideMyPlans: boolean;
  allowToCloneAIHuman: boolean;
  isTranslateEnabled: boolean;
  aiHumanFreeUser: boolean;
  aiImageFreeUser: boolean;
  aiVoiceFreeUser: boolean;
  hasUnlimitedVoiceClone: boolean;
  hasVideoTranslateLicense: boolean;
  cello_code: string | null;
  creditsAllowed: number;
  creditsUsed: number;
}

export enum ProfileModules {
  "myProfile" = "myProfile",
  "changePassword" = "changePassword",
  "checkCloneVoice" = "checkCloneVoice",
  "generateApiKey" = "generateApiKey",
  "cloneVoice" = "cloneVoice",
  "checkCustomAvatar" = "checkCustomAvatar",
  "loadingPage" = "loadingPage",
  "deleteAccount" = "deleteAccount",
  "updateProfile" = "updateProfile",
  "userHistoryProjects" = "userHistoryProjects",
}
