import styled from "styled-components";
import { useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import Button, { ButtonThemes } from "../Button/Button";
import { getPrefilled } from "../../redux/reducers/popupsReducer";

interface Props {
  open: boolean;
  onClose: () => void;
}

const FaceswapReplaceProjectPopup = ({ onClose, open }: Props) => {
  const { onCancel, onConfirm } = useSelector(getPrefilled);

  const handleClose = (type: "cancel" | "confirm") => {
    if (type === "confirm") {
      onConfirm();
    } else {
      onCancel();
    }

    onClose();
  };

  return (
    <Modal
      title="Are you sure you want to move on to creating another project?"
      open={open}
      onClose={onClose}
      maxWidth={500}
      maxHeight={260}
      closeIcon={false}
      description={<>Switching to another project will delete all saved data!</>}
    >
      <ButtonWrapper>
        <Button text="Cancel" buttonTheme={ButtonThemes.Outline} onClick={() => handleClose("cancel")} />
        <Button text="Confirm" onClick={() => handleClose("confirm")} />
      </ButtonWrapper>
    </Modal>
  );
};

const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: 45%;
  }
`;

export default FaceswapReplaceProjectPopup;
