import styled from "styled-components";
import { ReactNode } from "react";

import Button from "../../../components/Button/Button";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { FaceswapProjectTab } from "..";

interface Tab {
  label: FaceswapProjectTab | string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  tooltip?: string;
}

interface Props {
  disabled?: boolean;
  data: Tab[];
  active: string;
  handleActive: (tabId: FaceswapProjectTab | string) => void;
}

const Tabs = ({ data, active, handleActive, disabled }: Props) => {
  return (
    <Wrapper>
      {data.map((tab: Tab, order: number) => (
        <ButtonWrapper key={order}>
          <Button
            key={order}
            text={tab.label}
            className={tab.label === active ? "active" : "not-active"}
            disabled={tab.disabled || disabled}
            onClick={() => handleActive(tab.label)}
            endAdornment={tab.endAdornment}
            icon={tab.startAdornment}
          />
          {tab.endAdornment && (
            <Tooltip text={tab.tooltip} position="bottom" style={{ boxShadow: "none", padding: "4px 8px 6px" }} arrow />
          )}
        </ButtonWrapper>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled?: boolean }>`
  padding: 0px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.themeSwitcherShadow};
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  min-height: 31px;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}

  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
  ::-webkit-scrollbar-track {
    margin: 0 15px;
  }

  button {
    border-radius: 52px;
    height: 30px;
    min-width: 88px;
    gap: 8px;

    span {
      user-select: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &.not-active {
      background: transparent;
      box-shadow: none;
      opacity: 0.4;

      span {
        color: ${({ theme }) => theme.primaryText};
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  border: none !important;
  width: 100%;

  & > div {
    width: max-content;
    background: #292937;
  }

  &:hover > div {
    opacity: 0.6;
    visibility: visible;
    border-color: #292937;

    &::after {
      border-color: transparent transparent #292937 transparent;
    }
  }
`;

export default Tabs;
