import styled from "styled-components";
import { useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import { FolderCircleIcon } from "../Icons/FolderIcon";
import { CircleCheckedIcon } from "../Icons/CircleCheckedIcon";
import { getPrefilled } from "../../redux/reducers/popupsReducer";

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddVoiceAudioInfoPopup = ({ open, onClose }: Props) => {
  const { actorName, actorId, isChatGpt } = useSelector(getPrefilled);

  return (
    <Wrapper>
      <Modal
        open={open}
        title={
          <Heading>
            <CircleCheckedIcon />
            <Title>Your files have been uploaded successfully!</Title>
          </Heading>
        }
        maxWidth={660}
        maxHeight={500}
        className="voice-audio-info"
        onClose={onClose}
      >
        <Description>
          <FolderCircleIcon />
          <a href={`/actors?actor=${actorName}&actorId=${actorId}`}>Open Custom AI Voices</a>
        </Description>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > div:first-of-type {
    align-items: end !important;
    max-height: 100% !important;
    bottom: 20px;
  }

  & > div:last-of-type {
    background: transparent;
    backdrop-filter: none;
  }
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  column-gap: 6px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #191b1f;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
  color: #191b1f;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 30px;
    height: 30px;
    transform: scale(1.5);
  }

  a {
    background: var(--Linear, linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }

  b {
    color: #6f7074;
    font-weight: 500;
  }
`;

export default AddVoiceAudioInfoPopup;
