import styled from "styled-components";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "../../../../components/Tooltip/Tooltip";
import useAudioPlayer from "../../../../hooks/useAudioPlayer";
import TextArea from "../../../../components/TextArea/TextArea";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../../components/Button/IconButton";
import SelectVideoTranslation, { CustomSelectOptions } from "../../../../components/Select/SelectVideoTranslaction";
import {
  getActorsListLoading,
  getSpeechTranslation,
  getTextFromAudioLoading,
} from "../../../../redux/reducers/actorReducer";
import { IActor } from "../../../../types/actor";
import { PlayIcon } from "../../../../components/Icons/PlayIcon";
import { getFullImageUrl } from "../../../../lib/getFullImageUrl";
import { PauseIcon } from "../../../../components/Icons/PauseIcon";
import { languageOptions } from "../../../../mocks/videoTranslation";
import { AIVoicesMainTabs, IAudio } from "../../../../hooks/useAIVoices";
import { DropdownIconThin } from "../../../../components/Icons/DropdownIconThin";
import { audioToTranslatedTextServer } from "../../../../redux/actions/actorActions";
import { SpeechMultilingualIcon } from "../../../../components/Icons/AIVoicesSpeechIcons";

const customOptions: CustomSelectOptions = {
  borderRadius: "9px",
  borderRadiusList: "19px",
  border: "1px solid #9E9EAE42",
  borderHover: "1px solid #9E9EAE42",
  backgroundColor: "#292937",
  textColor: "#F0F0F3",
  textColorActive: "#0063B4",
};

interface Props {
  language: string;
  actor?: IActor;
  activeTab: AIVoicesMainTabs;
  projectAudio: IAudio | null;
  translatedText: string;
  audioProjectRoute?: string;
  handleTranslatedTextChange: (e: any) => void;
  handleLanguageSelect: (language: string | number) => void;
}

const ProjectAudioSettingsTab = ({
  actor,
  language,
  activeTab,
  projectAudio,
  translatedText,
  audioProjectRoute,
  handleLanguageSelect,
  handleTranslatedTextChange,
}: Props) => {
  const dispatch = useDispatch();
  const { SpeechTranslation } = AIVoicesMainTabs;
  const hasBadge = (badge: string) => actor?.badges?.includes(badge);
  const isLoading = useSelector(getActorsListLoading);
  const isTextFromAudioLoading = useSelector(getTextFromAudioLoading);
  const speechTranslationProject = useSelector(getSpeechTranslation);
  const isProjectLoading = speechTranslationProject.isLoading;

  const {
    audioPlayer,
    onPlaying,
    onLoadedMetadata,
    onLoadStart,
    setPlaying,
    playing,
    isFetching,
    currentTime,
    duration,
    toggleAudioPlay,
  } = useAudioPlayer();

  const applyChanges = () => {
    if (!projectAudio) {
      toast.error("Please upload or record the audio.");
      return;
    }

    if (!language) {
      toast.error("Please select the language into which you want to translate the audio.");
      return;
    }

    const formData = new FormData();
    formData.append("file", projectAudio?.file);

    dispatch(audioToTranslatedTextServer({ language: language.toLowerCase(), data: formData }));
  };

  useEffect(() => {
    setPlaying(false);
  }, [actor]);

  return isLoading ? (
    <EmptyState>
      <CircularProgress color="#fff" />
    </EmptyState>
  ) : !actor ? (
    <EmptyState>
      <span>Actor not selected</span>
    </EmptyState>
  ) : (
    <Wrapper>
      {actor?.audioSampleLink && (
        <audio
          src={actor?.audioSampleLink}
          ref={audioPlayer}
          onTimeUpdate={onPlaying}
          onLoadedMetadata={onLoadedMetadata}
          onLoadStart={onLoadStart}
        />
      )}
      <Content>
        <img src={actor?.photo} alt="" />
        <NameWrapper>
          <Name>{actor?.name}</Name>
          <Flag>
            {hasBadge("Multilingual") ? (
              <SpeechMultilingualIcon />
            ) : (
              <img src={actor?.flagPath ? getFullImageUrl(actor.flagPath) : "/images/flag.png"} alt="" />
            )}
          </Flag>
        </NameWrapper>
        <IconButtonWrapper>
          <IconButton
            iconButtonTheme={IconButtonThemes.Rounded}
            disabled={!actor?.audioSampleLink}
            onClick={toggleAudioPlay}
            icon={
              isFetching ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress color="#F0F0F3" />
                </div>
              ) : !playing || currentTime === duration ? (
                <PlayIcon />
              ) : (
                <PauseIcon />
              )
            }
          />
          {!actor?.audioSampleLink && <Tooltip text="No opportunity to listen to the actor" position="bottom" />}
        </IconButtonWrapper>
        {activeTab === SpeechTranslation && (
          <BoxWrapper>
            <LanguageWrapper>
              <p>Select Language</p>
              <SelectWrapper>
                <SelectVideoTranslation
                  value={language}
                  onChange={handleLanguageSelect}
                  optionsList={languageOptions.data}
                  actionIcon={<DropdownIconThin />}
                  placeholder="Select Language"
                  customOptions={customOptions}
                  disabled={!projectAudio || isProjectLoading || !!audioProjectRoute}
                />
                {!projectAudio && (
                  <Tooltip className="language-tooltip" text="Please upload or record audio" position="bottom" />
                )}
              </SelectWrapper>
            </LanguageWrapper>
            <TextAreaWrapper>
              <p>Edit script</p>
              {isTextFromAudioLoading ? (
                <CircularProgressWrapper>
                  <CircularProgress color="#ffffff" />
                </CircularProgressWrapper>
              ) : (
                <div>
                  <TextArea
                    value={translatedText}
                    placeholder="Edit your script here..."
                    onChange={handleTranslatedTextChange}
                    disabled={!projectAudio || isProjectLoading || !!audioProjectRoute}
                  />
                  {!projectAudio && <Tooltip text="Please upload or record audio" position="top" />}
                </div>
              )}
              <Button
                text="Apply changes"
                buttonTheme={ButtonThemes.Transparent}
                onClick={applyChanges}
                disabled={
                  !projectAudio || !language || isTextFromAudioLoading || isProjectLoading || !!audioProjectRoute
                }
              />
            </TextAreaWrapper>
          </BoxWrapper>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 255px;
  height: 100%;
  overflow: hidden auto;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 121px;
    height: 120px;
    border-radius: 50%;
  }
`;

const NameWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  column-gap: 9px;
  width: 230px;
`;

const Name = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

const Flag = styled.div`
  & img {
    width: 16px;
    border-radius: 0px;
    height: auto;
  }
`;

const IconButtonWrapper = styled.div`
  position: relative;
  margin: 7px 0 15px;

  button {
    background: #0063b4;
    box-shadow: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    svg {
      display: block;
      width: 20px;
      height: 20px;
      transform: translateX(1px);
    }
  }

  :hover > div {
    opacity: 1;
    visibility: visible;
    padding: 5px 6px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    min-width: max-content;
    box-shadow: none;

    span {
      font-size: 10px !important;
      line-height: 12px !important;
      text-transform: none !important;
    }
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 230px;
  margin-left: 25px;
  margin-right: 10px;
`;

const LanguageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.57px;
    color: #ababb9ba;
  }
`;

const TextAreaWrapper = styled.div`
  position: relative;
  width: 230px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > div {
    position: relative;
    width: 100%;
    height: 320px;
  }

  &:hover > div > div {
    opacity: 1;
    visibility: visible;
    padding: 10px 6px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    min-width: max-content;
    box-shadow: none;
    top: 100%;

    span {
      font-size: 10px !important;
      line-height: 12px !important;
      text-transform: none !important;
    }
  }

  textarea {
    height: 320px;
    background: #292937;
    border: 1px solid #9e9eae42;
    border-radius: 9px;
    padding: 8px;
    color: #ababb9;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.5px;
    text-align: left;
    margin-top: 0px;

    ::placeholder {
      display: flex;
      color: #ababb9;
      font-family: Mulish;
      font-size: 11px;
      font-weight: 500;
      line-height: 2;
    }
    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #c1c1c1;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
      margin: 8px 0;
    }
  }

  p {
    align-self: baseline;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.57px;
    color: #ababb9ba;
  }

  button {
    width: 140px;
    height: 40px;
    border-radius: 6px;
    background: #0063b4;
    display: flex;
    justify-content: center;

    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: #ffffff;
    }
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 320px;
  background: #292937;
  border: 1px solid #9e9eae42;
  border-radius: 9px;
  padding: 8px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 7px;
  height: 42px;
  position: relative;

  & > div {
    & > div {
      height: 40px;
    }

    & span > div {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;

      & > img {
        // border-radius: 50% !important;
        // object-fit: cover;
        width: 20px;
        height: 15px;
      }
    }

    svg {
      // border: 1px solid #35354a;
      width: 18px;
      height: 18px;

      path {
        fill: none;
        stroke: #ffffff;
      }
    }
  }

  :hover > .language-tooltip {
    opacity: 1;
    visibility: visible;
    padding: 5px 6px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    min-width: max-content;
    box-shadow: none;

    span {
      font-size: 10px !important;
      line-height: 12px !important;
      text-transform: none !important;
    }
  }
`;

export default ProjectAudioSettingsTab;
