import styled from "styled-components";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "./Tabs";
import Tooltip from "../../../components/Tooltip/Tooltip";
import TextArea from "../../../components/TextArea/TextArea";
import CircularProgress from "../../../components/Icons/CircularProgress";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { downloadFile } from "../../../lib/downloadFile";
import { HelpIcon } from "../../../components/Icons/HelpIcon";
import { TrashIcon } from "../../../components/Icons/TrashIcon";
import { FolderIcon } from "../../../components/Icons/FolderIcon";
import { CloudArrowUp } from "../../../components/Icons/CloudArrowUp";
import { DownloadIcon } from "../../../components/Icons/DownloadIcon";
import { FaceIcon, MediaIcon, UploadBoxIcon } from "../../../components/Icons/FaceSwapIcons";
import {
  clearImageByType,
  faceswapUploadFileServer,
  getFaceswapImageFromTextServer,
  setTextForImage,
} from "../../../redux/actions/faceswapActions";
import {
  getFaceswap,
  getTextForImage,
  getFaceswapSource,
  getFaceswapSourceLoading,
  getFaceswapTarget,
  getFaceswapTargetLoading,
} from "../../../redux/reducers/faceswapReducer";
import PlayPauseButton from "./PlayPauseButton";

const MAX_SIZE_IMAGE = 3145728;
const MAX_SIZE_VIDEO = 52428800;
const ACCEPTED_FORMATS_IMAGE = "image/png, image/jpg, image/jpeg";
const ACCEPTED_FORMATS_VIDEO = "video/mp4";

const tabs = [
  {
    label: "Image",
  },
  {
    label: "Video",
  },
];

interface Props {
  cardTitle: string;
  activeTab?: string;
  setActiveTab?: (mediaTab: string) => void;
  isTabSwitchEnabled?: boolean;
  isTextGenerationEnabled?: boolean;
}

const SourceFaceCard = ({
  cardTitle,
  activeTab = "Image",
  setActiveTab,
  isTextGenerationEnabled = true,
  isTabSwitchEnabled = false,
}: Props) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [imageLoad, setImageLoad] = useState(true);

  const project = useSelector(getFaceswap);
  const targetUrl = useSelector(getFaceswapTarget);
  const sourceUrl = useSelector(getFaceswapSource);
  const isLoadingSource = useSelector(getFaceswapSourceLoading);
  const isLoadingTarget = useSelector(getFaceswapTargetLoading);
  const textFieldValue = useSelector(getTextForImage);

  const videoRef = useRef(document.createElement("video"));
  const [playing, setPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);

  const target = project?.target || targetUrl;
  const source = project?.source || sourceUrl;
  const isAction = !project || !project?.faceswapProjectId;
  const isImageTab = activeTab === "Image";

  const handleInputClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  };

  const handleTextAreaChange = (e: any) => {
    const value = e.target.value;
    dispatch(setTextForImage(value));
  };

  const handleLoad = () => setImageLoad(false);

  const handleDelete = (type: "source" | "target") => () => {
    setImageLoad(true);
    dispatch(clearImageByType(type));
  };

  const handleGenerateImageFromText = () => {
    if (!textFieldValue) {
      toast.error("Description must not be empty.");
      return;
    }

    dispatch(getFaceswapImageFromTextServer(textFieldValue));
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    const allowedTypes = isImageTab ? /image\/(png|jpeg|jpg)/ : /video\/(mp4)/;

    if (uploadedFile && uploadedFile.size > (isImageTab ? MAX_SIZE_IMAGE : MAX_SIZE_VIDEO)) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setErrorSize(true);
      toast.error(`The file must not exceed ${isImageTab ? "3MB" : "50MB"}! Please upload a smaller file.`);
    } else if (!uploadedFile.type.match(allowedTypes)) {
      if (inputRef.current) {
        inputRef.current.value = "";
      }

      setErrorSize(true);
      toast.error(`Incorrect file format! Please upload the file in ${isImageTab ? "PNG or JPG" : "MP4"} format.`);
    } else {
      if (!isImageTab) {
        const video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;

          if (duration > 180) {
            if (inputRef.current) {
              inputRef.current.value = "";
            }

            setErrorSize(true);
            toast.error("The video must be less than 3 minutes! Please upload a shorter video.");
          } else {
            const formData = new FormData();
            formData.append("File", uploadedFile);

            setErrorSize(false);
            setImageLoad(true);
            dispatch(faceswapUploadFileServer({ data: formData, type: "target" }));
          }
        };

        video.src = URL.createObjectURL(uploadedFile);
      } else {
        const formData = new FormData();
        formData.append("File", uploadedFile);

        setErrorSize(false);
        setImageLoad(true);
        dispatch(faceswapUploadFileServer({ data: formData, type: "source" }));
      }
    }
  };

  const togglePlayed = () => {
    setIsPlayed(!isPlayed);
    setPlaying(false);
    videoRef.current.currentTime = 0;
  };

  const togglePlay = () => {
    setPlaying(!playing);
    if (playing === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab && setActiveTab(tab);
  };

  useEffect(() => {
    setImageLoad(true);
  }, [source]);

  return (
    <Card>
      <Title>
        {cardTitle === "Media" ? <MediaIcon /> : <FaceIcon />}
        <Text>{cardTitle}</Text>
        <Info>
          Guide
          <HelpIcon />
          <Tooltip
            text="Upload an image with a face. For best results, please avoid images with half face, side face, blurry face or faces with glasses."
            style={{ boxShadow: "none", borderRadius: "7px", padding: "4px 8px 6px" }}
            position="bottom"
            arrow
          />
        </Info>
      </Title>
      <DragAndDropWrapper visible={!!source}>
        {isTabSwitchEnabled && activeTab && (
          <TabsWrapper>
            <Tabs
              data={tabs}
              active={activeTab}
              handleActive={handleActiveTab}
              disabled={isLoadingSource || !!source || isLoadingTarget || !!target}
            />
          </TabsWrapper>
        )}
        <CardContent>
          {!isLoadingSource && isAction && (
            <input
              ref={inputRef}
              type="file"
              id="faceswap"
              name="faceswap"
              accept={isImageTab ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_VIDEO}
              onChange={handleUpload}
            />
          )}
          {source && isImageTab ? (
            <ImageWrapper id="img-wrapper">
              <UploadedImage
                src={source}
                height={"100%"}
                width={"100%"}
                onLoad={handleLoad}
                style={{ display: imageLoad ? "none" : "block" }}
              />
              {imageLoad && <CircularProgress color="#fff" />}
            </ImageWrapper>
          ) : target && !isImageTab ? (
            <VideoWrapper>
              <video
                ref={videoRef}
                src={target}
                width={"100%"}
                height="100%"
                onLoadedData={handleLoad}
                onEnded={togglePlayed}
                style={{ display: imageLoad ? "none" : "block" }}
              />
              {!imageLoad ? (
                <PlayPauseButton isPlay={playing} handlePlayChange={() => togglePlay()} />
              ) : (
                <CircularProgress color="#fff" />
              )}
            </VideoWrapper>
          ) : (
            <ActionInfo>
              <ActionIcon>
                <CloudArrowUp />
              </ActionIcon>
              {isImageTab ? (
                <>
                  {cardTitle === "Media" ? (
                    <ActionTitle>Upload a image</ActionTitle>
                  ) : (
                    <ActionTitle>Upload a photo with a face (Base face image)</ActionTitle>
                  )}
                  <ActionText isError={errorSize}>File Supported: PNG, JPG. Maximum size: 3MB</ActionText>
                </>
              ) : (
                <>
                  <ActionTitle>Upload a video</ActionTitle>
                  <ActionText isError={errorSize} style={{ maxWidth: "120px" }}>
                    File Supported: MP4. Maximum size: 50MB
                  </ActionText>
                </>
              )}
            </ActionInfo>
          )}
          {isAction && (
            <ButtonsWrapper>
              {source && isImageTab ? (
                <>
                  {/* <Button
                    className="rounded-button"
                    icon={<FolderIcon />}
                    disabled={isLoadingSource}
                    buttonTheme={ButtonThemes.Transparent}
                    onClick={() => {
                      return;
                    }}
                    style={{ zIndex: "1" }}
                  /> */}
                  <Button
                    className="rounded-button"
                    icon={<UploadBoxIcon />}
                    disabled={isLoadingSource}
                    buttonTheme={ButtonThemes.Transparent}
                    onClick={handleInputClick}
                    style={{ zIndex: "1" }}
                  />
                  <Button
                    className="rounded-button"
                    icon={<DownloadIcon />}
                    disabled={isLoadingSource}
                    buttonTheme={ButtonThemes.Transparent}
                    onClick={(e: any) => downloadFile(e, source)}
                    style={{ zIndex: "1" }}
                  />
                  <Button
                    className="rounded-button"
                    icon={<TrashIcon />}
                    onClick={handleDelete("source")}
                    buttonTheme={ButtonThemes.Transparent}
                    style={{ zIndex: "1" }}
                  />
                </>
              ) : target && !isImageTab ? (
                <>
                  <Button
                    text={"Upload"}
                    icon={<UploadBoxIcon />}
                    buttonTheme={ButtonThemes.Transparent}
                    onClick={handleInputClick}
                    style={{ zIndex: "1" }}
                  />
                  <Button
                    text={"Delete"}
                    onClick={handleDelete("target")}
                    buttonTheme={ButtonThemes.Transparent}
                    style={{ zIndex: "1" }}
                  />
                </>
              ) : isImageTab ? (
                <>
                  {/* <Button
                    text={"My Library"}
                    icon={<FolderIcon />}
                    disabled={isLoadingSource}
                    buttonTheme={ButtonThemes.Transparent}
                    onClick={() => {
                      return;
                    }}
                    style={{ zIndex: "1" }}
                  /> */}
                  <Button
                    text={isLoadingSource ? "Loading..." : "Upload"}
                    icon={<UploadBoxIcon />}
                    disabled={isLoadingSource}
                    buttonTheme={ButtonThemes.Transparent}
                    onClick={handleInputClick}
                    style={{ zIndex: "1" }}
                  />
                </>
              ) : (
                <Button
                  text={isLoadingTarget ? "Loading..." : "Upload"}
                  icon={<UploadBoxIcon />}
                  disabled={isLoadingTarget}
                  buttonTheme={ButtonThemes.Transparent}
                  onClick={handleInputClick}
                  style={{ zIndex: "1" }}
                />
              )}
            </ButtonsWrapper>
          )}
        </CardContent>
      </DragAndDropWrapper>
      {isTextGenerationEnabled && (
        <CreateFromText>
          <TextArea
            value={textFieldValue}
            placeholder="Describe your character and click “Create” to generate"
            onChange={handleTextAreaChange}
            disabled={isLoadingSource}
            rows={3}
          />
          <Button
            text={isLoadingSource && textFieldValue ? "Loading..." : "Create"}
            icon={<UploadBoxIcon />}
            disabled={!textFieldValue || isLoadingSource}
            buttonTheme={ButtonThemes.Transparent}
            onClick={handleGenerateImageFromText}
            style={{ zIndex: "1" }}
          />
        </CreateFromText>
      )}
    </Card>
  );
};

const Card = styled.div`
  flex: 1;
  min-width: 304px;
  background: #15171a;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 17px 18px;
  margin-bottom: 18px;

  @media (max-width: 1200px) {
    padding: 10px 18px 17px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  gap: 10px;

  & > svg {
    width: 25px;
    height: 25px;
  }

  & > :first-child {
    width: 32px;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  margin-right: auto;
`;

const ImageWrapper = styled.div`
  position: relative;
  align-self: center;
  max-width: 600px;
  max-height: 600px;
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 400px;
  max-width: fit-content;
  align-self: center;

  & video {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Info = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 15px;
  font-weight: 800;
  line-height: 18.83px;
  color: #ff9000;

  :hover > div {
    left: -70px;
    top: 30px;
    padding: 12px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    width: 280px;
    box-shadow: none;
    opacity: 0.9;
    visibility: visible;
    background: #292937;

    &::after {
      opacity: 0.6;
      border-color: transparent transparent #292937 transparent;
    }

    span {
      font-weight: 500;
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }

  svg path {
    stroke: #ff9000;
  }
`;

const DragAndDropWrapper = styled.div<{ visible?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div > input {
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
    ${({ visible }) =>
      visible &&
      `
        z-index: -1;
      `}
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 232px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-self: center;
  margin: 0 0 4px;
  height: 32px;
  border-radius: 52px;
  border: 0px;
  background: #292937;

  & > div {
    background: #292937;
    box-shadow: none;
    z-index: 10;

    & > div {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      border: 0px;

      & > div {
        top: 40px;
        left: -40px;
        width: 280px;
        opacity: 0.6;
        border-radius: 7px;
        background: #292937;

        &::after {
          opacity: 0.6;
          border-color: transparent transparent #292937 transparent;
        }
      }
    }

    button {
      box-shadow: none;
      background: #106cb6;

      &.not-active {
        opacity: 0.4;
      }
      &.not-active span {
        opacity: 1;
      }
    }

    &:hover {
      & div {
        opacity: 0.9;
      }
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;

  & button {
    height: 30px;
    max-width: 130px;
    padding: 0px 10px;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid #6f7074;
    justify-content: center;

    span {
      text-wrap: nowrap;
    }

    .folder-icon path {
      fill: none;
    }
  }

  .rounded-button {
    width: 30px;
    padding: 0px;
  }
`;

const ActionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const ActionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px dashed #ffffff;
  border-radius: 360px;

  @media (max-width: 1200px) {
    width: 100px;
    height: 100px;
  }

  svg {
    width: 55px;
    height: 55px;
    path {
      stroke: #b0dbff;
    }
  }
`;

const ActionTitle = styled.div`
  max-width: 210px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

const ActionText = styled.div<{ isError?: boolean }>`
  max-width: 135px;
  color: #959595;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;

  ${({ isError }) =>
    isError &&
    `
      color: #ff6c76;
    `}
`;

const CreateFromText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 122px;
  margin-top: 20px;

  textarea {
    height: 108px;
    border-radius: 32px;
    padding: 14px 17px;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    background: #1f2024;

    &::placeholder {
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    bottom: 0px;
    width: auto;
    height: 30px;
    padding: 6px 18px;
    gap: 16px;
    border-radius: 360px;
    border: 1px solid #6f7074;
    background: #8707ff;

    svg {
      flex-shrink: 0;
    }
  }
`;

export default SourceFaceCard;
