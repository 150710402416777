import styled from "styled-components";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import { PauseIcon, PlayIcon, TrashIcon } from "../Icons/Icons";
import { WaveformIcon, WaveformLongIcon } from "../Icons/WaveformIcon";
import { useEffect } from "react";

interface Props {
  url: string;
  isLongWave?: boolean;
  handleDelete?: () => void;
  setDuration?: (duration: number) => void;
}

const AudioPlayerCard = ({ url, isLongWave, handleDelete, setDuration }: Props) => {
  const { audioPlayer, currentTime, duration, playing, onPlaying, setPlaying, onLoadedMetadata } = useAudioPlayer();

  const onWaveClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setPlaying(!playing);
  };

  const onEnded = () => {
    setPlaying(false);
  };

  useEffect(() => {
    if (setDuration) setDuration(duration);
  }, [duration]);

  return (
    <Wrapper onClick={(e: any) => onWaveClick(e)}>
      <audio
        src={url}
        ref={audioPlayer}
        onEnded={onEnded}
        onTimeUpdate={onPlaying}
        onLoadedMetadata={onLoadedMetadata}
      />
      <ButtonIcon onClick={handleClick}>{playing ? <PauseIcon /> : <PlayIcon />}</ButtonIcon>
      {isLongWave ? (
        <>
          <WaveformLongIcon />
          {handleDelete && (
            <ButtonIcon className="delete-button" onClick={handleDelete}>
              <TrashIcon />
            </ButtonIcon>
          )}
        </>
      ) : (
        <WaveformIcon />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  background: ${({ theme }) => theme.primaryBackground};
  height: 23px;
  width: 140px;
  padding: 3px 8px;
  border-radius: 5px;
  gap: 4px;
  align-items: center;

  & > div:first-child {
    gap: 0px;
    width: calc(100% - 22px);
  }
`;

const ButtonIcon = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #009af7;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}

  svg {
    height: 9px;
    width: 9px;
  }

  &.delete-button {
    background: transparent;
    margin-left: auto;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: #ffffff};
      }
    }
  }
`;

export default AudioPlayerCard;
