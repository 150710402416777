import styled from "styled-components";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { faceswapSuggestMedia } from "../../../mocks/faceswap";
import { addMediaToTarget } from "../../../redux/actions/faceswapActions";

interface Props {
  activeMediaTab: string;
  isTransformSamples?: boolean;
}

const IdeasForVideo = ({ activeMediaTab, isTransformSamples }: Props) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <div>No idea? Try these {activeMediaTab === "Image" ? "images" : "videos"}.</div>
      <VideosWrapper>
        {faceswapSuggestMedia
          .filter((item) => item.type === (isTransformSamples ? "TransformSamples" : activeMediaTab))
          .map((item) => (
            <Media key={item.id} {...item} onClick={() => dispatch(addMediaToTarget(item.outputUrl))} />
          ))}
      </VideosWrapper>
    </Wrapper>
  );
};

interface MediaProps {
  id: number;
  url: string;
  type: string;
  alt?: string;
  onClick: () => void;
}

const Media = ({ id, url, alt, type, onClick }: MediaProps) => {
  const [load, setLoad] = useState(true);

  return (
    <MediaWrapper key={id} onClick={onClick}>
      {type === "Image" ? (
        <img src={url} alt={alt} onLoad={() => setLoad(false)} />
      ) : (
        <video src={url} onLoadedData={() => setLoad(false)} />
      )}
      {load && <Placeholder />}
    </MediaWrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: auto;
  padding: 5px 15px 15px;
`;

const VideosWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 8px;
  height: 100%;
  padding-bottom: 5px;
  max-height: 110px;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
`;

const MediaWrapper = styled.div`
  position: relative;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  & img,
  & video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #e9e9e9, #c4c3c3);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export default IdeasForVideo;
