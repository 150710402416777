import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertFromRaw, convertToRaw, EditorState, SelectionState } from "draft-js";

import Tooltip from "../Tooltip/Tooltip";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import CircularProgress from "../Icons/CircularProgress";
import ProfileActorSidebarBox from "./ProfileActorSidebarBox";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { Block } from "../Editor/Editor";
import { IActor } from "../../types/actor";
import { EditorSelect } from "../../mocks/actors";
import { Paragraphs, Zone } from "../../types/project";
import { setInlineStyle } from "../../lib/setInlineStyle";
import { getFullImageUrl } from "../../lib/getFullImageUrl";
import { ActorTypeId, SidebarBox } from "../../types/sidebarBox";
import { getProfile } from "../../redux/reducers/profileReducer";
import { DropdownIcon, PauseIcon, PlayIcon } from "../Icons/Icons";
import { SpeechMultilingualIcon } from "../Icons/AIVoicesSpeechIcons";

interface Props {
  active?: boolean;
  featureActive?: number;
  sidebarBoxes: SidebarBox[];
  actor: IActor | undefined;
  editorContent: any;
  setEditorContent: any;
  paragraphActive: number | undefined;
  paragraphs: Paragraphs[];
  setParagraphs: (paragraphs: Paragraphs[]) => void;
  lastSel: SelectionState;
  paragraphActor: IActor | undefined;
  selectedZone: Zone | undefined;
  editorSelect: string;
}

const ProfileActorSidebar = ({
  active,
  featureActive,
  actor,
  sidebarBoxes,
  editorContent,
  setEditorContent,
  paragraphActive,
  paragraphs,
  setParagraphs,
  lastSel,
  paragraphActor,
  selectedZone,
  editorSelect,
}: Props) => {
  const { isEmotiveTextsEnabled } = useSelector(getProfile);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const { Simple } = EditorSelect;

  const toggling = () => setIsOpen(!isOpen);

  const {
    audioPlayer,
    onPlaying,
    onLoadedMetadata,
    onLoadStart,
    setPlaying,
    playing,
    isFetching,
    currentTime,
    duration,
    toggleAudioPlay,
  } = useAudioPlayer();

  const onOptionClicked = (
    value: string,
    values: { id?: string | number | undefined; text: string; label?: string | undefined }[],
  ) => {
    const newValue = { key: "style", value };
    const newParagraphs = paragraphs.map((paragraph) =>
      paragraph.order === paragraphActive
        ? {
            ...paragraph,
            data: paragraph.data.map((data, index: number) =>
              index === (featureActive as number) / 2
                ? {
                    ...data,
                    features: !value
                      ? data.features.filter((features) => features.key !== "style")
                      : data.features.map((features) => (features.key === "style" ? newValue : features)),
                  }
                : data,
            ),
          }
        : paragraph,
    );

    setParagraphs(newParagraphs);

    let state = EditorState.forceSelection(editorContent, lastSel);
    const valuesIndex = values.findIndex((val) => val.text === value);

    handleChangeStyle({ style: values[valuesIndex].text }, state);
    setSelectedOption(value);
    setIsOpen(false);
  };

  const sidebarBoxByTypeId = sidebarBoxes.filter(
    (box) => box.actorTypeId === (actor?.actorTypeId || paragraphActor?.actorTypeId),
  );

  const sidebarBox = sidebarBoxByTypeId.length
    ? sidebarBoxByTypeId
    : sidebarBoxes.filter((box) => box.actorTypeId === ActorTypeId.TWO);

  const stylesValues = (actor || paragraphActor)?.styles?.length
    ? ["none"].concat((actor || paragraphActor)?.styles?.split(",") || []).map((style, index: number) => {
        return {
          id: index,
          text: index === 0 ? "" : style.charAt(0).toUpperCase() + style.slice(1),
          label: style.charAt(0).toUpperCase() + style.slice(1),
        };
      })
    : "";

  const styles = [
    {
      id: 20,
      title: "style",
      background: "#779930",
      defaultValue: "None",
      values: stylesValues,
      defaultTitle: "style",
    },
  ];

  const stylesSidebar =
    (actor || paragraphActor)?.actorId === 1 && (actor || paragraphActor)?.language?.slice(0, 5) === "en-US"
      ? sidebarBox.filter((box) => box.title === "style")
      : stylesValues.length
      ? styles
      : [];

  const handleChangeStyle = (style: { [s: string]: unknown } | ArrayLike<unknown>, state: any) => {
    let content: any = convertToRaw(state.getCurrentContent());
    const selection = state.getSelection();
    const selectionKey = selection.getAnchorKey();

    if (!Object.entries(style).length) return;

    content = setInlineStyle(content, selectionKey, style);

    if (!Object.values(style)[0]) {
      const newBlocks = content.blocks.map((block: any) =>
        block.key === selectionKey
          ? {
              ...block,
              inlineStyleRanges: block.inlineStyleRanges.filter(
                (inlineStyleRange: any) => inlineStyleRange.style !== "style_",
              ),
            }
          : block,
      );

      content = { ...content, blocks: newBlocks };
    }

    setEditorContent(EditorState.forceSelection(EditorState.createWithContent(convertFromRaw(content)), selection));
  };

  const setTimerValue = ({ state, selectionKey, value }: any) => {
    let content = convertToRaw(state.getCurrentContent());
    const selectionIndex = content.blocks.findIndex((block) => block.key === selectionKey);
    const timerIndex = selectionIndex + 1;
    value += "s";

    if (content.blocks[timerIndex]) {
      content.blocks[timerIndex].text = value;
      content.blocks[timerIndex].inlineStyleRanges[0].length = value.length;

      return EditorState.createWithContent(convertFromRaw(content));
    }

    content.blocks.push(
      new Block({
        key: Math.random().toString(16).slice(2).slice(0, 5),
        text: value,
        inlineStyles: [{ offset: 0, length: value.length + 1, style: "pause" }],
      }),
      new Block({
        key: Math.random().toString(16).slice(2).slice(0, 5),
        text: "",
        inlinyStyles: [],
      }),
    );

    return EditorState.createWithContent(convertFromRaw(content));
  };

  const handleChange = (
    e: any,
    key: string,
    step: number,
    values: { id?: string | number | undefined; text: string; label?: string | undefined }[],
  ) => {
    const newValue = { key, value: values[Math.round(e.target.value / step)].text };
    const newParagraphs = paragraphs.map((paragraph) =>
      paragraph.order === paragraphActive
        ? {
            ...paragraph,
            data: paragraph.data.map((data, index: number) =>
              index === (featureActive as number) / 2
                ? {
                    ...data,
                    features: data.features.map((features) => (features.key === key ? newValue : features)),
                  }
                : data,
            ),
          }
        : paragraph,
    );

    if (key !== "pause") setParagraphs(newParagraphs);

    const selectionKey = lastSel.getAnchorKey();
    const newContent = EditorState.createWithContent(
      convertFromRaw(
        setInlineStyle(convertToRaw(editorContent.getCurrentContent()), selectionKey, {
          isActive: "active",
        }),
      ),
    );
    let state = EditorState.forceSelection(newContent, lastSel);

    if (key === "pause") {
      const selection = state.getSelection();
      const selectionKey = selection.getAnchorKey();

      state = EditorState.forceSelection(
        setTimerValue({ state: newContent, selectionKey, value: values[Math.round(e.target.value / step)].text }),
        selection,
      );
    }

    handleChangeStyle({ [key]: values[Math.round(e.target.value / step)].text }, state);
  };

  const hasBadge = (badge: string) => (actor || paragraphActor)?.badges?.includes(badge);

  useEffect(() => {
    setPlaying(false);
  }, [actor]);

  return (
    <Wrapper active={active}>
      {(actor || paragraphActor)?.audioSampleLink && (
        <audio
          src={(actor || paragraphActor)?.audioSampleLink}
          ref={audioPlayer}
          onTimeUpdate={onPlaying}
          onLoadedMetadata={onLoadedMetadata}
          onLoadStart={onLoadStart}
        />
      )}
      <Content>
        <img src={actor?.photo || paragraphActor?.photo} alt="" />
        <NameWrapper>
          <Name>{actor?.name || paragraphActor?.name}</Name>
          <Flag>
            {hasBadge("Multilingual") ? (
              <SpeechMultilingualIcon />
            ) : (
              <img
                src={
                  actor?.flagPath || paragraphActor?.flagPath
                    ? getFullImageUrl(actor?.flagPath || paragraphActor?.flagPath)
                    : "/images/flag.png"
                }
                alt=""
              />
            )}
          </Flag>
        </NameWrapper>
        <IconButtonWrapper>
          <IconButton
            iconButtonTheme={IconButtonThemes.Rounded}
            disabled={!(actor || paragraphActor)?.audioSampleLink}
            onClick={toggleAudioPlay}
            icon={
              isFetching ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress color="#F0F0F3" />
                </div>
              ) : !playing || currentTime === duration ? (
                <PlayIcon />
              ) : (
                <PauseIcon />
              )
            }
          />
          {!(actor || paragraphActor)?.audioSampleLink && (
            <Tooltip text="No opportunity to listen to the actor" position="bottom" />
          )}
        </IconButtonWrapper>
        <BoxWrapper>
          {sidebarBox
            .filter((box) => box.title !== "style")
            .map((box) => {
              const activeData = selectedZone;
              const activeFeatureValue = activeData?.features?.find(
                (features) => features.key === box.defaultTitle,
              )?.value;

              const sidebarId = box.values.find((value) => value.text === activeFeatureValue)?.id;

              const sidebarDefaultId = box.values.find((value) => value.text === box.defaultValue)?.id;
              const sidebarValue = ((sidebarId as number) - 1) * box.step;
              const sidebarDefaultValue = ((sidebarDefaultId as number) - 1) * box.step;

              return (
                <>
                  <ProfileActorSidebarBox
                    key={box.id}
                    {...box}
                    value={!isNaN(sidebarValue) ? sidebarValue : sidebarDefaultValue}
                    handleChange={(e: any) => handleChange(e, box.title, box.step, box.values)}
                    disabled={editorSelect === Simple}
                  />
                </>
              );
            })}
          {isEmotiveTextsEnabled &&
            stylesSidebar &&
            stylesSidebar?.map(({ id, title, background, defaultValue, values, defaultTitle }: any) => {
              const activeData = selectedZone;
              const activeFeatureValue = activeData?.features?.find(
                (features: any) => features.key === defaultTitle,
              )?.value;

              return (
                <DropdownWrapper key={id}>
                  <DropdownContent>
                    <DropdownHeading disabled={editorSelect === Simple}>
                      <span>{title}</span>
                      {editorSelect !== Simple && <div style={{ background }} />}
                    </DropdownHeading>
                    <DropdownSelectOption
                      disabled={editorSelect === Simple}
                      onClick={() => editorSelect !== Simple && toggling()}
                    >
                      <span>{!activeFeatureValue ? defaultValue : activeFeatureValue}</span>
                      <IconButton
                        disabled={editorSelect === Simple}
                        iconButtonTheme={IconButtonThemes.Transparent}
                        icon={
                          !isOpen ? (
                            <DropdownIcon />
                          ) : (
                            <Rotate>
                              <DropdownIcon />
                            </Rotate>
                          )
                        }
                      />
                    </DropdownSelectOption>
                    {isOpen && (
                      <div>
                        <DropdownList disabled={editorSelect === Simple}>
                          {values.map((option: any) => (
                            <ListItem
                              onClick={() => editorSelect !== Simple && onOptionClicked(option.text, values)}
                              key={option.id}
                            >
                              {option.label}
                            </ListItem>
                          ))}
                        </DropdownList>
                      </div>
                    )}
                  </DropdownContent>
                </DropdownWrapper>
              );
            })}
        </BoxWrapper>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ active?: boolean }>`
  width: 0%;
  min-width: 0%;
  height: 100%;
  visibility: hidden;
  overflow: hidden auto;
  opacity: 1;
  transition: 0.2s;

  ${({ active }) =>
    active &&
    `
  min-width: 255px;
  width: 255px;
        visibility: visible;      
  `}

  @media (max-width: 1001px) {
    ${({ active }) =>
      active &&
      `
        padding: 40px 52px 32px;
        min-width: 100%;
        width: 100%;   
  `}
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 121px;
    height: 120px;
    border-radius: 50%;
  }
`;

const NameWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  column-gap: 9px;
  justify-content: center;
  flex-flow: row wrap;
  width: 240px;
`;

const Name = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

const Flag = styled.div`
  & img {
    width: 16px;
    border-radius: 0px;
    height: auto;
  }
`;

const IconButtonWrapper = styled.div`
  position: relative;
  margin: 7px 0 15px;

  button {
    background: #0063b4;
    box-shadow: none;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    svg {
      display: block;
      width: 20px;
      height: 20px;
      transform: translateX(1px);
    }
  }

  :hover > div {
    opacity: 1;
    visibility: visible;
    padding: 5px 6px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    min-width: max-content;
    box-shadow: none;

    span {
      font-size: 10px !important;
      line-height: 12px !important;
      text-transform: none !important;
    }
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DropdownWrapper = styled.div`
  background: ${({ theme }) => theme.secondaryBackground};
  border: 1px solid #35354a;
  border-radius: 12px;
  width: 232px;
`;

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`;

const DropdownHeading = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${({ disabled }) => (disabled ? "#4B4B51" : "#999BAF")};
    text-transform: capitalize;
  }

  div {
    width: 24px;
    height: 16px;
    border-radius: 28px;
  }
`;

const DropdownList = styled.ul<{ disabled?: boolean }>`
  max-height: 200px;
  overflow: hidden auto;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.secondaryBackground};
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${({ disabled }) => (disabled ? "#4B4B51" : "#ffffff")};

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const DropdownSelectOption = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${({ disabled }) => (disabled ? "#4B4B51" : "#ffffff")};
  }
`;

const ListItem = styled.li`
  list-style: none;
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #35354a;

  :last-of-type {
    border-bottom: none;
  }
`;

const Rotate = styled.div`
  transform: rotate(-180deg);
`;

export default ProfileActorSidebar;
