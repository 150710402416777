import { useEffect } from "react";

import FaceSwap, { FaceswapProjectTab } from ".";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { ThemeTypes } from "../../types/theme";
import { useDarkMode } from "../../hooks/useDarkMode";

interface DarkModeTypes {
  theme: ThemeTypes;
  toggleTheme: () => void;
}

interface Props {
  activeTab: FaceswapProjectTab;
}

const FaceSwapPage = ({ activeTab }: Props) => {
  const { theme, toggleTheme }: DarkModeTypes = useDarkMode();

  useEffect(() => {
    if (theme === ThemeTypes.light) {
      toggleTheme();
    }
  }, []);

  return (
    <ErrorBoundary>
      <FaceSwap activeTab={activeTab} />
    </ErrorBoundary>
  );
};

export default FaceSwapPage;
