import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearUserHistoryProjects,
  getUserHistoryProjectsServer,
  setUserHistoryProjectsPage,
} from "../redux/actions/profileActions";
import {
  getCurrentPageUserHistoryProjects,
  getUserHistoryProjects,
  getUserHistoryProjectsLoading,
  getUserHistoryProjectsTotalPages,
  getUserHistoryProjectsTotalRecords,
} from "../redux/reducers/profileReducer";

const DEFAULT_LENGTH_PER_PAGE = 20;

interface UpdateData {
  pageNumber?: number;
  pageSize?: number;
  category?: string;
}

export const useHistory = () => {
  const dispatch = useDispatch();

  const data = useSelector(getUserHistoryProjects);
  const isLoading = useSelector(getUserHistoryProjectsLoading);
  const totalPages = useSelector(getUserHistoryProjectsTotalPages);
  const totalRecords = useSelector(getUserHistoryProjectsTotalRecords);
  const currentPage = useSelector(getCurrentPageUserHistoryProjects);

  const [option, setOption] = useState<string>("");
  const [selectedHistoryLenghtPerPage, setSelectedHistoryLenghtPerPage] = useState(DEFAULT_LENGTH_PER_PAGE);

  const handleOptionChanges = (newValue: string) => setOption(newValue);

  const handleUpdateData = ({ pageNumber, pageSize, category }: UpdateData) => {
    dispatch(clearUserHistoryProjects());

    if (pageNumber) {
      dispatch(setUserHistoryProjectsPage({ pageNumber }));
    }

    dispatch(
      getUserHistoryProjectsServer({ pageNumber: pageNumber || 1, pageSize: pageSize || 20, category: category || "" }),
    );
  };

  const handlePaginate = (pageNumber: number) => {
    handleUpdateData({ pageNumber, pageSize: selectedHistoryLenghtPerPage, category: option });
  };

  const handleSelectHistoryLengthPerPage = (lengthPerPage: number) => {
    setSelectedHistoryLenghtPerPage(lengthPerPage);
    handleUpdateData({ pageNumber: 1, pageSize: lengthPerPage, category: option });
  };

  useEffect(() => {
    handleUpdateData({ pageSize: selectedHistoryLenghtPerPage, category: option });
  }, [option]);

  const menuOptions = ["Download", "Rename", "Delete"];

  return {
    data: data || [],
    option,
    isLoading,
    totalPages,
    currentPage,
    menuOptions,
    totalRecords,
    selectedHistoryLenghtPerPage,
    handlePaginate,
    handleOptionChanges,
    handleSelectHistoryLengthPerPage,
  };
};
