import styled from "styled-components";
import { AIImagesGroupedRecords } from "../../types/image";
import DividerWithText from "./DividerWithText";
import GalleryCard from "./GalleryImageCard";

interface Props {
  data: AIImagesGroupedRecords;
}

const AIImagesGroupedCard = ({ data }: Props) => {
  const { title, records, insertDateTime } = data;

  return (
    <GroupWrapper>
      <DividerWithText insertDateTime={insertDateTime} />
      <Title>{title}</Title>
      <Gallery>
        {records.map((record) => (
          <GalleryCard key={record.visualHistoryID} image={record} title={title} />
        ))}
      </Gallery>
    </GroupWrapper>
  );
};

const GroupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  align-self: center;
  margin: 12px 0 20px;
  color: #9e9eac;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

const Gallery = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
  flex-flow: wrap;

  & > div {
    max-width: 230px;
    max-height: 600px;
    width: auto;
    height: auto;

    & div {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;

      button {
        position: absolute;
        border: none;
        box-shadow: none;
        border-radius: 6px;
        background: #0286dc;

        svg {
          width: 16px;
          height: 16px;
          & path {
            fill: #ffffff;
          }
        }

        :focus {
          border: none;
        }
      }
    }
  }
`;

export default AIImagesGroupedCard;
