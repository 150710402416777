import { AIHumansStatus } from "../modules/MyStudio";
import { CharacterPrefilled } from "./actor";
import { HumatarPrefilled } from "./humatar";
import { UploadImagePrefilled } from "./image";
import { UserHistoryProjectPrefilled } from "./userHistoryProjects";

export enum DeleteType {
  Project,
  Asset,
  GalleryImage,
  UploadImage,
  CloneVoice,
  CustomHumatar,
  GalleryVideo,
  AIImages,
  HistoryProject,
}

export type ConfirmationDelete = {
  id: number;
  title: string;
  description: string;
  typeId: number;
  type: DeleteType;
  status?: AIHumansStatus | null;
  redirectTo?: boolean;
  previousFill?: UploadImagePrefilled | CharacterPrefilled | HumatarPrefilled | UserHistoryProjectPrefilled;
  pageSize?: number;
  sortByDesc?: boolean;
  category?: string;
  option?: string;
};
