import styled from "styled-components";
import { formatDistance } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Modal from "../Modal/Modal";
import Button, { ButtonThemes } from "../Button/Button";
import CircularProgress from "../Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import { pages } from "../../lib/routeUtils";
import { EditIcon } from "../Icons/EditIcon";
import { TrashIcon } from "../Icons/TrashIcon";
import {
  clearCurrentHumansProject,
  getProjectServer,
  renameHumansProjectServer,
  updateHumansProjectLoading,
} from "../../redux/actions/humansProjectActions";
import { DownloadIcon } from "../Icons/DownloadIcon";
import { downloadFile } from "../../lib/downloadFile";
import { AiHumansProjectModules } from "../../types/humansProject";
import { FacebookIcon, LinkedInIcon, MailIcon } from "../Icons/Icons";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { UserHistoryProjectPrefilled } from "../../types/userHistoryProjects";
import { ExportIcon, InstagramIcon, TwitterIcon } from "../Icons/HistoryIcons";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import { updateHistoryProjectServer } from "../../redux/actions/profileActions";
import { getHistoryPopupIsOpen, getPrefilled } from "../../redux/reducers/popupsReducer";
import { getHumansProject, getHumansProjectLoading } from "../../redux/reducers/humansProjectReducer";
import Tooltip from "../Tooltip/Tooltip";
import CloseIconV4 from "../Icons/CloseIconV4";

const HistoryPopup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const searchParams = new URLSearchParams(location.search);

  const project = useSelector(getHumansProject);
  const isLoading = useSelector(getHumansProjectLoading);
  const isHistoryPopupVisible = useSelector(getHistoryPopupIsOpen);
  const { type, typeId, pageSize, category, option, sortByDesc, status }: UserHistoryProjectPrefilled =
    useSelector(getPrefilled);

  const [isCopied, setIsCopied] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [projectName, setProjectName] = useState<string>(project?.title || "");
  const [projectId, setProjectId] = useState<string>(project?.projectId?.toString() || "");

  const removeProjectIdUrl = () => {
    searchParams.delete("projectId");
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const handleClose = () => {
    removeProjectIdUrl();
    dispatch(clearCurrentHumansProject());
    dispatch(updatePopup({ popup: Popups.historyPopup, status: false }));
  };

  const handleDuplicate = () => {
    navigate(pages.aiHumans() + `?template=${projectId}`);
    dispatch(clearCurrentHumansProject());
    dispatch(updatePopup({ popup: Popups.historyPopup, status: false }));
  };

  const handleCopyLink = () => {
    setIsCopied(true);

    const link = `https://app.synthesys.live/ai-humans?projectId=${projectId}`;
    setTimeout(() => {
      navigator.clipboard.writeText(link);
      setIsCopied(false);
    }, 1000);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (!projectId) throw Error("Project entity is missing while deleting");

    const previousFill = {
      type,
      typeId,
      pageSize,
      category,
      option,
      ...(type === DeleteType.HistoryProject
        ? {}
        : { projectId: Number(projectId), projectTypeId: typeId, status, sortByDesc }),
    };

    const prefilled: ConfirmationDelete = {
      id: Number(projectId),
      title: "Are you sure you want to delete project?",
      description: "The project will be removed and the action cannot be undone",
      typeId,
      type,
      pageSize,
      category,
      option,
      status,
      sortByDesc,
      previousFill,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleRename = (e: React.KeyboardEvent | React.FocusEvent, blur = false) => {
    if ((e as React.KeyboardEvent).code === "Enter" || blur) {
      if (!project) throw Error("Project entity is missing while renaming");
      if (project && projectName !== project?.title) {
        if (type === DeleteType.Project) {
          dispatch(
            renameHumansProjectServer({
              projectId: Number(projectId),
              projectTypeId: 2,
              newTitle: projectName,
              oldTitle: project?.title,
            }),
          );
        } else {
          dispatch(
            updateHistoryProjectServer({
              id: Number(projectId),
              category: category as string,
              newTitle: projectName,
              oldTitle: project?.title,
              isHumanProject: true,
            }),
          );
        }
      }
      setIsEdit(false);
    }
  };

  useEffect(() => {
    if (project?.title) setProjectName(project?.title);
  }, [project?.title]);

  useEffect(() => {
    if (type === DeleteType.Project) {
      const id = searchParams.get("projectId");
      dispatch(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: true }));

      if (id) {
        setProjectId(id);
        dispatch(getProjectServer(Number(id)));
      }
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current && !project && !isLoading) {
      handleClose();
    }

    isFirstRender.current = false;
  }, [project, isLoading]);

  if (!project && !isLoading) return null;

  return (
    <StyledModal
      className="history"
      childrenClassName="childrenHistory"
      open={isHistoryPopupVisible}
      onClose={handleClose}
      closeIcon={true}
      closeIconElement={<CloseIconV4 />}
      maxWidth={1024}
      maxHeight={512}
    >
      <Content isLoading={isLoading}>
        {isLoading ? (
          <CircularProgress color="#009af7" />
        ) : (
          <>
            <MediaSource>
              <VideoWrapper>
                <video
                  src={project?.output || project?.coverImage}
                  poster={project?.coverImage}
                  width={"100%"}
                  height="100%"
                  controls
                />
              </VideoWrapper>
            </MediaSource>
            <HeaderWrapper>
              <ProjectInfo>
                <Title>
                  {isEdit ? (
                    <Textfield
                      autoFocus
                      variant={TextfieldVariant.project}
                      value={projectName}
                      placeholder="Enter project name"
                      onChange={(e) => setProjectName(e.target.value)}
                      onKeyPress={(e) => handleRename(e)}
                      onBlur={(e) => handleRename(e, true)}
                    />
                  ) : (
                    <>
                      <p>{projectName}</p>
                      <IconButton
                        icon={<EditIcon />}
                        iconButtonTheme={IconButtonThemes.Transparent}
                        onClick={() => setIsEdit(true)}
                      />
                    </>
                  )}
                </Title>
                <TimeAgo>Created {formatDistance(new Date(project?.insertDateTime + "Z"), new Date())} ago</TimeAgo>
              </ProjectInfo>
              {/* <SocialButtons>
                {socialButtons.map(({ icon }, index) => (
                  <IconButton
                    key={index}
                    icon={icon}
                    iconButtonTheme={IconButtonThemes.Rounded}
                    onClick={() => {
                      return;
                    }}
                  />
                ))}
            </SocialButtons> */}
              <MobileOnly>
                <ButtonsWrapper>
                  <MobileWhiteButton>
                    <IconButton
                      onClick={handleDuplicate}
                      iconButtonTheme={IconButtonThemes.Rounded}
                      icon={<ExportIcon />}
                    />
                    <Tooltip text="Duplicate video" arrow />
                  </MobileWhiteButton>
                  <MobileWhiteButton>
                    <IconButton
                      onClick={handleDuplicate}
                      className="copy-button"
                      iconButtonTheme={IconButtonThemes.Rounded}
                      icon={<ExportIcon />}
                    />
                    <Tooltip text={isCopied ? "Link copied" : "Copy link"} arrow />
                  </MobileWhiteButton>
                  <MobileBlueButton>
                    <IconButton
                      onClick={handleDuplicate}
                      iconButtonTheme={IconButtonThemes.Rounded}
                      icon={<DownloadIcon />}
                    />
                    <Tooltip text="Download video" arrow />
                  </MobileBlueButton>
                  <MobileRedButton>
                    <IconButton
                      onClick={handleDuplicate}
                      iconButtonTheme={IconButtonThemes.Rounded}
                      icon={<TrashIcon />}
                    />
                    <Tooltip text="Delete" arrow />
                  </MobileRedButton>
                </ButtonsWrapper>
              </MobileOnly>
              <DesktopOnly>
                <ButtonsWrapper>
                  <ActionButtons>
                    <WhiteButton
                      text="Duplicate video"
                      icon={<ExportIcon />}
                      buttonTheme={ButtonThemes.Outline}
                      onClick={handleDuplicate}
                      style={{ zIndex: "1" }}
                    />
                    <WhiteButton
                      className="copy-button"
                      text={isCopied ? "Copied" : "Copy link"}
                      icon={<ExportIcon />}
                      buttonTheme={ButtonThemes.Outline}
                      onClick={handleCopyLink}
                      style={{ zIndex: "1" }}
                    />
                    <BlueButton
                      text="Download video"
                      icon={<DownloadIcon />}
                      buttonTheme={ButtonThemes.Outline}
                      onClick={(e) => downloadFile(e, project?.output || "")}
                      style={{ zIndex: "1" }}
                    />
                  </ActionButtons>
                  <RedButton
                    text="Delete"
                    icon={<TrashIcon />}
                    buttonTheme={ButtonThemes.Outline}
                    onClick={handleDelete}
                    style={{ zIndex: "1" }}
                  />
                </ButtonsWrapper>
              </DesktopOnly>
            </HeaderWrapper>
          </>
        )}
      </Content>
    </StyledModal>
  );
};

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;

    & > button {
      display: flex;
      order: 2;
      width: 150px;
      position: initial;
    }
  }
`;

const DesktopOnly = styled.div`
  display: contents;

  @media (max-width: 800px) {
    display: none;
  }
`;

const socialButtons = [
  { icon: <TwitterIcon /> },
  { icon: <FacebookIcon /> },
  { icon: <InstagramIcon /> },
  { icon: <MailIcon /> },
  { icon: <LinkedInIcon /> },
];

const StyledModal = styled(Modal)`
  &.history {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 42px 53px 59px 49px;
    border-radius: 20px;

    @media (max-width: 1060px) {
      padding: 24px;
    }
  }

  & div.modal-close-icon {
    width: 21px;
    height: 14px;
    top: 14px;
    right: 14px;

    svg {
      width: 21px;
      height: 14px;
    }

    @media (max-width: 1060px) {
      top: 8px;
      right: 8px;
    }
  }

  & .childrenHistory {
    height: 100%;
  }

  @media (max-width: 1060px) {
    max-width: 95%;
  }

  @media (max-height: 563px) {
    max-height: 95%;
  }
`;

const Content = styled.div<{ isLoading?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;

  ${({ isLoading }) =>
    isLoading &&
    `
    align-items: center;
    justify-content: center;
  `}

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 100%;
  height: 100%;

  @media (max-width: 800px) {
    flex-direction: row;
    max-width: unset;
    height: unset;
    gap: 8px;
  }
`;

const MediaSource = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    max-height: 90%;
    justify-content: center;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: fit-content;
  max-width: fit-content;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: fit-content;
  align-self: center;
  border-radius: 20px;
  overflow: hidden;

  & video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: ${({ theme }) => theme.tableBackground};
  }

  @media (max-width: 800px) {
    height: 100%;
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 355px;
  width: 100%;

  @media (max-width: 800px) {
    max-width: unset;
  }
`;

const Title = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primaryText};
  padding-bottom: 10px;

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div {
    width: 100%;

    input {
      max-width: 100%;
    }
  }

  @media (max-width: 800px) {
    padding-bottom: 0px;
    p {
      font-size: 16px;
      -webkit-line-clamp: 1;
    }
  }
`;

const TimeAgo = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.lightGreyText};
  padding-bottom: 22px;

  @media (max-width: 800px) {
    padding-bottom: 0px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-bottom: 33px;

  button {
    background: #ffffff;
    max-width: unset;
    max-height: unset;
    width: 40px;
    height: 40px;
    flex-shrink: 0.6;
    box-shadow: 0px 4px 4px 0px #00000040;

    & > svg path {
      fill: #009af7;

      &.linkedin {
        fill: #ffffff;
      }
    }
  }
`;

const ButtonsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;

  button {
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 0px;
  }

  @media (max-width: 800px) {
    flex-direction: unset;
    gap: 6px;

    button {
      width: 32px;
      height: 32px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    & > div {
      position: relative;
      width: 32px;
      height: 32px;

      &:hover > div {
        top: -42px;
        right: 5px;
        opacity: 1;
        visibility: visible;
        display: flex;
        align-items: center;
        border-radius: 7px;
        min-width: max-content;
        box-shadow: none;
        background: #292937;
        padding: 7px;

        span {
          color: #fff !important;
        }

        &:after {
          border-color: #292937 transparent transparent transparent;
          right: 5px;
          bottom: -13px;
        }
      }
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .copy-button {
    box-shadow: none;

    span {
      color: #aeaec0;
    }

    svg > path {
      stroke: #aeaec0;
    }
  }

  @media (max-width: 800px) {
    gap: 8px;
    button {
      height: 36px;
    }
  }
`;

const MobileWhiteButton = styled.div`
  & button {
    background: #ffffff;
  }

  svg path {
    stroke: #0063b4;
  }

  .copy-button {
    box-shadow: none;

    span {
      color: #aeaec0;
    }

    svg > path {
      stroke: #aeaec0;
    }
  }
`;

const MobileBlueButton = styled.div`
  & button {
    background: #0286dc;
  }

  svg path {
    fill: #fff;
  }
`;

const MobileRedButton = styled.div`
  & button {
    background: #ff5050;
  }

  svg path {
    fill: #fff;
  }
`;

const BlueButton = styled(Button)`
  justify-content: flex-start;
  height: 48px;
  padding: 14px 24px;
  gap: 8px;
  background: #0286dc;

  span {
    color: #fff;
  }

  svg path {
    fill: #fff;
  }

  &:hover {
    border: 0px;
  }

  &:focus {
    border: 0px;
  }
`;

const RedButton = styled(Button)`
  justify-content: flex-start;
  height: 48px;
  padding: 14px 24px;
  gap: 8px;
  background: #ff5050;

  span {
    color: #fff;
  }

  svg path {
    fill: #fff;
  }

  &:hover {
    border: 0px;
  }

  &:focus {
    border: 0px;
  }

  @media (max-width: 800px) {
    height: 36px;
  }
`;

const WhiteButton = styled(Button)`
  justify-content: flex-start;
  height: 48px;
  padding: 14px 24px;
  gap: 8px;
  background: #fff;

  span {
    color: #0063b4;
  }

  svg path {
    stroke: #0063b4;
  }

  &:hover {
    border: 0px;
  }

  &:focus {
    border: 0px;
  }
`;

export default HistoryPopup;
