export const HistoryIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="18.5" stroke="#3878D7" strokeWidth="3" />
    <mask
      id="mask0_1764_6691"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="5"
      width="32"
      height="31"
    >
      <path d="M6.66602 19V20.6667" stroke="black" strokeWidth="4" strokeLinecap="round" />
      <path d="M13.333 10.3359L13.333 29.6693" stroke="black" strokeWidth="4" strokeLinecap="round" />
      <path d="M19.9995 7.33594V33.3359" stroke="black" strokeWidth="4" strokeLinecap="round" />
      <path d="M26.666 10.3359V29.6693" stroke="black" strokeWidth="4" strokeLinecap="round" />
      <path d="M33.333 18.6719V20.6719" stroke="black" strokeWidth="4" strokeLinecap="round" />
    </mask>
    <g mask="url(#mask0_1764_6691)">
      <rect x="4.66699" y="6" width="31.3333" height="28.6667" fill="#317FF4" />
    </g>
  </svg>
);
