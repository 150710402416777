import { IActor } from "./actor";
import { Slide } from "./humansProject";

export interface Feature {
  key: string;
  value: string;
}

export interface Zone {
  text: string;
  features: Feature[];
  actorId?: number;
  isLoading?: boolean;
  outputUrl?: string;
  audioPath?: string;
  paragraphIndex?: number;
}

export interface Paragraphs {
  actorId: number;
  order: number;
  data: Zone[];
  insertDateTime?: string;
  jsonText?: string | null;
  outputAudio?: string;
  projectId?: number;
  projectParagraphId?: number;
  updateDateTime?: string;
  userId?: number;
  actor?: IActor;
  actorsList?: IActor[];
}

export interface Project {
  projectId?: number;
  projectTypeId: number;
  useId?: number;
  title: string;
  coverImage?: string;
  insertDateTime?: string;
  updateDateTime?: string;
  subTitle?: string;
  paragraphs: Paragraphs[];

  status?: number;
  output?: string;
  projectCategoryId?: number;
  subTitleLanguageId?: number;
  resolution?: string;
  isDraft?: boolean;
  isTemplate?: boolean;
  isHorizontal?: boolean;
  isSimple?: boolean;
  slides?: Slide[];
  estimatedTime?: number;
  backgroundSoundTrackId?: number | null;
  totalDuration?: number;
}

export interface ProjectList {
  pageNumber: number;
  pageSize: number;
  firstPage: string;
  lastPage: string;
  totalPages: number;
  totalRecords: number;
  nextPage?: any;
  previousPage?: any;
  data: Project[];
}

export interface AudioTranslateProject {
  audioTranslateProjectId?: number;
  userId?: number;
  title?: string;
  audioSrc?: string;
  targetLanguage?: string;
  insertDateTime?: string;
  updateDateTime?: string;
  isDeleted?: boolean;
  estimatedTime?: number;
  status?: number;
  totalDuration?: number;
  outputUrl?: string;
  totalSecondsTaken?: any;
  completeDateTime?: any;
  estimatedCompleteDateTime?: string;
  progress?: string;
  actorID: number;
  guid?: string;
  translatedText?: string;
  conversionType: number;
  file?: any;
  actor?: IActor;
}

export enum ProjectModules {
  "projectList" = "projectList",
  "project" = "project",
  "autoSave" = "autoSave",
}

export enum LoadingStyles {
  "loading" = "isLoading_loading",
  "generated" = "isGenerated_generated",
}
