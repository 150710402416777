import Select from "react-select";
import styled, { useTheme } from "styled-components";
import { FC, ReactNode, useState } from "react";

import { Option as OptionType } from "..";
import { ArrowsDownUp } from "../../../components/Icons/Icons";

interface Props {
  optionsList: OptionType[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  actionIcon?: ReactNode;
}

const HistoryTableHeadSelect: FC<Props> = ({ optionsList, onChange, value, disabled, placeholder }) => {
  const theme: any = useTheme();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const customStyles = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 6,
    }),
    control: (provided: any) => ({
      ...provided,
      boxShadow: "none",
      border: "1px solid transparent",
      backgroundColor: "transparent",
      height: "34px",
      minWidth: "200px",
      maxWidth: "200px",
      cursor: "pointer",

      "&:hover": {
        border: "1px solid transparent",
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,

      div: {
        padding: 0,
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: "12px",
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: "5px",
      maxHeight: "200px",
      border: `1px solid ${theme.tableDivider}`,
      backgroundColor: theme.primaryBackground,
      boxShadow: "none",
      paddingBottom: 0,
      paddingTop: 0,
      overflowX: "hidden",

      "@media (max-width: 1001px)": {
        "::-webkit-scrollbar": {
          display: "none",
        },
      },

      "::-webkit-scrollbar": {
        width: "2px",
      },

      "::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: theme.secondaryBackground,
      },

      "::-webkit-scrollbar-track": {
        margin: "15px 0",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      margin: 0,

      "& span": {
        color: theme.primaryText,
      },
    }),
    option: (provided: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      backgroundColor: "transparent",
      padding: "8px",

      "&:active": {
        backgroundColor: "transparent",
        color: "#ffffff",
      },
    }),
  };

  const formatOptionLabel = (option: OptionType, { context }: { context: string }) => (
    <SelectorOptionItem active={context === "menu" && option.value === value}>
      <Row>
        <span>{option.label !== "Type" && context !== "menu" ? `Type (${option.label})` : option.label}</span>
        {context !== "menu" && <ArrowsDownUp />}
      </Row>
    </SelectorOptionItem>
  );

  const handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  return (
    <Select
      value={optionsList.find((option) => option.value === value)}
      onChange={handleChange}
      options={optionsList}
      isDisabled={disabled}
      styles={customStyles}
      isSearchable={false}
      placeholder={placeholder}
      menuPlacement="auto"
      menuShouldBlockScroll={true}
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
};

const SelectorOptionItem = styled.span<{ active?: boolean }>`
  position: relative;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText}b3;
  cursor: pointer;
  display: block !important;
  border: none !important;
  margin: 0 !important;

  ${({ active, theme }) =>
    active &&
    `
       border-radius: 5px;
       color: ${theme.primaryText};
       font-weight: 600;
  `}
`;

const Row = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 195px;
  }
`;

export default HistoryTableHeadSelect;
