import React, { ReactNode } from "react";
import styled from "styled-components";

type TooltipPosition = "top" | "top-right" | "top-left" | "left" | "bottom" | "right";

interface Props {
  text?: ReactNode;
  reverseColor?: boolean;
  style?: Record<string, string>;
  position?: TooltipPosition;
  arrow?: boolean;
  className?: string;
}

const Tooltip = ({ text, reverseColor, style, position, arrow, className }: Props) => {
  return (
    <Wrapper reverseColor={reverseColor} style={style} position={position} arrow={arrow} className={className}>
      <span>{text}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ reverseColor?: boolean; position?: TooltipPosition; arrow?: boolean }>`
  padding: 12px;
  background-color: ${({ theme, reverseColor }) =>
    reverseColor ? theme.secondaryBackground : theme.primaryBackground};
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.buttonShadow};
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  visibility: hidden;
  z-index: 100;
  width: auto;

  &::after {
    content: "";
    position: absolute;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    opacity: ${({ arrow }) => (arrow ? 1 : 0)};
  }

  span {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px !important;
    font-family: "Mulish", sans-serif;
    // text-transform: capitalize;
    color: ${({ theme, reverseColor }) => (reverseColor ? theme.secondaryText : theme.primaryText)} !important;
  }

  ${({ position, theme, reverseColor }) =>
    position === "top" &&
    `
        bottom: 120%;
        left: 50%;
        transform: translateX(-50%);

        &::after {
          top: 95%;
          left: 50%;
          border-color: ${
            reverseColor ? theme.secondaryBackground : theme.primaryBackground
          } transparent transparent transparent;
        }

  `}
  ${({ position, theme, reverseColor }) =>
    position === "bottom" &&
    `
        top: 120%;
        left: 50%;
        transform: translateX(-50%);

        &::after {
          left: 50%;
          bottom: 95%;
          border-color: transparent transparent ${
            reverseColor ? theme.secondaryBackground : theme.primaryBackground
          } transparent;
        }
  `}
  ${({ position, theme, reverseColor }) =>
    position === "left" &&
    `
        top: 50%;
        right: 120%;
        transform: translateY(-50%);

        &::after {
          top: 50%;
          margin-left: 0;
          transform: translateY(-50%);
          left: 100%;
          border-color: transparent transparent transparent ${
            reverseColor ? theme.secondaryBackground : theme.primaryBackground
          };
        }
  `}
  ${({ position, theme, reverseColor }) =>
    position === "right" &&
    `
        top: 50%;
        left: 120%;
        transform: translateY(-50%);

        &::after {
          top: 50%;
          margin-left: 0;
          transform: translateY(-50%);
          right: 100%;
          border-color: transparent ${
            reverseColor ? theme.secondaryBackground : theme.primaryBackground
          } transparent transparent;
        }
  `}
  ${({ position, theme, reverseColor }) =>
    position === "top-left" &&
    `
      bottom: 120%;
      left: -50%;

      &::after {
        top: 95%;
        left: 70%;
        border-color: ${
          reverseColor ? theme.secondaryBackground : theme.primaryBackground
        } transparent transparent transparent;
      }
  `}
  ${({ position, theme, reverseColor }) =>
    position === "top-right" &&
    `
      bottom: 120%;
      left: 50%;

      &::after {
        top: 95%;
        left: 30%;
        border-color: ${
          reverseColor ? theme.secondaryBackground : theme.primaryBackground
        } transparent transparent transparent;
      }
  `}
`;

export default Tooltip;
