export const aiVoicesLightTheme = {
  primaryBackgroundAIVoices: "#191b1f",
  secondaryBackgroundAIVoices: "#ffffff",
  primaryTextAIVoices: "#191b1f",
  secondaryTextAIVoices: "#6A6A7C",
  actorCardAIVoices: "#ffffff",
  actorCardShadowAIVoices: "5px 5px 10px 0px #AEAEC04D",
  actorCardActiveShadowAIVoices: "5px 5px 10px 0px #AEAEC04D",
  actorSidebarAIVoices: "#F4F4F4",
  sidebarGradientAIVoices: "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F4F4F4 100%)",
  playerBackgroundAIVoices: "#ffffff",
  playerProgressBarShadowAIVoices: "-5px -5px 4px 0px #FFFFFF4D inset, 5px 5px 4px 0px #AEAEC033 inset",
  primaryShadowAIVoices:
    "-5px -5px 10px #FFFFFF, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF",
  pageWrapperAIVoices: "#F0F0F3",
  fileItemAIVoices: "#DEDEE3",
};

export const aiVoicesDarkTheme = {
  primaryBackgroundAIVoices: "#191b1f",
  secondaryBackgroundAIVoices: "#191b1f",
  primaryTextAIVoices: "#ffffff",
  secondaryTextAIVoices: "#D5D5D7",
  actorCardAIVoices: "#2A2A35",
  actorCardShadowAIVoices: "none",
  actorCardActiveShadowAIVoices: "5px 5px 10px 0px #AEAEC04D",
  actorSidebarAIVoices: "#191B1F",
  sidebarGradientAIVoices: "none",
  playerBackgroundAIVoices: "none",
  playerProgressBarShadowAIVoices: "none",
  primaryShadowAIVoices: "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
  pageWrapperAIVoices: "#272A31",
  fileItemAIVoices: "#9E9EAE42",
};
