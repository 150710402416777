import styled from "styled-components";
import { useEffect, useState } from "react";
import { TableBody, TableContainer, TableHead, Table as MuiTable, TableCell, TableRow, Skeleton } from "@mui/material";

import Pagination from "./Pagination";
import HistoryTableRow from "./HistoryTableRow";
import Select from "../../../components/Select/Select";
import HistoryTableHeadCell from "./HistoryTableHeadCell";
import HistoryTableRowSkeleton from "./HistoryTableRowSkeleton";
import { Column } from "..";
import { ArrowsDown, ArrowsDownUp, ArrowsUp, DropDawnPlanIcon } from "../../../components/Icons/Icons";
import { UserHistoryProject } from "../../../types/userHistoryProjects";
import { useSelector } from "react-redux";
import { getHistoryPopupIsOpen } from "../../../redux/reducers/popupsReducer";

const DEFAULT_MIN_VALUE = 0;

interface Props {
  data: UserHistoryProject[];
  option: string;
  columns: Column[];
  isLoading: boolean;
  totalPages: number;
  totalRecords: number;
  currentPage: number;
  menuOptions: string[];
  selectedHistoryLenghtPerPage: number;
  paginate: (pageNumber: number) => void;
  handleOptionChanges: (newOption: string) => void;
  handleSelectHistoryLengthPerPage: (lengthPerPage: number) => void;
}

const HistoryTable = ({
  data,
  option,
  columns,
  isLoading,
  totalPages,
  totalRecords,
  currentPage,
  menuOptions,
  selectedHistoryLenghtPerPage,
  paginate,
  handleOptionChanges,
  handleSelectHistoryLengthPerPage,
}: Props) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const [lastHistoryItemIndexForShow, setLastHistoryItemIndexForShow] = useState(0);
  const [firstHistoryItemIndex, setFirstHistoryItemIndex] = useState(0);
  const [currentPageData, setCurrentPageData] = useState<UserHistoryProject[]>(data);
  const [activeProject, setActiveProject] = useState<number | null>(null);
  const [menuOpen, setMenuOpen] = useState<number | null>(null);

  const isHistoryPopupVisible = useSelector(getHistoryPopupIsOpen);

  const handleActiveProject = (e: any, id: number) => {
    e.preventDefault();
    setActiveProject(id);
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const updatePaginateData = () => {
    const updatedLastHistoryItemIndex = currentPage * selectedHistoryLenghtPerPage;
    const updatedLastHistoryItemIndexForShow =
      updatedLastHistoryItemIndex < totalRecords ? updatedLastHistoryItemIndex : totalRecords;
    setLastHistoryItemIndexForShow(updatedLastHistoryItemIndexForShow);

    const updatedFirstHistoryItemIndex = updatedLastHistoryItemIndex - selectedHistoryLenghtPerPage;
    setFirstHistoryItemIndex(updatedFirstHistoryItemIndex);

    const sortedData = data.slice().sort((a, b) => {
      let dateA = new Date(a.insertDateTime + "Z").getTime();
      let dateB = new Date(b.insertDateTime + "Z").getTime();
      if (sortColumn === "Name") {
        return sortOrder === "asc"
          ? a.title.localeCompare(b.title, undefined, { numeric: true })
          : b.title.localeCompare(a.title, undefined, { numeric: true });
      } else if (sortColumn === "Created") {
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (sortColumn === "Favourite") {
        return sortOrder === "asc"
          ? compareFavorites(a.favorite, b.favorite)
          : compareFavorites(b.favorite, a.favorite);
      }
      return 0;
    });

    setCurrentPageData(sortedData);
  };

  const compareFavorites = (favA: boolean | null, favB: boolean | null) => {
    const valueA = favA === true ? 1 : 2;
    const valueB = favB === true ? 1 : 2;

    return valueA - valueB;
  };

  useEffect(() => {
    updatePaginateData();
  }, [selectedHistoryLenghtPerPage, currentPage, sortColumn, sortOrder, data]);

  return (
    <Card isTableHidden={isHistoryPopupVisible}>
      <TableContainer style={{ padding: "0 24px", flex: 1, maxHeight: "898px" }}>
        <MuiTable stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column: Column) => (
                <HistoryTableHeadCell
                  key={column.id}
                  {...column}
                  icon={
                    column.icon &&
                    ((sortColumn as string) === column.header ? (
                      sortOrder === "asc" ? (
                        <ArrowsUp />
                      ) : (
                        <ArrowsDown />
                      )
                    ) : (
                      <ArrowsDownUp />
                    ))
                  }
                  option={option}
                  handleOptionChanges={handleOptionChanges}
                  onClick={() => column.icon && handleSort(column.header)}
                />
              ))}
            </TableRow>
          </TableHead>
          <StyledTableBody>
            {isLoading
              ? Array.from({ length: selectedHistoryLenghtPerPage }).map((_, index) => (
                  <HistoryTableRowSkeleton key={index} skeletonCount={columns.length} />
                ))
              : currentPageData &&
                currentPageData.map((row) => (
                  <HistoryTableRow
                    key={row.id}
                    {...row}
                    menuOpen={menuOpen}
                    menuOptions={menuOptions}
                    pageSize={selectedHistoryLenghtPerPage}
                    active={row.id === activeProject}
                    setActiveProject={setActiveProject}
                    setMenuOpen={setMenuOpen}
                    option={option}
                    handleActiveProject={(e: any) => handleActiveProject(e, row.id)}
                  />
                ))}
          </StyledTableBody>
        </MuiTable>
      </TableContainer>
      <BottomWrapper>
        <span>
          {firstHistoryItemIndex < DEFAULT_MIN_VALUE ? DEFAULT_MIN_VALUE : firstHistoryItemIndex + 1}-
          {lastHistoryItemIndexForShow} of {totalRecords} items
        </span>
        <Pagination
          currentPage={currentPage}
          historyLenghtPerPage={selectedHistoryLenghtPerPage}
          historyLenth={totalPages}
          paginate={paginate}
          disabled={isLoading}
        />
        <ShowWrapper>
          <span>Show</span>
          <SelectWrapper>
            <Select
              value={selectedHistoryLenghtPerPage}
              onChange={handleSelectHistoryLengthPerPage}
              optionsList={options}
              disabled={isLoading}
              defaultValueText=""
              actionIcon={<DropDawnPlanIcon />}
            />
          </SelectWrapper>
        </ShowWrapper>
      </BottomWrapper>
    </Card>
  );
};

const options = {
  data: [
    {
      id: "1",
      label: "10",
      value: 10,
    },
    {
      id: "2",
      label: "20",
      value: 20,
    },
    {
      id: "3",
      label: "50",
      value: 50,
    },
    {
      id: "4",
      label: "100",
      value: 100,
    },
  ],
};

const Card = styled.div<{ isTableHidden?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.tableBackground};
  border-radius: 16px;
  max-height: 978px;

  ${({ isTableHidden }) =>
    isTableHidden &&
    `
      .MuiTableContainer-root {
      opacity: 0;
      }
    `}

  & > div {
    scrollbar-color: ${({ theme }) => theme.tableDivider} ${({ theme }) => theme.tableBackground};
  }
`;

const StyledTableBody = styled(TableBody)`
  & tr:last-of-type > td:last-of-type {
    .menu-wrapper {
      top: -60px !important;
    }
  }
`;

const BottomWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-flow: row wrap;
  padding: 0 24px 12px;
  margin: 20px 0px 0px 0px;

  & > span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.tableTitleText};
    order: 1;
  }

  @media (max-width: 800px) {
    & > span {
      order: 1;
      width: calc(50% - 8px);
    }
  }
`;

const ShowWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  order: 3;

  span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme.tableTitleText};
  }

  @media (max-width: 800px) {
    order: 2;
    width: calc(50% - 8px);
    justify-content: end;
  }
`;

const SelectWrapper = styled("div")`
  div > div:nth-child(2) {
    width: 68px;
    height: 32px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.primaryBackground};
    border: 1px solid ${({ theme }) => theme.tableDivider};
    box-shadow: none;
    padding: 0 8px;
  }

  div > div:nth-child(3) {
    bottom: 42px;
    background-color: ${({ theme }) => theme.primaryBackground};
    border: 1px solid ${({ theme }) => theme.tableDivider};
  }

  div > div:nth-child(3) {
    span {
      border: none;
    }
  }

  div svg {
    top: 16px;
    right: 10px;
    margin-left: -3px;
    path {
      opacity: 1;
      fill: none;
      stroke: ${({ theme }) => theme.tableText};
    }
  }
  p {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export default HistoryTable;
