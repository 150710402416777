import { IImage, ImagesModules } from "../../types/image";

export const GENERATE_IMAGE_SERVER = "GENERATE_IMAGE_SERVER";
export const UPDATE_IMAGES_LOADING = "UPDATE_IMAGES_LOADING";
export const UPDATE_GENERATED_IMAGES = "UPDATE_GENERATED_IMAGES";
export const GET_ALL_HISTORY_IMAGES_SERVER = "GET_ALL_HISTORY_IMAGES_SERVER";
export const UPDATE_HAS_MORE_HISTORY_IMAGES = "UPDATE_HAS_MORE_HISTORY_IMAGES";
export const SET_PAGE_HISTORY_IMAGES = "SET_PAGE_HISTORY_IMAGES";
export const CLEAR_HISTORY_IMAGES = "CLEAR_HISTORY_IMAGES";
export const UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER = "UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER";
export const SEARCH_HISTORY_IMAGES_LOADING = "SEARCH_HISTORY_IMAGES_LOADING";
export const CLEAR_HISTORY_IMAGES_SEARCH = "CLEAR_HISTORY_IMAGES_SEARCH";

export const GET_ALL_GALLERY_IMAGES_SERVER = "GET_ALL_GALLERY_IMAGES_SERVER";
export const CLEAR_GALLERY_IMAGES = "CLEAR_GALLERY_IMAGES";
export const SEARCH_GALLERY_IMAGES_LOADING = "SEARCH_GALLERY_IMAGES_LOADING";
export const CLEAR_GALLERY_IMAGES_SEARCH = "CLEAR_GALLERY_IMAGES_SEARCH";
export const DELETE_GALLERY_IMAGE_SERVER = "DELETE_GALLERY_IMAGE_SERVER";
export const UPDATE_HAS_MORE_GALLERY_IMAGES = "UPDATE_HAS_MORE_GALLERY_IMAGES";
export const SET_PAGE_GALLERY_IMAGES = "SET_PAGE_GALLERY_IMAGES";

export const GET_PROMPT_TO_VIDEO_LIST_SERVER = "GET_PROMPT_TO_VIDEO_LIST_SERVER";
export const UPDATE_HAS_MORE_PROMPT_TO_VIDEO_LIST = "UPDATE_HAS_MORE_PROMPT_TO_VIDEO_LIST";
export const SET_PAGE_PROMPT_TO_VIDEO_LIST = "SET_PAGE_PROMPT_TO_VIDEO_LIST";
export const ADD_PROMPT_TO_VIDEO_SERVER = "ADD_PROMPT_TO_VIDEO_SERVER";
export const GET_PROMPT_TO_VIDEO_BY_ID_SERVER = "GET_PROMPT_TO_VIDEO_BY_ID_SERVER";
export const CLEAR_PROMPT_TO_VIDEO_LIST = "CLEAR_PROMPT_TO_VIDEO_LIST";

export const GET_ALL_GALLERY_VIDEOS_SERVER = "GET_ALL_GALLERY_VIDEOS_SERVER";
export const CLEAR_GALLERY_VIDEOS = "CLEAR_GALLERY_VIDEOS";
export const SEARCH_GALLERY_VIDEOS_LOADING = "SEARCH_GALLERY_VIDEOS_LOADING";
export const CLEAR_GALLERY_VIDEOS_SEARCH = "CLEAR_GALLERY_VIDEOS_SEARCH";
export const DELETE_GALLERY_VIDEO_SERVER = "DELETE_GALLERY_VIDEO_SERVER";
export const UPDATE_HAS_MORE_GALLERY_VIDEOS = "UPDATE_HAS_MORE_GALLERY_VIDEOS";
export const SET_PAGE_GALLERY_VIDEOS = "SET_PAGE_GALLERY_VIDEOS";

export const DOWNLOAD_ALL_IMAGES_SERVER = "DOWNLOAD_ALL_IMAGES_SERVER";
export const UPDATE_DOWNLOAD_ALL_IMAGES_LOADING = "UPDATE_DOWNLOAD_ALL_IMAGES_LOADING";
export const UPDATE_DOWNLOAD_ALL_IMAGES = "UPDATE_DOWNLOAD_ALL_IMAGES";

export const CREATE_GALLERY_IMAGE_SERVER = "CREATE_GALLERY_IMAGE_SERVER";
export const CREATE_GALLERY_VIDEO_SERVER = "CREATE_GALLERY_VIDEO_SERVER";

export const INPAINT_RENDER_SERVER = "INPAINT_RENDER_SERVER";

export const UPSCALE_IMAGE_SERVER = "UPSCALE_IMAGE_SERVER";
export const UPSCALE_VIDEO_SERVER = "UPSCALE_VIDEO_SERVER";

export const SWAP_FACE_VIDEO_SERVER = "SWAP_FACE_VIDEO_SERVER";
export const GET_SWAPPED_FACE_VIDEO_SERVER = "GET_SWAPPED_FACE_VIDEO_SERVER";

export const UPDATE_FACE_SWAP_IS_LOADING = "UPDATE_FACE_SWAP_IS_LOADING";

export const SYNC_LIPS_VIDEO_SERVER = "SYNC_LIPS_VIDEO_SERVER";
export const GET_LIPS_SYNCED_VIDEO_SERVER = "GET_LIPS_SYNCED_VIDEO_SERVER";

export const CLEAR_IMAGE_FROM_MODULE = "CLEAR_IMAGE_FROM_MODULE";
export const GET_AI_IMAGES_GROUPED_RECORDS_SERVER = "GET_AI_IMAGES_GROUPED_RECORDS_SERVER";
export const UPDATE_HAS_MORE_AI_IMAGES_GROUPED_RECORDS = "UPDATE_HAS_MORE_AI_IMAGES_GROUPED_RECORDS";
export const CLEAR_AI_IMAGES_GROUPED_RECORDS = "CLEAR_AI_IMAGES_GROUPED_RECORDS";
export const DELETE_AI_IMAGES_GROUPED_RECORD_SERVER = "DELETE_AI_IMAGES_GROUPED_RECORD_SERVER";

interface GenerateImageProps {
  text: string;
  width: number;
  height: number;
  enhance: number;
  samplesAmount: number;
  createVariationFileUrl?: string;
  upscale: string;
  style_category: string | null;
  style_sub_category: string | null;
  story?: string;
  resourceImage?: string;
  model?: "personalize" | "story";
  face?: string;
}

interface UpdateLoadingProps {
  isLoading: boolean;
  module: ImagesModules;
}

interface UpdateGeneratedImages {
  data: IImage[];
}

interface GetAllHistoryImagesServerProps {
  pageNumber?: number;
  pageSize?: number;
  keyword?: string;
  isHistoryPage?: boolean;
  sortByDesc?: boolean;
  isSortLoading?: boolean;
}

interface UpdateHasMoreHistoryImagesProps {
  hasMore: boolean;
}

interface SetPageHistoryImagesProps {
  pageNumber: number;
}
interface DownloadAllImagesProps {
  images: string[];
}

interface CreateGalleryImagesServerProps {
  path: string;
  fileName: string;
}

interface GetPromptToVideoListServerProps {
  pageNumber?: number;
  pageSize?: number;
  keyword?: string;
}

interface AddPromptToVideoServerProps {
  title: string;
  prompt?: string;
  generatedImage?: string;
  motion: string;
  size: string;
  personalize?: boolean;
  resourceImage?: string;
  story?: string;
  extendSeconds?: number;
  pose?: boolean;
  poseImage?: string;
}

interface GetPromptToVideoByIdServerProps {
  id: number;
}

interface UpscaleServerProps {
  id?: number;
  url: string;
}

interface UploadImageToVisualGalleryProps {
  data: FormData;
  module?:
    | ImagesModules.storyReferenceImage
    | ImagesModules.personalizeImage
    | ImagesModules.faceSwapImage
    | ImagesModules.poseImage;
}

interface SwapFaceVideoServerProps {
  faceswapType: string;
  source: string;
  target: string;
}

interface GetSwappedFaceVideoServerProps {
  faceswapId: number;
}

interface SyncLipsVideoServerProps {
  formData: FormData;
}

interface GetLipsSyncedVideoServerProps {
  lipsyncId: number;
  module?: ImagesModules.storyReferenceImage | ImagesModules.personalizeImage | ImagesModules.poseImage;
}

interface GetAIImagesGroupedRecordsServer {
  pageNumber?: number;
  pageSize?: number;
  sortByDesc?: boolean;
  isSortLoading?: boolean;
}

export const generateImagesServer = ({
  text,
  width,
  height,
  enhance,
  samplesAmount,
  createVariationFileUrl,
  upscale,
  style_category,
  style_sub_category,
  story,
  resourceImage,
  face,
  model,
}: GenerateImageProps) => ({
  type: GENERATE_IMAGE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/GetImageFromText",
      data: {
        textInput: text,
        wd: width,
        ht: height,
        enhance,
        n_samples: samplesAmount,
        createVariationFileUrl,
        upscale,
        style_category,
        style_sub_category,
        story,
        resourceImage,
        model,
        face,
      },
    },
  },
});

export const updateGeneratedImagesLoading = ({ isLoading, module }: UpdateLoadingProps) => ({
  type: UPDATE_IMAGES_LOADING,
  payload: { isLoading, module },
});

export const updateGeneratedImages = ({ data }: UpdateGeneratedImages) => ({
  type: UPDATE_GENERATED_IMAGES,
  payload: data,
});

export const getAllHistoryImagesServer = ({
  pageNumber,
  pageSize,
  keyword,
  isHistoryPage,
}: GetAllHistoryImagesServerProps) => ({
  type: GET_ALL_HISTORY_IMAGES_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/GetAllHistoryImages",
      data: {
        pageNumber,
        pageSize: pageSize || 80,
        keyword,
        sortWith: "insertDateTime",
        sortByDesc: true,
      },
    },
    isHistoryPage,
  },
});

export const updateHasMoreHistoryImages = ({ hasMore }: UpdateHasMoreHistoryImagesProps) => ({
  type: UPDATE_HAS_MORE_HISTORY_IMAGES,
  payload: {
    hasMore,
  },
});

export const setPageHistoryImages = ({ pageNumber }: SetPageHistoryImagesProps) => ({
  type: SET_PAGE_HISTORY_IMAGES,
  payload: { pageNumber },
});

export const clearHistoryImages = () => ({
  type: CLEAR_HISTORY_IMAGES,
});

export const uploadImageToVisualGalleryServer = ({ data, module }: UploadImageToVisualGalleryProps) => ({
  type: UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/UploadImageToVisualGallery",
      data,
    },
    module,
  },
});

export const clearImageFromModule = (
  module:
    | ImagesModules.storyReferenceImage
    | ImagesModules.personalizeImage
    | ImagesModules.faceSwapImage
    | ImagesModules.poseImage,
) => ({
  type: CLEAR_IMAGE_FROM_MODULE,
  payload: { module },
});

export const searchHistoryImagesLoading = (loading: boolean) => ({
  type: SEARCH_HISTORY_IMAGES_LOADING,
  payload: loading,
});

export const clearHistoryImagesSearch = () => ({
  type: CLEAR_HISTORY_IMAGES_SEARCH,
});

export const getAllGalleryImagesServer = ({ pageNumber, pageSize, keyword }: GetAllHistoryImagesServerProps) => ({
  type: GET_ALL_GALLERY_IMAGES_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/GetAllGalleryImages",
      data: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 12,
        keyword,
        sortWith: "insertDateTime",
        sortByDesc: true,
      },
    },
  },
});

export const updateHasMoreGalleryImages = ({ hasMore }: UpdateHasMoreHistoryImagesProps) => ({
  type: UPDATE_HAS_MORE_GALLERY_IMAGES,
  payload: {
    hasMore,
  },
});

export const setPageGalleryImages = ({ pageNumber }: SetPageHistoryImagesProps) => ({
  type: SET_PAGE_GALLERY_IMAGES,
  payload: { pageNumber },
});

export const clearGalleryImages = () => ({
  type: CLEAR_GALLERY_IMAGES,
});

export const searchGalleryImagesLoading = (loading: boolean) => ({
  type: SEARCH_GALLERY_IMAGES_LOADING,
  payload: loading,
});

export const clearGalleryImagesSearch = () => ({
  type: CLEAR_GALLERY_IMAGES_SEARCH,
});

export const deleteGalleryImageServer = (id: number) => ({
  type: DELETE_GALLERY_IMAGE_SERVER,
  payload: {
    request: {
      method: "DELETE",
      url: `/AIImages/DeleteGalleryImage?Id=${id}`,
    },
    id,
  },
});

export const getAIImagesGroupedRecordsServer = ({
  pageNumber,
  pageSize,
  sortByDesc,
  isSortLoading,
}: GetAIImagesGroupedRecordsServer) => ({
  type: GET_AI_IMAGES_GROUPED_RECORDS_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/user/aiImagesGroupedRecords",
      data: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 40,
        sortWith: "insertDateTime",
        sortByDesc,
      },
      isSortLoading,
    },
  },
});

export const updateHasMoreAIImagesGroupedRecords = ({ hasMore }: UpdateHasMoreHistoryImagesProps) => ({
  type: UPDATE_HAS_MORE_AI_IMAGES_GROUPED_RECORDS,
  payload: {
    hasMore,
  },
});

export const clearAIImagesGroupedRecords = () => ({
  type: CLEAR_AI_IMAGES_GROUPED_RECORDS,
});

export const deleteAIImagesGroupedRecordServer = (id: number) => ({
  type: DELETE_AI_IMAGES_GROUPED_RECORD_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/AIImages/deleteAIImage?Id=${id}`,
    },
    id,
  },
});

export const downloadAllImagesServer = ({ images }: DownloadAllImagesProps) => ({
  type: DOWNLOAD_ALL_IMAGES_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/DownloadZip",
      data: images,
    },
  },
});

export const updateDownloadAllImagesLoading = ({ isLoading, module }: UpdateLoadingProps) => ({
  type: UPDATE_DOWNLOAD_ALL_IMAGES_LOADING,
  payload: { isLoading, module },
});

export const updateDownloadAllImages = () => ({
  type: UPDATE_DOWNLOAD_ALL_IMAGES,
});

export const createGalleryImageServer = ({ fileName, path }: CreateGalleryImagesServerProps) => ({
  type: CREATE_GALLERY_IMAGE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/CreateGalleryImage",
      data: {
        fileName,
        path,
        status: 1,
      },
    },
  },
});

export const inPaintRenderServer = (data: FormData, isEdit: boolean) => ({
  type: INPAINT_RENDER_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/AIImages/InPaintingRender",
      data,
    },
    isEdit,
  },
});

export const getPromptToVideoListServer = ({ pageNumber, pageSize, keyword }: GetPromptToVideoListServerProps) => ({
  type: GET_PROMPT_TO_VIDEO_LIST_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/Prompt2VideoProject/list",
      data: {
        pageNumber,
        pageSize: pageSize || 16,
        keyword,
        sortWith: "",
        sortByDesc: true,
      },
    },
  },
});

export const addPromptToVideoServer = ({
  title,
  prompt,
  generatedImage,
  motion,
  size,
  personalize,
  resourceImage,
  story,
  extendSeconds,
  pose,
  poseImage,
}: AddPromptToVideoServerProps) => ({
  type: ADD_PROMPT_TO_VIDEO_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/Prompt2VideoProject/add",
      data: {
        title,
        prompt,
        generatedImage,
        motion,
        size,
        personalize,
        resourceImage,
        story,
        extendSeconds,
        pose,
        poseImage,
      },
    },
  },
});

export const getPromptToVideoByIdServer = ({ id }: GetPromptToVideoByIdServerProps) => ({
  type: GET_PROMPT_TO_VIDEO_BY_ID_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/Prompt2VideoProject/get?Id=${id}`,
    },
  },
});

export const clearPromptToVideoList = () => ({
  type: CLEAR_PROMPT_TO_VIDEO_LIST,
});

export const updateHasMorePromptToVideoList = ({ hasMore }: UpdateHasMoreHistoryImagesProps) => ({
  type: UPDATE_HAS_MORE_PROMPT_TO_VIDEO_LIST,
  payload: {
    hasMore,
  },
});

export const setPagePromptToVideoList = ({ pageNumber }: SetPageHistoryImagesProps) => ({
  type: SET_PAGE_PROMPT_TO_VIDEO_LIST,
  payload: { pageNumber },
});

export const getAllGalleryVideosServer = ({
  pageNumber,
  pageSize,
  keyword,
  sortByDesc = true,
  isSortLoading,
}: GetAllHistoryImagesServerProps) => ({
  type: GET_ALL_GALLERY_VIDEOS_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/Prompt2VideoProject/GetAllGalleryVideos",
      data: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 12,
        keyword,
        sortWith: "insertDateTime",
        sortByDesc,
      },
      isSortLoading,
    },
  },
});

export const updateHasMoreGalleryVideos = ({ hasMore }: UpdateHasMoreHistoryImagesProps) => ({
  type: UPDATE_HAS_MORE_GALLERY_VIDEOS,
  payload: {
    hasMore,
  },
});

export const setPageGalleryVideos = ({ pageNumber }: SetPageHistoryImagesProps) => ({
  type: SET_PAGE_GALLERY_VIDEOS,
  payload: { pageNumber },
});

export const clearGalleryVideos = () => ({
  type: CLEAR_GALLERY_VIDEOS,
});

export const searchGalleryVideosLoading = (loading: boolean) => ({
  type: SEARCH_GALLERY_VIDEOS_LOADING,
  payload: loading,
});

export const clearGalleryVideosSearch = () => ({
  type: CLEAR_GALLERY_VIDEOS_SEARCH,
});

export const deleteGalleryVideoServer = (id: number) => ({
  type: DELETE_GALLERY_VIDEO_SERVER,
  payload: {
    request: {
      method: "DELETE",
      url: `/Prompt2VideoProject/DeleteGalleryVideo?Id=${id}`,
    },
    id,
  },
});

export const createGalleryVideoServer = ({ fileName, path }: CreateGalleryImagesServerProps) => ({
  type: CREATE_GALLERY_VIDEO_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/Prompt2VideoProject/createGalleryVideo",
      data: {
        fileName,
        path,
        status: 1,
      },
    },
  },
});

export const upscaleImageServer = ({ id, url }: UpscaleServerProps) => ({
  type: UPSCALE_IMAGE_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/Prompt2VideoProject/upscaleImage?url=${url}`,
    },
    id,
  },
});

export const upscaleVideoServer = ({ url }: UpscaleServerProps) => ({
  type: UPSCALE_VIDEO_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/Prompt2VideoProject/upscaleVideo?url=${url}`,
    },
  },
});

export const swapFaceVideoServer = ({ faceswapType, source, target }: SwapFaceVideoServerProps) => ({
  type: SWAP_FACE_VIDEO_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/Faceswap/create`,
      data: { faceswapType, source, target },
    },
  },
});

export const getSwappedFaceVideoServer = ({ faceswapId }: GetSwappedFaceVideoServerProps) => ({
  type: GET_SWAPPED_FACE_VIDEO_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/Faceswap/get`,
      params: { Id: faceswapId },
    },
  },
});

export const updateFaceSwapIsLoading = (loading: boolean) => ({
  type: UPDATE_FACE_SWAP_IS_LOADING,
  payload: loading,
});

export const syncLipsVideoServer = ({ formData }: SyncLipsVideoServerProps) => ({
  type: SYNC_LIPS_VIDEO_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/Lipsync/create`,
      data: formData,
    },
  },
});

export const getLipsSyncedVideoServer = ({ lipsyncId }: GetLipsSyncedVideoServerProps) => ({
  type: GET_LIPS_SYNCED_VIDEO_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/Lipsync/get`,
      params: { Id: lipsyncId },
    },
  },
});
