import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import PlayPauseButton from "./PlayPauseButton";
import MenuItem from "../../../components/ActionMenu/MenuItem";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import ProgressBar from "../../VideoTranslation/components/ProgressBar";
import CircularProgress from "../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { downloadFile } from "../../../lib/downloadFile";
import { VideoTranslationStatus } from "../../../types/videoTranslation";
import { CrossIcon, DownloadIcon, OptionsIcon, TrashIcon } from "../../../components/Icons/Icons";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { ShuffleIcon } from "../../../components/Icons/ShuffleIcon";

function isImageUrl(url: string): boolean {
  return /\.(jpeg|jpg|png)$/i.test(url);
}

interface Props {
  type: string;
  status: VideoTranslationStatus;
  insertDateTime: string;
  estimatedTime: number;
  faceswapProjectId: number;
  outputUrl: string;
}

const MyLibraryBarItem = ({ type, status, insertDateTime, estimatedTime, faceswapProjectId, outputUrl }: Props) => {
  const navigate = useNavigate();

  const [loadedVideos, setLoadedVideos] = useState<any>(true);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  const videoRef = useRef(document.createElement("video"));
  const [playing, setPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);

  const [menuOpen, setMenuOpen] = useState<number | null>(null);
  const menuOptions = [
    "Download",
    //  "Delete"
  ];

  const recogniseIcon = (name: string) => {
    switch (name) {
      case "Download":
        return <DownloadIcon />;
      case "Delete":
        return <TrashIcon />;
      default:
        return <></>;
    }
  };

  const actionMenu = [
    {
      icon: <OptionsIcon />,
      options: menuOptions?.map((option) => ({
        name: option,
        icon: recogniseIcon(option),
      })),
    },
  ];

  const statusText = {
    [VideoTranslationStatus.Pending]: "Pending...",
    [VideoTranslationStatus.InProgress]: "Generating...",
    [VideoTranslationStatus.Completed]: "Completed",
    [VideoTranslationStatus.Failed]: "Failed To Generate",
  };

  const lastUpdateTime = new Date(insertDateTime + "Z");
  const timeElapsed = (Number(currentTime) - Number(lastUpdateTime)) / 1000;
  const percentComplete = (timeElapsed / Number(estimatedTime)) * 100;

  const handleVideoLoad = () => {
    setLoadedVideos(false);
  };

  const togglePlayed = () => {
    setIsPlayed(!isPlayed);
    setPlaying(false);
    videoRef.current.currentTime = 0;
  };

  const togglePlay = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setPlaying(!playing);
    if (playing === true) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleOpenMenu = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(menuOpen === id ? null : id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setMenuOpen(null);
  };

  const handleDeleteClick = (e: any) => {
    //TODO Add delete logic
    return;
  };

  useEffect(() => {
    if (status === VideoTranslationStatus.InProgress || status === VideoTranslationStatus.Pending) {
      const intervalId = setInterval(() => setCurrentTime(new Date()), 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [status]);

  const translationStatus = statusText[status as VideoTranslationStatus];
  const isCompleted = status === VideoTranslationStatus.Completed;
  const hasFailed = status === VideoTranslationStatus.Failed;

  return (
    <Wrapper onClick={() => navigate(`/${type}/${faceswapProjectId}`)}>
      {isCompleted ? (
        <>
          {isImageUrl(outputUrl) ? (
            <img src={outputUrl} onLoad={() => handleVideoLoad()} />
          ) : (
            <div>
              <video ref={videoRef} onLoadedData={handleVideoLoad} onEnded={togglePlayed}>
                <source src={outputUrl} type="video/mp4" />
              </video>
              {!isImageUrl(outputUrl) ? "" : <CircularProgress color="#fff" />}
            </div>
          )}
          {loadedVideos ? (
            <Placeholder />
          ) : (
            <>
              {/* <RemixButton>
                <Button
                  text={"Remix"}
                  icon={<ShuffleIcon />}
                  buttonTheme={ButtonThemes.Transparent}
                  onClick={() => {
                    return;
                  }}
                  style={{ zIndex: "1" }}
                />
              </RemixButton> */}
              {!isImageUrl(outputUrl) && (
                <PlayButtonWrapper>
                  <PlayPauseButton isPlay={playing} handlePlayChange={(e: any) => togglePlay(e)} />
                </PlayButtonWrapper>
              )}
              <ActionWrapper>
                {actionMenu.map((m, order) => (
                  <ActionMenu
                    key={order}
                    position={{
                      x: "left",
                      y: "bottom",
                    }}
                    open={menuOpen === faceswapProjectId}
                    menuStyle={{ top: "0px", right: "40px", padding: "4px 4px 4px 12px" }}
                    handleClose={handleCloseMenu}
                    trigger={
                      <IconButton
                        iconButtonTheme={IconButtonThemes.Transparent}
                        icon={m.icon}
                        onClick={(e: any) => handleOpenMenu(e, faceswapProjectId)}
                      />
                    }
                  >
                    {m.options && (
                      <MenuWrapper>
                        {m.options.map((option, index) => (
                          <MenuItem
                            key={index}
                            icon={option.icon}
                            onClick={(e: any) => {
                              e.preventDefault();
                              option.name === "Download" && downloadFile(e, outputUrl || "");
                              option.name === "Delete" && handleDeleteClick(e);
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </MenuWrapper>
                    )}
                  </ActionMenu>
                ))}
              </ActionWrapper>
            </>
          )}
        </>
      ) : (
        <StatusWrapper status={status}>
          <div>
            <span>
              {translationStatus} <p>project #{faceswapProjectId}</p>
            </span>
            {!hasFailed && <ProgressBar value={percentComplete} maxHeight={12} />}
          </div>
        </StatusWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 320px;
  height: 230px;

  @media (max-width: 400px) {
    max-width: 100%;
    max-height: 128px;
    width: 100%;
  }

  video {
    width: 320px;
    height: 230px;
    object-fit: cover;
    @media (max-width: 400px) {
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 320px;
    height: 230px;
    object-fit: cover;
    @media (max-width: 400px) {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    button,
    div {
      display: flex;
    }
  }
`;

const StatusWrapper = styled.div<{ status: VideoTranslationStatus }>`
  width: 320px;
  height: 230px;
  background: #6d6d6d33;
  padding: 12px;
  position: relative;
  display: flex;
  align-items: ${({ status }) => (status === VideoTranslationStatus.Failed ? "center" : "end")};
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 166px;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      text-transform: capitalize;

      p {
        max-width: 166px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }
`;

const PlayButtonWrapper = styled.div`
  & div {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 54.02px;
    angle: -0 deg;
    background: #0286dcba;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
  }
`;

const RemixButton = styled.div`
  position: absolute;
  width: 112px;
  height: 30px;
  display: none;
  align-items: center;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  border: none;
  border-radius: 15px;
  background: #48d64859;
  gap: 10px;
  padding: 0 15px;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #e9e9e9, #c4c3c3);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const ActionWrapper = styled("div")`
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  align-items: center;
  justify-content: center;
  align-items: center;
  background: #0063b4;
  border-radius: 6px;
  width: 25px;
  height: 25px;

  span {
    button {
      width: 25px;
      height: 25px;
      max-width: 25px;
      border: none;
      box-shadow: none;
      border-radius: 6px;

      svg > path {
        fill: ${({ theme }) => theme.tableTitleText};
      }
    }
  }

  .menu-wrapper {
    border: none;
    max-width: 180px;
    background: #0063b4;

    div {
      color: #ffffff;
      -webkit-text-fill-color: unset;
      background: none;
      padding: 3px 0px;
      font-weight: 500;
      svg > path {
        fill: #ffffff;
      }
    }
  }
`;

const MenuWrapper = styled("div")`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export default MyLibraryBarItem;
