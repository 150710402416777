export const ExportIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66663 7.33333L14.1333 1.86666"
      stroke="#0063B4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 4.53333V1.33333H11.4667"
      stroke="#0063B4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33337 1.33333H6.00004C2.66671 1.33333 1.33337 2.66667 1.33337 6V10C1.33337 13.3333 2.66671 14.6667 6.00004 14.6667H10C13.3334 14.6667 14.6667 13.3333 14.6667 10V8.66667"
      stroke="#0063B4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TwitterIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M18.7788 4.18206C18.1557 4.45084 17.4427 4.6321 16.729 4.72522C17.4402 4.27955 18.0603 3.57008 18.3226 2.77496C17.6114 3.22064 16.899 3.49004 16.0074 3.67254C15.3775 2.97179 14.3923 2.53795 13.4101 2.5448C11.4459 2.55851 9.84986 4.15622 9.86339 6.09527C9.86524 6.35969 9.86709 6.6241 9.95821 6.88789C7.01063 6.73217 4.32228 5.34064 2.52185 3.23777C2.25769 3.76847 2.08342 4.38668 2.08773 5.00366C2.09634 6.2376 2.7287 7.29091 3.71513 7.90103C3.09014 7.90539 2.55321 7.73285 2.10495 7.47155C2.11663 9.14618 3.37705 10.6358 4.986 10.889C4.71876 10.979 4.36164 10.9815 4.0045 10.984C3.73665 10.9859 3.55808 10.9871 3.28962 10.9009C3.74587 12.308 5.09251 13.3563 6.69961 13.3451C5.45641 14.3233 3.94228 14.8627 2.24589 14.8746C1.97804 14.8765 1.71019 14.8783 1.35244 14.7927C2.96631 15.751 4.84556 16.3549 6.89909 16.3406C13.5061 16.2945 17.0399 10.8931 17.0073 6.22171L17.0042 5.78102C17.7167 5.51162 18.3373 4.89028 18.7788 4.18206Z"
        fill="#191B1F"
      />
    </g>
  </svg>
);
export const FacebookIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M19.1659 10.0001C19.1659 4.95844 15.0409 0.833435 9.99919 0.833435C4.95752 0.833435 0.83252 4.95844 0.83252 10.0001C0.83252 14.5834 4.15544 18.3647 8.5096 19.0522V12.6355H6.21794V10.0001H8.5096V7.9376C8.5096 5.64594 9.8846 4.38552 11.9471 4.38552C12.9784 4.38552 14.0096 4.61469 14.0096 4.61469V6.90635H12.8638C11.7179 6.90635 11.3742 7.59385 11.3742 8.28135V10.0001H13.895L13.4367 12.6355H11.2596V19.1668C15.8429 18.4793 19.1659 14.5834 19.1659 10.0001Z"
        fill="#0048B4"
      />
    </g>
  </svg>
);
export const InstagramIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M10.2079 12.7606C11.6174 12.7606 12.76 11.618 12.76 10.2085C12.76 8.79899 11.6174 7.65637 10.2079 7.65637C8.79838 7.65637 7.65576 8.79899 7.65576 10.2085C7.65576 11.618 8.79838 12.7606 10.2079 12.7606Z"
        fill="#F7002C"
      />
      <path
        d="M13.7156 2.23175H6.69727C5.51317 2.23309 4.37796 2.70407 3.54068 3.54135C2.70339 4.37863 2.23242 5.51384 2.23108 6.69794V13.7162C2.23242 14.9003 2.70339 16.0355 3.54068 16.8728C4.37796 17.7101 5.51317 18.1811 6.69727 18.1824H13.7156C14.8997 18.1811 16.0349 17.7101 16.8722 16.8728C17.7094 16.0355 18.1804 14.9003 18.1818 13.7162V6.69794C18.1804 5.51384 17.7094 4.37863 16.8722 3.54135C16.0349 2.70407 14.8997 2.23309 13.7156 2.23175ZM10.2064 14.0353C9.44928 14.0353 8.70914 13.8107 8.0796 13.3901C7.45007 12.9694 6.9594 12.3716 6.66966 11.6721C6.37991 10.9726 6.3041 10.2028 6.45181 9.46025C6.59952 8.71766 6.96412 8.03555 7.4995 7.50017C8.03488 6.96479 8.71699 6.60019 9.45958 6.45248C10.2022 6.30477 10.9719 6.38058 11.6714 6.67033C12.3709 6.96007 12.9688 7.45074 13.3894 8.08028C13.8101 8.70981 14.0346 9.44995 14.0346 10.2071C14.0334 11.222 13.6297 12.1951 12.9121 12.9127C12.1944 13.6304 11.2214 14.0341 10.2064 14.0353ZM14.3536 7.01695C14.1643 7.01695 13.9793 6.96082 13.8219 6.85566C13.6645 6.7505 13.5418 6.60103 13.4694 6.42616C13.397 6.25128 13.378 6.05885 13.4149 5.8732C13.4519 5.68756 13.543 5.51703 13.6769 5.38318C13.8107 5.24934 13.9812 5.15819 14.1669 5.12126C14.3525 5.08433 14.545 5.10329 14.7198 5.17572C14.8947 5.24816 15.0442 5.37083 15.1493 5.52821C15.2545 5.68559 15.3106 5.87063 15.3106 6.05991C15.3106 6.31374 15.2098 6.55716 15.0303 6.73664C14.8508 6.91612 14.6074 7.01695 14.3536 7.01695Z"
        fill="#F7002C"
      />
    </g>
  </svg>
);
export const MailIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1162 2.5C15.2337 2.5 16.3087 2.94167 17.0995 3.73417C17.8912 4.525 18.3337 5.59167 18.3337 6.70833V13.2917C18.3337 15.6167 16.442 17.5 14.1162 17.5H5.88366C3.55783 17.5 1.66699 15.6167 1.66699 13.2917V6.70833C1.66699 4.38333 3.54949 2.5 5.88366 2.5H14.1162ZM15.442 7.94999L15.5087 7.88332C15.7078 7.64166 15.7078 7.29166 15.4995 7.04999C15.3837 6.92582 15.2245 6.84999 15.0587 6.83332C14.8837 6.82416 14.717 6.88332 14.5912 6.99999L10.8337 9.99999C10.3503 10.4008 9.65785 10.4008 9.16702 9.99999L5.41702 6.99999C5.15785 6.80832 4.79952 6.83332 4.58368 7.05832C4.35868 7.28332 4.33368 7.64166 4.52452 7.89166L4.63368 7.99999L8.42535 10.9583C8.89202 11.325 9.45785 11.525 10.0503 11.525C10.6412 11.525 11.217 11.325 11.6828 10.9583L15.442 7.94999Z"
        fill="#009AF7"
      />
    </g>
  </svg>
);
export const LinkedInIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M16.0156 1.25H3.98437C2.47422 1.25 1.25 2.47422 1.25 3.98437V16.0156C1.25 17.5258 2.47422 18.75 3.98437 18.75H16.0156C17.5258 18.75 18.75 17.5258 18.75 16.0156V3.98437C18.75 2.47422 17.5258 1.25 16.0156 1.25Z"
        fill="#009AF7"
      />
      <path
        className="linkedin"
        d="M7.02181 14.9982H4.71976V7.75351H7.02181V14.9982ZM5.87079 6.73791C5.12601 6.73791 4.58435 6.19625 4.58435 5.45147C4.58435 4.7067 5.19372 4.16504 5.87079 4.16504C6.61556 4.16504 7.15722 4.7067 7.15722 5.45147C7.15722 6.19625 6.61556 6.73791 5.87079 6.73791ZM15.4175 14.9982H13.1154V11.0712C13.1154 9.92014 12.6415 9.5816 11.9644 9.5816C11.2873 9.5816 10.6103 10.1233 10.6103 11.1389V14.9982H8.30824V7.75351H10.4749V8.76912C10.678 8.29517 11.4905 7.55039 12.6415 7.55039C13.9279 7.55039 15.2821 8.29517 15.2821 10.5295V14.9982H15.4175Z"
        fill="white"
      />
    </g>
  </svg>
);
