import styled from "styled-components";
import { Slide } from "@mui/material";
import { useSelector } from "react-redux";

import MyLibraryBarItem from "./MyLibraryBarItem";
import ArrowDown from "../../../components/Icons/ArrowDown";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { getFaceswapList, getFaceswapListLoading } from "../../../redux/reducers/faceswapReducer";

interface Props {
  type: string;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const MyLibraryBar = ({ type, isVisible, setIsVisible }: Props) => {
  const data = useSelector(getFaceswapList);
  const isLoading = useSelector(getFaceswapListLoading);

  const toggleRecentlyGeneratedVisible = () => setIsVisible(!isVisible);

  return (
    <BottomContent active={isVisible}>
      <BottomContentFormControl active={isVisible} onClick={toggleRecentlyGeneratedVisible}>
        <div>
          <span>My Library</span>
          <IconButton>
            <ArrowDown />
          </IconButton>
          <div></div>
        </div>
      </BottomContentFormControl>
      <BottomContentSlide direction="up" in={isVisible} mountOnEnter unmountOnExit className="acvsss">
        <VideoItemsWrapper>
          {isLoading ? (
            <CircularProgressWrapper>
              <CircularProgress color="#009af7" />
            </CircularProgressWrapper>
          ) : (
            data.map((item) => <MyLibraryBarItem key={item.faceswapProjectId} type={type} {...item} />)
          )}
        </VideoItemsWrapper>
      </BottomContentSlide>
    </BottomContent>
  );
};

const BottomContent = styled.div<{ active: boolean }>`
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: auto;
  height: ${({ active }) => (active ? "290px" : "46px")};
  transition: height 0.3s ease;
  border-top: 1px solid ${({ theme }) => theme.videoTranslationGray};
  background-color: ${({ theme }) => theme.primaryBackground};

  @media (max-width: 400px) {
    height: ${({ active }) => (active ? "100%" : "46px")};
  }
`;

const BottomContentSlide = styled(Slide)`
  margin-top: auto;
`;

const VideoItemsWrapper = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
  max-height: 230px;
  margin: 0 25px 25px;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  @media (max-width: 400px) {
    flex-wrap: wrap;
    max-height: 100%;
  }
`;

const CircularProgressWrapper = styled.div`
  margin: auto;
`;

const BottomContentFormControl = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 12px 25px;
  div {
    display: flex;
    justify-content: space-between;

    & div:last-child {
      width: 256px;
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.primaryText};
  }
  svg {
    ${({ active }) =>
      !active &&
      `
        transform: rotate(180deg);
  `}

    width: 24px;
    height: 24px;
    path {
      fill: ${({ theme }) => theme.primaryText};
    }
  }
`;

const IconButton = styled.div`
  cursor: pointer;
`;

export default MyLibraryBar;
