import styled from "styled-components";
import { useDispatch } from "react-redux";

import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { GalleryVideo } from "../../types/image";
import { downloadImage } from "../../lib/downloadImage";
import { PopupUploadIcon, TrashIcon } from "../Icons/Icons";
import { EnlargedPicture } from "../../types/enlargedPicture";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import { Text2Video } from "../../types/text2Video";
import DividerWithText from "./DividerWithText";

const menu = [
  { name: "Download", icon: <PopupUploadIcon /> },
  { name: "Delete", icon: <TrashIcon /> },
];

interface Props {
  video: GalleryVideo;
  isHomePage?: boolean;
}

const GalleryVideoCard = ({ video, isHomePage = false }: Props) => {
  const dispatch = useDispatch();

  const handleVideoClick = (video: GalleryVideo) => {
    const prefilled: Text2Video = {
      outputUrl: video.path,
      fileName: video.fileName,
      isView: true,
    };
    dispatch(updatePopup({ popup: Popups.text2VideoPopup, status: true, prefilled }));
  };

  const handleImageDelete = (e: any) => {
    if (!video.videoGalleryID) {
      throw Error("The ID video is missing when deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: video.videoGalleryID,
      title: "Are you sure you want to delete video?",
      description: "The video will be removed and the action cannot be undone",
      typeId: 0,
      type: DeleteType.GalleryVideo,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  return (
    <GroupWrapper isHomePage={isHomePage}>
      {!isHomePage && <DividerWithText insertDateTime={video.insertDateTime} />}
      <ImgWrapper isHomePage={isHomePage} onClick={() => handleVideoClick(video)}>
        <StyledVideo src={video.path} />
        <ActionWrapper isHomePage={isHomePage}>
          {menu.map((m, order) => (
            <IconButton
              key={order}
              className={m.name + "Button"}
              iconButtonTheme={isHomePage ? IconButtonThemes.Primary : IconButtonThemes.Transparent}
              icon={m.icon}
              onClick={(e: any) => {
                e.preventDefault();
                m.name === "Download" && downloadImage(e, video.path);
                m.name === "Delete" && handleImageDelete(e);
              }}
            />
          ))}
        </ActionWrapper>
      </ImgWrapper>
    </GroupWrapper>
  );
};

const GroupWrapper = styled.div<{ isHomePage?: boolean }>`
  ${({ isHomePage }) =>
    !isHomePage &&
    `
  width: 100%;
  display: flex;
  flex-direction: column;
    `}
`;

const ImgWrapper = styled.div<{ isHomePage?: boolean }>`
  cursor: pointer;
  width: 228px;
  height: 160px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  ${({ isHomePage }) =>
    !isHomePage &&
    `
    width: unset;
    height: unset;
    max-width: 600px;
    max-height: 350px;
    border-radius: 10px;
    align-self: center;
 
    &:hover {
      .DeleteButton {
        visibility: visible;
        }
      }
    `}

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-shadow: ${({ theme }) => theme.cardShadow};
    pointer-events: none;
  }

  ${({ theme, isHomePage }) =>
    !isHomePage &&
    `
  button {
    position: absolute;
    width: 25px;
    height: 25px;
    border: none;
    box-shadow: none;
    border-radius: 6px;
    background: #0286dc;

    svg {
      width: 16px;
      height: 16px;
      & path {
        fill: #ffffff;
      }
    }

    &.DownloadButton {
      bottom: 15px;
      right: 15px;
    }

    &.DeleteButton {
      top: 15px;
      left: 15px;
      background: ${theme.red};
        visibility: hidden;
  }
  }
   `}
`;

const StyledVideo = styled.video`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const ActionWrapper = styled.div<{ isHomePage?: boolean }>`
  ${({ theme, isHomePage }) =>
    isHomePage &&
    `
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 16px;

  button {
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: none;
    border-radius: 6px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  svg path {
    fill: none;
    stroke: ${theme.white};
  }

  button:first-of-type svg path,
  button:last-of-type svg path {
    fill: ${theme.white};
    stroke: none;
  }

  .menu-wrapper {
    background: ${theme.primaryBackground};
  }
  `}
`;

export default GalleryVideoCard;
