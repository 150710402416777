import styled from "styled-components";
import { format, parseISO } from "date-fns";

const formatDateWithSuffix = (dateString: string) => {
  const date = parseISO(dateString);
  const day = format(date, "do");
  const monthYear = format(date, "MMMM yyyy");
  const time = format(date, "h:mm a");

  return `${day} ${monthYear} | ${time}`;
};

interface Props {
  insertDateTime: string;
}

const DividerWithText = ({ insertDateTime }: Props) => {
  return (
    <DividerWrapper>
      <DateLabel>{formatDateWithSuffix(insertDateTime)}</DateLabel>
      <Divider />
    </DividerWrapper>
  );
};

const DividerWrapper = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  margin: 20px 0px;
`;

const DateLabel = styled.span`
  color: #979797;
  background: ${({ theme }) => theme.primaryBackground};
  padding: 0px 10px;
  z-index: 1;
  margin: 0px auto;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
`;

const Divider = styled.hr`
  border-top: 1px solid #9797974d;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-image: initial;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

export default DividerWithText;
