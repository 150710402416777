import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { sliceString } from "../../lib/formatUtils";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { renameProjectServer } from "../../redux/actions/projectAction";
import { Project } from "../../types/project";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { ClockIcon, EditIcon, OptionsIcon, TrashIcon } from "../Icons/Icons";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import MenuItem from "../ActionMenu/MenuItem";
import ActionMenu from "../ActionMenu/ActionMenu";
import AudioPlayerCard from "../AudioPlayerCard/AudioPlayerCard";
import { isAudioUrl } from "../../lib/isAudioUrl";

interface ProjectCardProps {
  project: Project;
  title: string;
  description?: string;
  projectTime?: string;
  image?: string;
  fullWidth?: boolean;
  active?: boolean;
  sortByDesc: boolean;
  setActiveProject: any;
  handleActiveProject?: (e: any) => void;
}

const ProjectCard = ({
  project,
  title,
  description,
  projectTime,
  image,
  fullWidth = false,
  active,
  sortByDesc,
  setActiveProject,
  handleActiveProject,
}: ProjectCardProps) => {
  const dispatch = useDispatch();

  const [projectName, setProjectName] = useState<string>(title);
  const [menuOpen, setMenuOpen] = useState<number>();
  const handleChange = (e: any) => setProjectName(e.target.value);

  const handleOpenMenu = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen(id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();

    setMenuOpen(-1);
  };

  const handleKeyPress = (e: any, type?: "blur") => {
    if (e.code === "Enter" || type) {
      if (!project) throw Error("Project entity is missing while renaming");
      if (projectName !== project?.title) {
        dispatch(
          renameProjectServer({
            projectId: project?.projectId,
            projectTypeId: project?.projectTypeId,
            newTitle: projectName,
            oldTitle: project?.title,
          }),
        );
      }
      setActiveProject(null);
    }
  };

  useEffect(() => {
    setProjectName(project.title);
  }, [project.title]);

  const handleDeleteClick = (e: any) => {
    if (!project.projectId) {
      throw Error("Project entity is missing while deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: project.projectId,
      title: "Are you sure you want to delete project " + project.title,
      description: "The project will be removed and the action cannot be undone",
      typeId: 1,
      type: DeleteType.Project,
      sortByDesc,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  return (
    <Wrapper className="project-card" fullWidth={fullWidth}>
      <Left>
        <Header>
          {!active ? (
            <ProjectTitle>
              <div />
              <TitleWrapper>
                <Title>{title}</Title>
              </TitleWrapper>
              <ActionWrapper>
                {menu.map((m, order) => (
                  <ActionMenu
                    key={order}
                    position={{
                      x: "left",
                      y: "bottom",
                    }}
                    open={menuOpen === order}
                    menuStyle={{
                      top: "0px",
                      right: "25px",
                      padding: "12px 12px",
                      background: "#0286DC",
                      border: "none",
                    }}
                    handleClose={handleCloseMenu}
                    trigger={
                      <IconButton
                        iconButtonTheme={IconButtonThemes.Transparent}
                        icon={m.icon}
                        onClick={order === 0 ? (e: any) => handleOpenMenu(e, order) : () => null}
                      />
                    }
                  >
                    {m.options && (
                      <MenuWrapper>
                        {m.options.map((option, index) =>
                          option.hidden ? (
                            <></>
                          ) : (
                            <MenuItem
                              key={index}
                              icon={option.icon}
                              onClick={(e: any) => {
                                e.preventDefault();
                                option.name === "Rename" && handleActiveProject && handleActiveProject(e);
                                option.name === "Delete" && handleDeleteClick(e);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ),
                        )}
                      </MenuWrapper>
                    )}
                  </ActionMenu>
                ))}
              </ActionWrapper>
            </ProjectTitle>
          ) : (
            <Textfield
              autoFocus
              variant={TextfieldVariant.project}
              value={projectName}
              placeholder="Enter project name"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onBlur={(e) => handleKeyPress(e, "blur")}
            />
          )}
        </Header>
        {project?.output && isAudioUrl(project?.output) && <AudioPlayerCard url={project.output} />}
        {description && <Description>{sliceString(description, 169)}</Description>}
        {projectTime && (
          <TimeWrapper>
            <ClockIcon />
            <ProjectTime>{formatDistance(new Date(projectTime + "Z"), new Date())} ago</ProjectTime>
          </TimeWrapper>
        )}
      </Left>
    </Wrapper>
  );
};

const menu = [
  {
    icon: <OptionsIcon />,
    options: [
      {
        name: "Rename",
        icon: <EditIcon />,
        hidden: false,
      },
      {
        name: "Delete",
        icon: <TrashIcon />,
      },
    ],
  },
];

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  padding: 6px 4px;
  background: ${({ theme }) => theme.tableBackground};
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 4px;
  width: 234px;
  height: 186px;
  position: relative;

  &:hover {
    .delete {
      opacity: 1;
    }
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `}
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 6px;
  right: 4px;

  span {
    button {
      width: 20px;
      height: 20px;
      border: none;
      box-shadow: none;
      border-radius: 6px;

      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #191b1fba;
        }
      }

      &:focus {
        border: none;
      }
    }
  }
`;

const MenuWrapper = styled.div`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-radius: 12px;

  & div {
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    background-clip: unset;
    color: #ffffff;

    & path {
      fill: #ffffff;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 4px;

  button {
    svg > path {
      fill: ${({ theme }) => theme.icon};
    }
  }
`;

const ProjectTitle = styled.div`
  display: flex;
  margin-left: 4px;
  width: calc(100% - 25px);

  > div:first-child {
    width: 20px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Title = styled.div`
  max-width: 185px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const Description = styled.div`
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  // word-break: break-all;
  -webkit-box-orient: vertical;
  margin: 7px 10px 0px 10px;
`;

const ProjectTime = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.primaryText};
  opacity: 0.6;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: auto 10px 0;
  justify-content: flex-end;

  & > svg > path {
    fill: ${({ theme }) => theme.icon};
    opacity: 0.6;
  }
`;

const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;

  .loader {
    position: absolute;
    z-index: 300;
    background-color: white;
    border-radius: 50%;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;

    path {
      fill: #009af7;
    }
  }

  .delete {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    z-index: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;

    svg {
      margin: auto;
      width: 20px;
      height: auto;

      path {
        fill: red;
      }
    }
  }
`;

const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: 40px;
  height: 40px;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
`;

const EmptyImage = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.disableIcon}33;
  border-radius: 3px;
`;

export default ProjectCard;
