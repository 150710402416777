import { useEffect, useState } from "react";
import styled from "styled-components";

import Button, { ButtonThemes } from "../../../components/Button/Button";
import { LeftArrowPlanIcon, RightArrowPlanIcon } from "../../../components/Icons/Icons";

interface Props {
  historyLenghtPerPage: number;
  historyLenth: number;
  currentPage: number;
  disabled?: boolean;
  paginate: (value: number) => void;
}

const Pagination = ({ historyLenghtPerPage, historyLenth, currentPage, disabled, paginate }: Props) => {
  const [pageNumbers, setPageNumbers] = useState<(number | "...")[]>([]);

  const generatePageNumbers = () => {
    if (historyLenth <= 4) {
      setPageNumbers(Array.from({ length: historyLenth }, (_, i) => i + 1));
    } else {
      if (currentPage <= 2) {
        setPageNumbers([1, 2, 3, 4, "...", historyLenth]);
      } else if (currentPage >= historyLenth - 1) {
        setPageNumbers([1, "...", historyLenth - 3, historyLenth - 2, historyLenth - 1, historyLenth]);
      } else {
        setPageNumbers([1, currentPage - 1, currentPage, currentPage + 1, "...", historyLenth]);
      }
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < historyLenth) {
      paginate(currentPage + 1);
    }
  };

  useEffect(() => {
    generatePageNumbers();
  }, [historyLenth, historyLenghtPerPage, currentPage]);

  return (
    <Wrapper disabled={disabled}>
      <ButtonWrapper>
        <Button buttonTheme={ButtonThemes.Transparent} onClick={handlePrevPage} text={<LeftArrowPlanIcon />} />
      </ButtonWrapper>
      {pageNumbers.map((number, index) => (
        <ButtonWrapper key={index} className={currentPage === number ? "active" : number === "..." ? "not-active" : ""}>
          <Button
            buttonTheme={ButtonThemes.Transparent}
            onClick={() => typeof number === "number" && paginate(number)}
            text={number !== "..." ? String(number) : "..."}
            disabled={number === "..."}
          />
        </ButtonWrapper>
      ))}
      <ButtonWrapper>
        <Button buttonTheme={ButtonThemes.Transparent} onClick={handleNextPage} text={<RightArrowPlanIcon />} />
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  order: 2;
  gap: 8px;

  .not-active {
    box-shadow: none;
    border: none;
  }

  .active {
    /* box-shadow: 5px 5px 10px 0px #aeaec04d, -5px -5px 10px 0px #ffffff, 2px 2px 4px 0px #ffffff inset,
      -2px -2px 4px 0px #0000001a inset; */

    button {
      background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
      pointer-events: none;
      opacity: 0.6;
    `}

  @media (max-width: 800px) {
    order: 3;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  min-width: 32px;
  height: 32px;
  align-items: center;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  border: 1px solid ${({ theme }) => theme.tableDivider};

  button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 6px;

    svg path {
      opacity: 1;
      fill: none;
      stroke: ${({ theme }) => theme.tableText};
    }

    span {
      display: flex;
      color: ${({ theme }) => theme.tableTitleText};
    }
  }
`;

export default Pagination;
