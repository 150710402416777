export const NexoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 6.27999V17.71C22 17.89 21.91 18.05 21.76 18.14L18.87 19.87C18.71 19.96 18.52 19.96 18.36 19.87L8.75 14.28C8.6 14.19 8.5 14.03 8.5 13.85V12.52C8.5 12.13 8.91999 11.89 9.25999 12.09L17.75 17.18C18.08 17.38 18.51 17.14 18.51 16.75V8.41999C18.51 8.23999 18.42 8.07999 18.27 7.98999L14.19 5.53998C13.87 5.34998 13.87 4.87998 14.19 4.67998L16.76 3.12999C16.92 3.02999 17.12 3.02999 17.28 3.12999L21.78 5.82999C21.91 5.93999 22 6.10999 22 6.27999Z"
      fill="#4D4E54"
    />
    <path
      d="M11.29 18.43L7.26001 20.85C7.10001 20.95 6.90002 20.95 6.75002 20.85L2.25002 18.15C2.10002 18.06 2.01001 17.9 2.01001 17.72V6.29001C2.01001 6.11001 2.10002 5.95002 2.25002 5.86002L6.75002 3.16002C6.91002 3.06002 7.11001 3.06002 7.26001 3.16002L16.76 8.86002C16.91 8.95002 17 9.11001 17 9.29001V14.12C17 14.51 16.58 14.75 16.24 14.55L7.75002 9.46002C7.42002 9.26003 6.99002 9.50002 6.99002 9.89002V14.72C6.99002 14.9 7.08001 15.06 7.23001 15.15L11.27 17.58C11.61 17.77 11.61 18.23 11.29 18.43Z"
      fill="#4D4E54"
    />
  </svg>
);
