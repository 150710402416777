import styled from "styled-components";
import { Skeleton, TableCell, TableRow } from "@mui/material";

interface Props {
  skeletonCount: number;
}

const HistoryTableRowSkeleton = ({ skeletonCount }: Props) => (
  <TableRow>
    {Array.from({ length: skeletonCount }).map((_, order) =>
      order === 0 ? (
        <StyledTableCell key={order} component="th" scope="row">
          <div>
            <Skeleton animation="wave" variant="circular" width="40px" height="40px" style={{ flexShrink: 0 }} />
            <Skeleton animation="wave" variant="text" width="100%" />
          </div>
        </StyledTableCell>
      ) : (
        <StyledTableCell key={order}>
          <Skeleton animation="wave" variant="text" />
        </StyledTableCell>
      ),
    )}
  </TableRow>
);

const StyledTableCell = styled(TableCell)`
  padding: 0 12px;
  height: 47px;
  font-family: Mulish;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.tableTitleText};
  border-bottom: 1px solid ${({ theme }) => theme.tableDivider};

  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export default HistoryTableRowSkeleton;
