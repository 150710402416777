import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import VideoCard from "../VideoCard";
import SourceFaceCard from "../SourceFaceCard";
import ManipulateCard from "../ManipulateCard";
import { FaceswapProjectTab } from "../..";
import {
  clearFaceswap,
  getAIServiceByIdServer,
  getAIServiceListServer,
} from "../../../../redux/actions/faceswapActions";

const ReplicateMediaScreen = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [activeFaceTabInRegeneration, setFaceTabInRegeneration] = useState("Video");

  const fetchData = (isLoading?: boolean) => dispatch(getAIServiceListServer({ serviceTypeID: 4, isLoading }));

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => fetchData(false), 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    dispatch(clearFaceswap());

    if (id) dispatch(getAIServiceByIdServer({ id: parseInt(id), isProjectRestored: true }));
  }, [id]);

  return (
    <>
      <SourceFaceCard
        cardTitle="Source"
        activeTab={activeFaceTabInRegeneration}
        setActiveTab={setFaceTabInRegeneration}
        isTextGenerationEnabled={false}
      />
      <ManipulateCard />
      <VideoCard
        type={FaceswapProjectTab["Replicate Media"]}
        generateButtonText="Regenerate video now"
        activeTab={activeFaceTabInRegeneration}
      />
    </>
  );
};

export default ReplicateMediaScreen;
