// import { useEffect } from "react";
import Actors from ".";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
// import { useDarkMode } from "../../hooks/useDarkMode";
// import { ThemeTypes } from "../../types/theme";

// interface DarkModeTypes {
//   theme: ThemeTypes;
//   toggleTheme: () => void;
// }
const ActorPage = () => {
  // const { theme, toggleTheme }: DarkModeTypes = useDarkMode();

  // useEffect(() => {
  //   if (theme === ThemeTypes.dark) {
  //     toggleTheme();
  //   }
  // }, []);

  return (
    <ErrorBoundary>
      <Actors />
    </ErrorBoundary>
  );
};

export default ActorPage;
