import { toast } from "react-toastify";
import { put, select, takeEvery } from "redux-saga/effects";
import {
  CLEAR_GALLERY_IMAGES_SEARCH,
  CLEAR_HISTORY_IMAGES_SEARCH,
  DELETE_GALLERY_IMAGE_SERVER,
  GENERATE_IMAGE_SERVER,
  GET_ALL_GALLERY_IMAGES_SERVER,
  GET_ALL_HISTORY_IMAGES_SERVER,
  UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER,
  DOWNLOAD_ALL_IMAGES_SERVER,
  clearGalleryImages,
  clearHistoryImages,
  getAllGalleryImagesServer,
  getAllHistoryImagesServer,
  searchGalleryImagesLoading,
  searchHistoryImagesLoading,
  updateGeneratedImages,
  updateGeneratedImagesLoading,
  updateHasMoreHistoryImages,
  updateDownloadAllImagesLoading,
  CREATE_GALLERY_IMAGE_SERVER,
  updateHasMoreGalleryImages,
  INPAINT_RENDER_SERVER,
  GET_PROMPT_TO_VIDEO_LIST_SERVER,
  ADD_PROMPT_TO_VIDEO_SERVER,
  getPromptToVideoByIdServer,
  clearPromptToVideoList,
  getPromptToVideoListServer,
  GET_PROMPT_TO_VIDEO_BY_ID_SERVER,
  updateHasMorePromptToVideoList,
  GET_ALL_GALLERY_VIDEOS_SERVER,
  DELETE_GALLERY_VIDEO_SERVER,
  CLEAR_GALLERY_VIDEOS_SEARCH,
  updateHasMoreGalleryVideos,
  searchGalleryVideosLoading,
  clearGalleryVideos,
  getAllGalleryVideosServer,
  CREATE_GALLERY_VIDEO_SERVER,
  UPSCALE_IMAGE_SERVER,
  UPSCALE_VIDEO_SERVER,
  SWAP_FACE_VIDEO_SERVER,
  updateFaceSwapIsLoading,
  GET_SWAPPED_FACE_VIDEO_SERVER,
  SYNC_LIPS_VIDEO_SERVER,
  GET_LIPS_SYNCED_VIDEO_SERVER,
  GET_AI_IMAGES_GROUPED_RECORDS_SERVER,
  updateHasMoreAIImagesGroupedRecords,
  DELETE_AI_IMAGES_GROUPED_RECORD_SERVER,
} from "../actions/imagesActions";
import { ImagesModules } from "../../types/image";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { EnlargedPicture } from "../../types/enlargedPicture";
import { Popups, updatePopup } from "../actions/popupsActions";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import {
  getAIImagesGroupedRecords,
  getGalleryImages,
  getGalleryVideos,
  getHistoryImages,
  getPromptToVideoList,
} from "../reducers/imagesReducer";

const imagesSaga = [
  takeEvery(`${GENERATE_IMAGE_SERVER}`, handleGenerateImages),
  takeEvery(`${GENERATE_IMAGE_SERVER}_SUCCESS`, handleGenerateImagesSuccess),
  takeEvery(`${GENERATE_IMAGE_SERVER}_FAIL`, handleGenerateImagesFail),

  takeEvery(`${GET_ALL_HISTORY_IMAGES_SERVER}`, handleAllHistoryImagesServer),
  takeEvery(`${GET_ALL_HISTORY_IMAGES_SERVER}_SUCCESS`, handleAllHistoryImagesServerSuccess),
  takeEvery(`${GET_ALL_HISTORY_IMAGES_SERVER}_FAIL`, handleAllHistoryImagesServerFail),

  takeEvery(`${GET_PROMPT_TO_VIDEO_LIST_SERVER}`, handleGetPromptToVideoListServer),
  takeEvery(`${GET_PROMPT_TO_VIDEO_LIST_SERVER}_SUCCESS`, handleGetPromptToVideoListServerSuccess),
  takeEvery(`${GET_PROMPT_TO_VIDEO_LIST_SERVER}_FAIL`, handleGetPromptToVideoListServerFail),

  takeEvery(`${ADD_PROMPT_TO_VIDEO_SERVER}`, handleAddPromptToVideo),
  takeEvery(`${ADD_PROMPT_TO_VIDEO_SERVER}_SUCCESS`, handleAddPromptToVideoSuccess),
  takeEvery(`${ADD_PROMPT_TO_VIDEO_SERVER}_FAIL`, handleAddPromptToVideoFail),

  takeEvery(`${GET_PROMPT_TO_VIDEO_BY_ID_SERVER}_SUCCESS`, handleGetPromptToVideoByIdServerSuccess),
  takeEvery(`${GET_PROMPT_TO_VIDEO_BY_ID_SERVER}_FAIL`, handleGetPromptToVideoByIdServerFail),

  takeEvery(`${UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER}`, handleUploadImageToVisualGalleryServer),
  takeEvery(`${UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER}_SUCCESS`, handleUploadImageToVisualGalleryServerSuccess),
  takeEvery(`${UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER}_FAIL`, handleUploadImageToVisualGalleryServerFail),

  takeEvery(`${GET_ALL_GALLERY_IMAGES_SERVER}`, handleAllGalleryImagesServer),
  takeEvery(`${GET_ALL_GALLERY_IMAGES_SERVER}_SUCCESS`, handleAllGalleryImagesServerSuccess),
  takeEvery(`${GET_ALL_GALLERY_IMAGES_SERVER}_FAIL`, handleAllGalleryImagesServerFail),

  takeEvery(`${DELETE_GALLERY_IMAGE_SERVER}_FAIL`, handleDeleteGalleryImageServerFail),
  takeEvery(`${DELETE_GALLERY_IMAGE_SERVER}_SUCCESS`, handleDeleteGalleryImageServerSuccess),

  takeEvery(`${GET_AI_IMAGES_GROUPED_RECORDS_SERVER}`, handleAIImagesGroupedRecordsServer),
  takeEvery(`${GET_AI_IMAGES_GROUPED_RECORDS_SERVER}_SUCCESS`, handleAIImagesGroupedRecordsServerSuccess),
  takeEvery(`${GET_AI_IMAGES_GROUPED_RECORDS_SERVER}_FAIL`, handleAIImagesGroupedRecordsServerFail),

  takeEvery(`${DELETE_AI_IMAGES_GROUPED_RECORD_SERVER}_FAIL`, handleDeleteAIImagesGroupedRecordServerFail),
  takeEvery(`${DELETE_AI_IMAGES_GROUPED_RECORD_SERVER}_SUCCESS`, handleDeleteAIImagesGroupedRecordServerSuccess),

  takeEvery(`${GET_ALL_GALLERY_VIDEOS_SERVER}`, handleAllGalleryVideosServer),
  takeEvery(`${GET_ALL_GALLERY_VIDEOS_SERVER}_SUCCESS`, handleAllGalleryVideosServerSuccess),
  takeEvery(`${GET_ALL_GALLERY_VIDEOS_SERVER}_FAIL`, handleAllGalleryVideosServerFail),

  takeEvery(`${DELETE_GALLERY_VIDEO_SERVER}_FAIL`, handleDeleteGalleryVideoServerFail),
  takeEvery(`${DELETE_GALLERY_VIDEO_SERVER}_SUCCESS`, handleDeleteGalleryVideoServerSuccess),

  takeEvery(CLEAR_HISTORY_IMAGES_SEARCH, handleClearHistoryImagesSearch),
  takeEvery(CLEAR_GALLERY_IMAGES_SEARCH, handleClearGalleryImagesSearch),
  takeEvery(CLEAR_GALLERY_VIDEOS_SEARCH, handleClearGalleryVideosSearch),

  takeEvery(`${DOWNLOAD_ALL_IMAGES_SERVER}`, handleDownloadAllImages),
  takeEvery(`${DOWNLOAD_ALL_IMAGES_SERVER}_SUCCESS`, handleDownloadAllImagesSuccess),
  takeEvery(`${DOWNLOAD_ALL_IMAGES_SERVER}_FAIL`, handleDownloadAllImagesFail),

  takeEvery(CREATE_GALLERY_IMAGE_SERVER, handleCreateGalleryImageServer),
  takeEvery(`${CREATE_GALLERY_IMAGE_SERVER}_SUCCESS`, handleCreateGalleryImageServerSuccess),
  takeEvery(`${CREATE_GALLERY_IMAGE_SERVER}_FAIL`, handleCreateGalleryImageServerFail),

  takeEvery(CREATE_GALLERY_VIDEO_SERVER, handleCreateGalleryVideoServer),
  takeEvery(`${CREATE_GALLERY_VIDEO_SERVER}_SUCCESS`, handleCreateGalleryVideoServerSuccess),
  takeEvery(`${CREATE_GALLERY_VIDEO_SERVER}_FAIL`, handleCreateGalleryVideoServerFail),

  takeEvery(INPAINT_RENDER_SERVER, handleRenderInpaintServer),
  takeEvery(`${INPAINT_RENDER_SERVER}_SUCCESS`, handleRenderInpaintServerSuccess),
  takeEvery(`${INPAINT_RENDER_SERVER}_FAIL`, handleRenderInpaintServerFail),

  takeEvery(UPSCALE_IMAGE_SERVER, handleUpscaleImageServer),
  takeEvery(`${UPSCALE_IMAGE_SERVER}_SUCCESS`, handleUpscaleImageServerSuccess),
  takeEvery(`${UPSCALE_IMAGE_SERVER}_FAIL`, handleUpscaleImageServerFail),

  takeEvery(UPSCALE_VIDEO_SERVER, handleUpscaleVideoServer),
  takeEvery(`${UPSCALE_VIDEO_SERVER}_SUCCESS`, handleUpscaleVideoServerSuccess),
  takeEvery(`${UPSCALE_VIDEO_SERVER}_FAIL`, handleUpscaleVideoServerFail),

  takeEvery(SWAP_FACE_VIDEO_SERVER, handleSwapFaceVideoServer),
  takeEvery(`${SWAP_FACE_VIDEO_SERVER}_SUCCESS`, handleSwapFaceVideoSuccess),
  takeEvery(`${SWAP_FACE_VIDEO_SERVER}_FAIL`, handleSwapFaceVideoFail),

  takeEvery(`${GET_SWAPPED_FACE_VIDEO_SERVER}_SUCCESS`, handleGetSwappedFaceVideoSuccess),
  takeEvery(`${GET_SWAPPED_FACE_VIDEO_SERVER}_FAIL`, handleGetSwappedFaceVideoFail),

  takeEvery(SYNC_LIPS_VIDEO_SERVER, handleSyncLipsVideoServer),
  // takeEvery(`${SYNC_LIPS_VIDEO_SERVER}_SUCCESS`, ),
  takeEvery(`${SYNC_LIPS_VIDEO_SERVER}_FAIL`, handleSyncLipsVideoFail),

  takeEvery(`${GET_LIPS_SYNCED_VIDEO_SERVER}_SUCCESS`, handleGetSyncedLipsVideoSuccess),
  takeEvery(`${GET_LIPS_SYNCED_VIDEO_SERVER}_FAIL`, handleGetSyncedLipsVideoFail),
];

function* handleGenerateImages() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedImages, isLoading: true }));
}

function* handleGenerateImagesSuccess(action: any) {
  const data = action.payload.data;

  yield put(updateGeneratedImages({ data }));
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedImages, isLoading: false }));
  yield put(clearHistoryImages());
  yield put(
    getAllHistoryImagesServer({
      keyword: "",
      pageNumber: 1,
    }),
  );
}

function* handleGenerateImagesFail(action: any) {
  if (action?.error?.response?.data?.message === "AI Images limit reached") {
    const prefilled: NotEnoughCredits = {
      title: "AI Images limit reached",
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
    };

    yield put(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  } else {
    toast.error(action?.error?.response?.data?.message || "An error occured while generating images");
  }

  yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedImages, isLoading: false }));
}

function* handleAllHistoryImagesServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.historyImages, isLoading: true }));
}

function* handleAllHistoryImagesServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_HISTORY_IMAGES.toast);
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.historyImages, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_HISTORY_IMAGES,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleAllHistoryImagesServerSuccess(action: any) {
  const allImages = getHistoryImages(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allImages.length >= totalLength) yield put(updateHasMoreHistoryImages({ hasMore: false }));

  yield put(updateGeneratedImagesLoading({ module: ImagesModules.historyImages, isLoading: false }));
  yield put(searchHistoryImagesLoading(false));
}

function* handleUploadImageToVisualGalleryServer(action: any) {
  const { module = ImagesModules.uploadImageToVisualGallery } = action.payload;

  yield put(updateGeneratedImagesLoading({ module, isLoading: true }));
}

function* handleUploadImageToVisualGalleryServerFail(action: any) {
  const { module = ImagesModules.uploadImageToVisualGallery } = action.meta.previousAction.payload;

  yield toast.error("There was an error uploading");
  yield put(updateGeneratedImagesLoading({ module, isLoading: false }));
}

function* handleUploadImageToVisualGalleryServerSuccess(action: any) {
  const { module = ImagesModules.uploadImageToVisualGallery } = action.meta.previousAction.payload;

  yield toast.success("The image was uploaded successfully");
  yield put(clearGalleryImages());
  yield put(
    getAllGalleryImagesServer({
      keyword: "",
    }),
  );
  yield put(updateGeneratedImagesLoading({ module, isLoading: false }));
}

function* handleClearHistoryImagesSearch() {
  yield put(clearHistoryImages());
  yield put(
    getAllHistoryImagesServer({
      keyword: "",
      pageNumber: 1,
    }),
  );
}

function* handleAllGalleryImagesServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.galleryImages, isLoading: true }));
}

function* handleAllGalleryImagesServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_GALLERY_IMAGES.toast);
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.galleryImages, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_GALLERY_IMAGES,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleAllGalleryImagesServerSuccess(action: any) {
  const allGalleryImages = getGalleryImages(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allGalleryImages.length >= totalLength) yield put(updateHasMoreGalleryImages({ hasMore: false }));

  yield put(updateGeneratedImagesLoading({ module: ImagesModules.galleryImages, isLoading: false }));
  yield put(searchGalleryImagesLoading(false));
}

function* handleClearGalleryImagesSearch() {
  yield put(clearGalleryImages());
  yield put(
    getAllGalleryImagesServer({
      keyword: "",
    }),
  );
}

function* handleDeleteGalleryImageServerFail() {
  yield toast.error(`The image could not be deleted`);
}

function* handleDeleteGalleryImageServerSuccess() {
  yield toast.success(`The image has been deleted`);
}

function* handleDownloadAllImages() {
  yield put(updateDownloadAllImagesLoading({ module: ImagesModules.downloadAllImages, isLoading: true }));
}

function* handleDownloadAllImagesSuccess(action: any) {
  const data = action.payload.data;
  toast.success("Download success");
  const url = process.env.REACT_APP_API_LINK?.replace("/api", "") + data.data;
  window.open(url, "_blank");
  yield put(updateDownloadAllImagesLoading({ module: ImagesModules.downloadAllImages, isLoading: false }));
}

function* handleDownloadAllImagesFail() {
  toast.error("Error occured");
  yield put(updateDownloadAllImagesLoading({ module: ImagesModules.downloadAllImages, isLoading: false }));
}

function* handleCreateGalleryImageServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.imageGallery, isLoading: true }));
}

function* handleCreateGalleryImageServerSuccess() {
  toast.success("Image added to visual gallery successfully");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.imageGallery, isLoading: false }));
}

function* handleCreateGalleryImageServerFail() {
  toast.error("An error occured while adding an image to the visual gallery");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.imageGallery, isLoading: false }));
}

function* handleRenderInpaintServer() {
  yield put(updateDownloadAllImagesLoading({ module: ImagesModules.inPaint, isLoading: true }));
}

function* handleRenderInpaintServerSuccess(action: any) {
  const image = action.payload.data.data[0];
  const isEdit = action.meta.previousAction.payload.isEdit;
  toast.success("Rendering inpaint completed");
  yield put(updatePopup({ popup: Popups.aIImageRemoveAreaPopup, status: false, prefilled: {} }));

  if (!isEdit) {
    const prefilled: EnlargedPicture = {
      pic: image,
      isView: false,
    };

    yield put(updatePopup({ popup: Popups.enlargedImagePopup, status: true, prefilled }));
  }

  yield put(updateDownloadAllImagesLoading({ module: ImagesModules.inPaint, isLoading: false }));
}

function* handleRenderInpaintServerFail() {
  toast.error("Error occured while rendering inpaint result, contact support for more details");
  yield put(updateDownloadAllImagesLoading({ module: ImagesModules.inPaint, isLoading: false }));
  yield put(updatePopup({ popup: Popups.aIImageRemoveAreaPopup, status: false, prefilled: {} }));
}

function* handleGetPromptToVideoListServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.promptToVideo, isLoading: true }));
}

function* handleGetPromptToVideoListServerSuccess(action: any) {
  const allVideos = getPromptToVideoList(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allVideos.length >= totalLength) yield put(updateHasMorePromptToVideoList({ hasMore: false }));

  yield put(updateGeneratedImagesLoading({ module: ImagesModules.promptToVideo, isLoading: false }));
}

function* handleGetPromptToVideoListServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_PROMPT_TO_VIDEO_LIST.toast);
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.promptToVideo, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_PROMPT_TO_VIDEO_LIST,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleAddPromptToVideo() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedVideo, isLoading: true }));
}

function* handleAddPromptToVideoSuccess(action: any) {
  const { prompt2VideoProjectId: id } = action.payload.data.data;

  yield put(getPromptToVideoByIdServer({ id }));
}

function* handleAddPromptToVideoFail(action: any) {
  toast.error(action?.error?.response?.data?.message || "An error during the generation of prompts in the video");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedVideo, isLoading: false }));
}

function* handleGetPromptToVideoByIdServerSuccess(action: any) {
  const { status } = action.payload.data.data;

  if (status === 3) {
    yield put(clearPromptToVideoList());
    yield put(
      getPromptToVideoListServer({
        keyword: "",
        pageNumber: 1,
      }),
    );
    yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedVideo, isLoading: false }));
  } else if (status === 4) {
    toast.error("Failed to generate video. Contact support!");
    yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedVideo, isLoading: false }));
  }
}

function* handleGetPromptToVideoByIdServerFail() {
  toast.error("Failed to get generated video");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.generatedVideo, isLoading: false }));
}

function* handleAllGalleryVideosServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.galleryVideos, isLoading: true }));
}

function* handleAllGalleryVideosServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_GALLERY_VIDEOS.toast);
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.galleryVideos, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_GALLERY_VIDEOS,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleAllGalleryVideosServerSuccess(action: any) {
  const allGalleryVideos = getGalleryVideos(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allGalleryVideos.length >= totalLength) yield put(updateHasMoreGalleryVideos({ hasMore: false }));

  yield put(updateGeneratedImagesLoading({ module: ImagesModules.galleryVideos, isLoading: false }));
  yield put(searchGalleryVideosLoading(false));
}

function* handleClearGalleryVideosSearch() {
  yield put(clearGalleryVideos());
  yield put(
    getAllGalleryVideosServer({
      keyword: "",
    }),
  );
}

function* handleDeleteGalleryVideoServerFail() {
  yield toast.error(`The video could not be deleted`);
}

function* handleDeleteGalleryVideoServerSuccess() {
  yield toast.success(`The video has been deleted`);
}

function* handleCreateGalleryVideoServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.videoGallery, isLoading: true }));
}

function* handleCreateGalleryVideoServerSuccess() {
  toast.success("Video added to video gallery successfully");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.videoGallery, isLoading: false }));
}

function* handleCreateGalleryVideoServerFail() {
  toast.error("An error occured while adding an video to the video gallery");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.videoGallery, isLoading: false }));
}

function* handleUpscaleImageServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.upscaleImage, isLoading: true }));
}

function* handleUpscaleImageServerSuccess() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.upscaleImage, isLoading: false }));
}

function* handleUpscaleImageServerFail() {
  toast.error("Failed to upscale image");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.upscaleImage, isLoading: false }));
}

function* handleUpscaleVideoServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.upscaleVideo, isLoading: true }));
}

function* handleUpscaleVideoServerSuccess() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.upscaleVideo, isLoading: false }));
}

function* handleUpscaleVideoServerFail() {
  toast.error("Failed to upscale video");
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.upscaleVideo, isLoading: false }));
}

function* handleSwapFaceVideoServer() {
  //
}

function* handleSwapFaceVideoSuccess() {
  yield put(updateFaceSwapIsLoading(true));
}

function* handleSwapFaceVideoFail() {
  yield toast.error("Failed to complete the face swap");
}

function* handleGetSwappedFaceVideoSuccess(action: any) {
  const { status } = action.payload.data.data;

  if (status === 3) {
    yield put(updateFaceSwapIsLoading(false));
  } else if (status === 4) {
    toast.error("Failed to generate video. Contact support!");
    yield put(updateFaceSwapIsLoading(false));
  }
}

function* handleGetSwappedFaceVideoFail() {
  yield put(updateFaceSwapIsLoading(false));
  toast.error("Failed to generate video. Contact support!");
}

function* handleSyncLipsVideoServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.lipsync, isLoading: true }));
}

function* handleSyncLipsVideoFail() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.lipsync, isLoading: false }));
  toast.error("Failed to initiate lips syncing. Contact support!");
}

function* handleGetSyncedLipsVideoSuccess(action: any) {
  const { status } = action.payload.data.data;

  if (status === 3) {
    yield put(updateGeneratedImagesLoading({ module: ImagesModules.lipsync, isLoading: false }));
  } else if (status === 4) {
    toast.error("Failed to generate video. Contact support!");
    yield put(updateGeneratedImagesLoading({ module: ImagesModules.lipsync, isLoading: false }));
  }
}

function* handleGetSyncedLipsVideoFail() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.lipsync, isLoading: false }));
  toast.error("Failed to generate video. Contact support!");
}

function* handleAIImagesGroupedRecordsServer() {
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.aiImagesGroupedRecords, isLoading: true }));
}

function* handleAIImagesGroupedRecordsServerSuccess(action: any) {
  const allAIImagesGroupedRecords = getAIImagesGroupedRecords(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allAIImagesGroupedRecords.length >= totalLength)
    yield put(updateHasMoreAIImagesGroupedRecords({ hasMore: false }));

  yield put(updateGeneratedImagesLoading({ module: ImagesModules.aiImagesGroupedRecords, isLoading: false }));
}

function* handleAIImagesGroupedRecordsServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_AI_IMAGES.toast);
  yield put(updateGeneratedImagesLoading({ module: ImagesModules.aiImagesGroupedRecords, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_AI_IMAGES,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleDeleteAIImagesGroupedRecordServerFail() {
  yield toast.error(`The image could not be deleted`);
}

function* handleDeleteAIImagesGroupedRecordServerSuccess() {
  yield toast.success(`The image has been deleted`);
}

export default imagesSaga;
