import styled from "styled-components";
import AudioPlayerCard from "../../../components/AudioPlayerCard/AudioPlayerCard";
import { useState } from "react";
import { formatNumberToDuration } from "../../../lib/formatDuration";

interface Props {
  url: string;
  delete?: () => void;
}

const AudioPlayerWidget = ({ url, delete: handleDelete }: Props) => {
  const [duration, setDuration] = useState(0);

  return (
    <Wrapper>
      <p>Audio</p>
      <AudioPlayerCard isLongWave url={url} handleDelete={handleDelete} setDuration={setDuration} />
      <p>{formatNumberToDuration(duration)}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  margin-top: 24px;
  padding: 8px 35px;
  border-radius: 32px;
  background: #1f2024;

  p {
    margin: 0 8px;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #e0dcdc;

    :last-child {
      align-self: flex-end;
    }
  }

  & > div {
    width: 100%;
    background: #292d32;
  }
`;

export default AudioPlayerWidget;
