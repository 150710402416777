export enum HomeTabs {
  AIVoices = "AIVoices",
  AIHumans = "AIHumans",
  AIImages = "AIImages",
}

export const TrainingTabs = [
  { value: "AI Voices", title: "AI Voices" },
  { value: "AI Humans", title: "AI Humans" },
  { value: "AI Images", title: "AI Images" },
  { value: "Video Translate", title: "Video Translate" },
];

export const AIVoicesTabs = [
  { value: "AI Voices", title: "AI Voices" },
  { value: "AI Humans", title: "AI Humans" },
];

export enum AIHumansStatus {
  All = 0,
  Draft = 1,
  InProgress = 2,
  Completed = 3,
  Failure = 4,
}

export enum ProjectsDisplayMode {
  aiHuman = "ai-humans",
  aiVoices = "actors",
  aiImages = "ai-images",
  aiVideos = "ai-videos",
}

export const projectDisplayModeTabs = [
  {
    value: ProjectsDisplayMode.aiHuman,
    title: "AI Humans",
  },
  {
    value: ProjectsDisplayMode.aiVoices,
    title: "AI Voices",
  },
  {
    value: ProjectsDisplayMode.aiImages,
    title: "AI Images",
  },
  {
    value: ProjectsDisplayMode.aiVideos,
    title: "AI Videos",
  },
];

export const aiHumansStatusOption = [
  {
    value: AIHumansStatus.All,
    title: "All",
  },
  {
    value: AIHumansStatus.Completed,
    title: "Completed",
  },
  {
    value: AIHumansStatus.Draft,
    title: "Draft",
  },
];

export const options = {
  data: [
    {
      id: "1",
      label: "All",
      value: AIHumansStatus.All,
    },
    {
      id: "2",
      label: "Completed",
      value: AIHumansStatus.Completed,
    },
    {
      id: "3",
      label: "Draft",
      value: AIHumansStatus.Draft,
    },
  ],
};
