export const faceswapSuggestMedia = [
  {
    id: 1,
    url: "/images/faceswap/image-1.jpg",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Images/alex-robinson-AGr13YPOPqw-unsplash.jpg",
    alt: "Alex Robinson",
    type: "Image",
  },
  {
    id: 2,
    url: "/images/faceswap/image-2.jpg",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Images/christophe-dusabe-x-0a7bB0Ees-unsplash.jpg",
    alt: "Christophe Dusabe",
    type: "Image",
  },
  {
    id: 3,
    url: "/images/faceswap/image-3.jpg",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Images/christopher-campbell-rDEOVtE7vOs-unsplash.jpg",
    alt: "Christopher Campbell",
    type: "Image",
  },
  {
    id: 4,
    url: "/images/faceswap/image-4.jpg",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Images/lance-reis-BlRYsJzrxgQ-unsplash.jpg",
    alt: "Lance Reis #1",
    type: "Image",
  },
  {
    id: 5,
    url: "/images/faceswap/image-5.jpg",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Images/lance-reis-dYpWTMseNzw-unsplash.jpg",
    alt: "Lance Reis #2",
    type: "Image",
  },
  {
    id: 6,
    url: "/images/faceswap/image-6.jpg",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Images/ludovic-migneault-4uj3iZ5m084-unsplash.jpg",
    alt: "Ludovic Migneault",
    type: "Image",
  },
  {
    id: 7,
    url: "/images/faceswap/image-7.jpg",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Images/thanh-duc-phan-LXZPsD6yVYI-unsplash.jpg",
    alt: "Thanh duc Phan",
    type: "Image",
  },
  {
    id: 8,
    url: "/images/faceswap/video-1.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Pexels_videos/4115317-hd_1366_720_50fps.mp4",
    type: "Video",
  },
  {
    id: 9,
    url: "/images/faceswap/video-2.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Pexels_videos/5516426-hd_1920_1080_30fps.mp4",
    type: "Video",
  },
  {
    id: 10,
    url: "/images/faceswap/video-3.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Pexels_videos/5781313-hd_1280_720_50fps.mp4",
    type: "Video",
  },
  {
    id: 11,
    url: "/images/faceswap/video-4.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Pexels_videos/6706926-hd_1920_1080_25fps.mp4",
    type: "Video",
  },
  {
    id: 12,
    url: "/images/faceswap/video-5.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Pexels_videos/6739113-hd_1920_1080_25fps.mp4",
    type: "Video",
  },
  {
    id: 13,
    url: "/images/faceswap/video-6.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Pexels_videos/6824127-hd_1280_720_30fps.mp4",
    type: "Video",
  },
  {
    id: 14,
    url: "/images/faceswap/video-7.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Pexels_videos/9464484-hd_2048_1080_25fps.mp4",
    type: "Video",
  },
  {
    id: 15,
    url: "/images/faceswap/video-8.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Transform%20Samples/d3.mp4",
    type: "TransformSamples",
  },
  {
    id: 16,
    url: "/images/faceswap/video-9.mp4",
    outputUrl:
      "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys_live/static/Transform%20Samples/d9%20(1).mp4",
    type: "TransformSamples",
  },
];
