import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  GetProjectListServerProps,
  clearProjectList,
  getProjectListRefreshServer,
  getProjectListServer,
} from "../redux/actions/projectAction";
import {
  getAIImagesGroupedRecords,
  getAIImagesGroupedRecordsLoading,
  getAIImagesGroupedRecordsSortLoading,
  getCurrentPageAIImagesGroupedRecords,
  getCurrentPageGalleryVideos,
  getGalleryVideoSortLoading,
  getGalleryVideos,
  getGalleryVideosLoading,
  getHasMoreAIImagesGroupedRecords,
  getHasMoreGalleryVideos,
} from "../redux/reducers/imagesReducer";
import {
  getCurrentPageProjects,
  getHasMoreProjects,
  getIsDeleteLoading,
  getIsSortLoading,
  getIsStatusLoading,
  getProjectList,
  getProjectListLoading,
} from "../redux/reducers/projectReducer";
import { AIHumansStatus, ProjectsDisplayMode } from "../modules/MyStudio";
import {
  clearAIImagesGroupedRecords,
  clearGalleryVideos,
  getAIImagesGroupedRecordsServer,
  getAllGalleryVideosServer,
} from "../redux/actions/imagesActions";
import { Project } from "../types/project";
import { AIImagesGroupedRecords, GalleryImage, GalleryVideo } from "../types/image";

interface FeatureList {
  [key: string]: {
    list: Project[] | GalleryImage[] | GalleryVideo[] | AIImagesGroupedRecords[];
    isLoading: boolean;
    hasMore: boolean;
    currentPage: number;
    isSort?: boolean;
  };
}

interface Props {
  projectDisplayModeTabs: {
    value: ProjectsDisplayMode;
    title: string;
  }[];
}

export const useMyStudio = ({ projectDisplayModeTabs }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const projectList = useSelector(getProjectList);
  const projectListLoading = useSelector(getProjectListLoading);
  const hasMore = useSelector(getHasMoreProjects);
  const currentPage = useSelector(getCurrentPageProjects);
  const isStatusLoading = useSelector(getIsStatusLoading);
  const isSortLoading = useSelector(getIsSortLoading);

  const aiImages = useSelector(getAIImagesGroupedRecords);
  const aiImagesLoading = useSelector(getAIImagesGroupedRecordsLoading);
  const aiImagesHasMore = useSelector(getHasMoreAIImagesGroupedRecords);
  const aiImagesCurrentPage = useSelector(getCurrentPageAIImagesGroupedRecords);
  const aiImagesSortLoading = useSelector(getAIImagesGroupedRecordsSortLoading);

  const galleryVideos = useSelector(getGalleryVideos);
  const galleryVideosLoading = useSelector(getGalleryVideosLoading);
  const galleryVideosHasMore = useSelector(getHasMoreGalleryVideos);
  const galleryVideosCurrentPage = useSelector(getCurrentPageGalleryVideos);
  const gallerySortLoading = useSelector(getGalleryVideoSortLoading);

  const [currentSelectValue, setCurrentSelectValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<AIHumansStatus | null>(null);
  const [activeDisplayMode, setActiveDisplayMode] = useState(projectDisplayModeTabs[0].value);
  const [activeProject, setActiveProject] = useState<number | null>();
  const [activeStatus, setActiveStatus] = useState<AIHumansStatus | null>(null);
  const [activeProjectTypeId, setActiveProjectTypeId] = useState<number>();
  const [sortByDesc, setSortByDesc] = useState<{ [key: string]: boolean }>({
    ["actors"]: true,
    ["ai-images"]: true,
    ["ai-videos"]: true,
  });

  const activeProjectType = {
    [ProjectsDisplayMode.aiVoices]: 1,
    [ProjectsDisplayMode.aiHuman]: 2,
    [ProjectsDisplayMode.aiImages]: 3,
    [ProjectsDisplayMode.aiVideos]: 4,
  };

  const featuresList: FeatureList = {
    [ProjectsDisplayMode.aiVoices]: {
      list: projectList,
      isLoading: projectListLoading,
      hasMore,
      currentPage,
      isSort: isSortLoading,
    },
    [ProjectsDisplayMode.aiHuman]: {
      list: projectList,
      isLoading: projectListLoading,
      hasMore,
      currentPage,
    },
    [ProjectsDisplayMode.aiImages]: {
      list: aiImages,
      isLoading: aiImagesLoading,
      hasMore: aiImagesHasMore,
      currentPage: aiImagesCurrentPage,
      isSort: aiImagesSortLoading,
    },
    [ProjectsDisplayMode.aiVideos]: {
      list: galleryVideos,
      isLoading: galleryVideosLoading,
      hasMore: galleryVideosHasMore,
      currentPage: galleryVideosCurrentPage,
      isSort: gallerySortLoading,
    },
  };

  const handleActiveStatus = (status: AIHumansStatus) => {
    setActiveStatus(status);
  };

  const handleActiveDisplayMode = (tab: ProjectsDisplayMode) => {
    setActiveDisplayMode(tab);
    const params = new URLSearchParams(location.search);
    params.set("filter", tab);

    navigate(`?${params.toString()}`);
    setActiveProjectTypeId(activeProjectType[tab]);
  };

  const onSelectedStatusChanges = (newValue: AIHumansStatus) => {
    setSelectedStatus(newValue);
  };

  const onSortByDescChanges = (newValue: boolean, type: ProjectsDisplayMode) => {
    setSortByDesc({ ...sortByDesc, [type]: newValue });
  };

  const handleActiveProject = (e: any, id?: number) => {
    e.preventDefault();

    setActiveProject(id);
  };

  const onChangeCurrentValue = (val: string) => {
    setCurrentSelectValue(val);
  };

  const getProjectListFunc = ({ currentPage, activeProjectTypeId, sortByDesc }: any) => {
    let data: GetProjectListServerProps = {
      keyword: "",
      pageNumber: currentPage + 1,
      pageSize: 40,
      projectTypeId: activeProjectTypeId,
    };

    if (activeProjectTypeId === activeProjectType[ProjectsDisplayMode.aiHuman]) {
      data = {
        ...data,
        status: selectedStatus,
        isStatusLoading: true,
      };
    }

    if (activeProjectTypeId === activeProjectType[ProjectsDisplayMode.aiVoices]) {
      data = {
        ...data,
        sortByDesc,
        isSortLoading: true,
      };
    }

    dispatch(getProjectListServer(data));
  };

  const getAIImagesFunc = (aiImagesCurrentPage: number, sortByDesc: boolean) => {
    dispatch(
      getAIImagesGroupedRecordsServer({
        pageNumber: aiImagesCurrentPage + 1,
        pageSize: 40,
        sortByDesc,
        isSortLoading: true,
      }),
    );
  };

  const getGalleryVideosFunc = (galleryVideosCurrentPage: number, sortByDesc: boolean) => {
    dispatch(
      getAllGalleryVideosServer({
        keyword: "",
        pageNumber: galleryVideosCurrentPage + 1,
        pageSize: 40,
        sortByDesc,
        isSortLoading: true,
      }),
    );
  };

  useEffect(() => {
    if (activeDisplayMode === ProjectsDisplayMode.aiVoices || activeDisplayMode === ProjectsDisplayMode.aiHuman) {
      dispatch(clearProjectList());

      if (activeProjectTypeId) {
        getProjectListFunc({ currentPage: 0, activeProjectTypeId, sortByDesc: sortByDesc[activeDisplayMode] });
      }
    } else if (activeDisplayMode === ProjectsDisplayMode.aiImages) {
      dispatch(clearAIImagesGroupedRecords());

      getAIImagesFunc(0, sortByDesc[activeDisplayMode]);
    } else {
      dispatch(clearGalleryVideos());

      getGalleryVideosFunc(0, sortByDesc[activeDisplayMode]);
    }
  }, [activeDisplayMode, selectedStatus, activeProjectTypeId, sortByDesc]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (activeDisplayMode === ProjectsDisplayMode.aiHuman && selectedStatus === AIHumansStatus.All) {
      intervalId = setInterval(() => dispatch(getProjectListRefreshServer()), 10000);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [activeDisplayMode, selectedStatus]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterValue = params.get("filter") || "";
    setActiveDisplayMode(filterValue as ProjectsDisplayMode);
    setActiveProjectTypeId(activeProjectType[filterValue as ProjectsDisplayMode]);

    if (!filterValue) {
      const params = new URLSearchParams(location.search);
      params.set("filter", "ai-humans");
      navigate(`?${params.toString()}`);
    }
  }, [location]);

  return {
    sortByDesc,
    featuresList: featuresList[activeDisplayMode] ?? featuresList[ProjectsDisplayMode.aiHuman],
    isStatusLoading,
    currentSelectValue,
    activeProject,
    selectedStatus,
    activeStatus,
    activeProjectTypeId,
    handleActiveStatus,
    onSelectedStatusChanges,
    onSortByDescChanges,
    handleActiveProject,
    onChangeCurrentValue,
    getProjectListFunc,
    getAIImagesFunc,
    getGalleryVideosFunc,
    setActiveProject,
    handleActiveDisplayMode,
    activeDisplayMode,
  };
};
