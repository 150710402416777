import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { MenuIcon } from "../../../components/Icons/MenuIcon";
import {
  DownloadIcon,
  RenameAssetIcon,
  ShareAssetIcon,
  SortTableIcon,
  TrashIcon,
} from "../../../components/Icons/Icons";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";

export enum AssetType {
  voiceOver = "Voice over",
  image = "Image",
  video = "Video",
}

const assetsTypesImages = {
  [AssetType.image]: "/images/Suitcase.png",
  [AssetType.video]: "/images/Video.png",
  [AssetType.voiceOver]: "/images/TextBubble.png",
};

export interface Asset {
  id: string;
  name: string;
  type: AssetType;
  duration?: string;
}

interface Props {
  assets: Asset[];
}

const AssetsTable = ({ assets }: Props) => {
  const [openMenu, setOpenMenu] = useState<string[]>([]);

  const theme = useTheme();

  const handleOpenMenu = (id: string) => {
    setOpenMenu((items) => [...items, id]);
  };

  const handleCloseMenu = (id: string) => {
    setOpenMenu((items) => items.filter((item) => item !== id));
  };

  return (
    <Card>
      <Table>
        <TableHeader>
          <TableHeaderCell>
            <HeaderText>Name</HeaderText>
            <SortIconWrapper>
              <SortTableIcon />
            </SortIconWrapper>
          </TableHeaderCell>
          <TableHeaderCell>
            <HeaderText>Type</HeaderText>
            <SortIconWrapper>
              <SortTableIcon />
            </SortIconWrapper>
          </TableHeaderCell>
          <TableHeaderCell>
            <HeaderText>Duration</HeaderText>
            <SortIconWrapper>
              <SortTableIcon />
            </SortIconWrapper>
          </TableHeaderCell>
          <TableHeaderCell style={{ width: "44px" }} />
        </TableHeader>
        <TableBody>
          {assets.map((asset) => (
            <TableRow key={asset.id}>
              <TableCell>
                <AssetTypeImage src={assetsTypesImages[asset.type]} />
                <TableText>{asset.name}</TableText>
              </TableCell>
              <TableCell>
                <TableText>{asset.type}</TableText>
              </TableCell>
              <TableCell>
                <TableText>{asset.duration || "—"}</TableText>
              </TableCell>
              <TableCell>
                <ActionMenu
                  trigger={
                    <IconButton
                      iconButtonTheme={IconButtonThemes.Transparent}
                      onClick={() => handleOpenMenu(asset.id)}
                      icon={
                        <MenuIconWrapper>
                          <MenuIcon />
                        </MenuIconWrapper>
                      }
                    />
                  }
                  open={openMenu.includes(asset.id)}
                  handleClose={() => handleCloseMenu(asset.id)}
                  menuStyle={menuStyles(theme)}
                >
                  <MenuItem>
                    <FillSvgWrapper>
                      <DownloadIcon />
                    </FillSvgWrapper>
                    <MenuText>Download</MenuText>
                  </MenuItem>
                  <MenuItem>
                    <StrokeSvgWrapper>
                      <RenameAssetIcon />
                    </StrokeSvgWrapper>
                    <MenuText>Rename</MenuText>
                  </MenuItem>
                  <MenuItem>
                    <StrokeSvgWrapper>
                      <ShareAssetIcon />
                    </StrokeSvgWrapper>
                    <MenuText>Share</MenuText>
                  </MenuItem>
                  <MenuItem>
                    <FillSvgWrapper>
                      <TrashIcon />
                    </FillSvgWrapper>
                    <MenuText>Detele</MenuText>
                  </MenuItem>
                </ActionMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

const Card = styled.div`
  border-radius: 16px;
  padding: 12px 24px;
  background: ${({ theme }) => theme.tableBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  max-width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 0 10px;
  }
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 700px;
`;

const TableHeader = styled.thead``;

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.tableDivider};
`;

const HeaderText = styled.span`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const SortIconWrapper = styled.span`
  margin-left: 4px;
  cursor: pointer;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled.td`
  padding: 6px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.tableDivider};
  vertical-align: middle;
  position: relative;
`;

const MenuIconWrapper = styled.div`
  & svg > path {
    fill: ${({ theme }) => theme.primaryText};
  }
`;

const TableText = styled.span`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  display: inline-block;
  vertical-align: middle;
`;

const AssetTypeImage = styled.img`
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
`;

const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const FillSvgWrapper = styled.div`
  & svg > path {
    fill: ${({ theme }) => theme.primaryText};
  }
`;

const StrokeSvgWrapper = styled.div`
  & svg > path {
    stroke: ${({ theme }) => theme.primaryText};
  }
`;

const MenuText = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const menuStyles = (theme: any) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  width: "auto",
  minWidth: "122px",
  padding: "12px",
  borderRadius: "12px",
  boxShadow: theme.cardShadow,
  background: theme.primaryBackground,
  border: "none",
});

export default AssetsTable;
