export const HelpIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2273_2826)">
      <path
        d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 5.24963C5.25 4.90351 5.35273 4.56517 5.5452 4.27738C5.73766 3.9896 6.01123 3.7653 6.33129 3.63284C6.65135 3.50039 7.00354 3.46574 7.34331 3.53326C7.68309 3.60078 7.99519 3.76745 8.24016 4.0122C8.48512 4.25694 8.65194 4.56876 8.71953 4.90822C8.78711 5.24769 8.75243 5.59955 8.61985 5.91932C8.48728 6.23909 8.26277 6.5124 7.97472 6.70469C7.68668 6.89698 7.34802 6.99962 7.00159 6.99962V8.16628"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.4585C6.86193 10.4585 6.75 10.3466 6.75 10.2085C6.75 10.0704 6.86193 9.9585 7 9.9585"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.4585C7.13807 10.4585 7.25 10.3466 7.25 10.2085C7.25 10.0704 7.13807 9.9585 7 9.9585"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2273_2826">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
