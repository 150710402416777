import {
  IImage,
  ImagesModules,
  GalleryImage,
  PromptToVideoList,
  GalleryVideo,
  AIImagesGroupedRecords,
} from "../../types/image";
import { StoreType } from "../../types/store";
import {
  CLEAR_GALLERY_IMAGES,
  CLEAR_GALLERY_IMAGES_SEARCH,
  CLEAR_HISTORY_IMAGES,
  CLEAR_HISTORY_IMAGES_SEARCH,
  DELETE_GALLERY_IMAGE_SERVER,
  GET_ALL_GALLERY_IMAGES_SERVER,
  GET_ALL_HISTORY_IMAGES_SERVER,
  SEARCH_GALLERY_IMAGES_LOADING,
  SEARCH_HISTORY_IMAGES_LOADING,
  SET_PAGE_HISTORY_IMAGES,
  UPDATE_GENERATED_IMAGES,
  UPDATE_HAS_MORE_HISTORY_IMAGES,
  UPDATE_IMAGES_LOADING,
  UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER,
  UPDATE_DOWNLOAD_ALL_IMAGES_LOADING,
  UPDATE_HAS_MORE_GALLERY_IMAGES,
  SET_PAGE_GALLERY_IMAGES,
  INPAINT_RENDER_SERVER,
  GET_PROMPT_TO_VIDEO_LIST_SERVER,
  CLEAR_PROMPT_TO_VIDEO_LIST,
  GET_PROMPT_TO_VIDEO_BY_ID_SERVER,
  UPDATE_HAS_MORE_PROMPT_TO_VIDEO_LIST,
  SET_PAGE_PROMPT_TO_VIDEO_LIST,
  UPDATE_HAS_MORE_GALLERY_VIDEOS,
  SET_PAGE_GALLERY_VIDEOS,
  GET_ALL_GALLERY_VIDEOS_SERVER,
  CLEAR_GALLERY_VIDEOS,
  SEARCH_GALLERY_VIDEOS_LOADING,
  CLEAR_GALLERY_VIDEOS_SEARCH,
  DELETE_GALLERY_VIDEO_SERVER,
  UPSCALE_IMAGE_SERVER,
  UPSCALE_VIDEO_SERVER,
  SWAP_FACE_VIDEO_SERVER,
  UPDATE_FACE_SWAP_IS_LOADING,
  GET_SWAPPED_FACE_VIDEO_SERVER,
  GET_LIPS_SYNCED_VIDEO_SERVER,
  SYNC_LIPS_VIDEO_SERVER,
  CLEAR_IMAGE_FROM_MODULE,
  UPDATE_HAS_MORE_AI_IMAGES_GROUPED_RECORDS,
  GET_AI_IMAGES_GROUPED_RECORDS_SERVER,
  CLEAR_AI_IMAGES_GROUPED_RECORDS,
  DELETE_AI_IMAGES_GROUPED_RECORD_SERVER,
} from "../actions/imagesActions";

export interface imagesStateType {
  [ImagesModules.generatedImages]: {
    isLoading: boolean;
    data: IImage[];
  };
  [ImagesModules.historyImages]: {
    data: IImage[];
    search: string;
    hasMore: boolean;
    pageNumber: number;
    isLoading: boolean;
    searchLoading: boolean;
    totalPages: number;
    totalRecords: number;
  };
  [ImagesModules.galleryImages]: {
    data: GalleryImage[];
    search: string;
    hasMore: boolean;
    pageNumber: number;
    isLoading: boolean;
    searchLoading: boolean;
    isDelete: boolean;
  };
  [ImagesModules.aiImagesGroupedRecords]: {
    data: AIImagesGroupedRecords[];
    hasMore: boolean;
    pageNumber: number;
    isLoading: boolean;
    isDelete: boolean;
    isSortLoading: boolean;
  };
  [ImagesModules.uploadImageToVisualGallery]: {
    uploadedImage: string;
    isLoading: boolean;
  };
  [ImagesModules.downloadAllImages]: {
    isLoading: boolean;
  };
  [ImagesModules.imageGallery]: {
    isLoading: boolean;
  };
  [ImagesModules.inPaint]: {
    inPaintImage: string;
    isLoading: boolean;
  };
  [ImagesModules.promptToVideo]: {
    data: PromptToVideoList[];
    search: string;
    hasMore: boolean;
    pageNumber: number;
    isLoading: boolean;
    totalPages: number;
    totalRecords: number;
  };
  [ImagesModules.generatedVideo]: {
    data: PromptToVideoList | null;
    isLoading: boolean;
  };
  [ImagesModules.galleryVideos]: {
    data: GalleryVideo[];
    search: string;
    hasMore: boolean;
    pageNumber: number;
    isLoading: boolean;
    searchLoading: boolean;
    isDelete: boolean;
    isSortLoading: boolean;
  };
  [ImagesModules.videoGallery]: {
    isLoading: boolean;
  };
  [ImagesModules.upscaleImage]: {
    isLoading: boolean;
  };
  [ImagesModules.upscaleVideo]: {
    isLoading: boolean;
  };
  [ImagesModules.storyReferenceImage]: {
    isLoading: boolean;
    uploadedImage: string;
  };
  [ImagesModules.personalizeImage]: {
    isLoading: boolean;
    uploadedImage: string;
  };
  [ImagesModules.faceSwapImage]: {
    isLoading: boolean;
    isVideoBeingProcessed: boolean;
    uploadedImage: string;
  };
  [ImagesModules.lipsync]: {
    isLoading: boolean;
  };
  [ImagesModules.poseImage]: {
    isLoading: boolean;
    uploadedImage: string;
  };
}

const images: imagesStateType = {
  [ImagesModules.generatedImages]: {
    isLoading: false,
    data: [],
  },
  [ImagesModules.historyImages]: {
    data: [],
    search: "",
    hasMore: true,
    pageNumber: 0,
    isLoading: false,
    searchLoading: false,
    totalPages: 0,
    totalRecords: 0,
  },
  [ImagesModules.galleryImages]: {
    data: [],
    search: "",
    hasMore: true,
    pageNumber: 0,
    isLoading: false,
    searchLoading: false,
    isDelete: false,
  },
  [ImagesModules.aiImagesGroupedRecords]: {
    data: [],
    hasMore: true,
    pageNumber: 0,
    isLoading: false,
    isDelete: false,
    isSortLoading: false,
  },
  [ImagesModules.uploadImageToVisualGallery]: {
    uploadedImage: "",
    isLoading: false,
  },
  [ImagesModules.downloadAllImages]: {
    isLoading: false,
  },
  [ImagesModules.imageGallery]: {
    isLoading: false,
  },
  [ImagesModules.inPaint]: {
    inPaintImage: "",
    isLoading: false,
  },
  [ImagesModules.promptToVideo]: {
    data: [],
    search: "",
    hasMore: true,
    pageNumber: 0,
    isLoading: false,
    totalPages: 0,
    totalRecords: 0,
  },
  [ImagesModules.generatedVideo]: {
    data: null,
    isLoading: false,
  },
  [ImagesModules.galleryVideos]: {
    data: [],
    search: "",
    hasMore: true,
    pageNumber: 0,
    isLoading: false,
    searchLoading: false,
    isDelete: false,
    isSortLoading: false,
  },
  [ImagesModules.videoGallery]: {
    isLoading: false,
  },
  [ImagesModules.upscaleImage]: {
    isLoading: false,
  },
  [ImagesModules.upscaleVideo]: {
    isLoading: false,
  },
  [ImagesModules.storyReferenceImage]: {
    isLoading: false,
    uploadedImage: "",
  },
  [ImagesModules.personalizeImage]: {
    isLoading: false,
    uploadedImage: "",
  },
  [ImagesModules.faceSwapImage]: {
    isLoading: false,
    isVideoBeingProcessed: false,
    uploadedImage: "",
  },
  [ImagesModules.lipsync]: {
    isLoading: false,
  },
  [ImagesModules.poseImage]: {
    isLoading: false,
    uploadedImage: "",
  },
};

const imagesReducer = (state = images, action: any) => {
  switch (action.type) {
    case UPDATE_GENERATED_IMAGES: {
      return {
        ...state,
        [ImagesModules.generatedImages]: { ...state[ImagesModules.generatedImages], data: action.payload.data },
      };
    }

    case UPDATE_IMAGES_LOADING: {
      const { module, isLoading } = action.payload;

      return { ...state, [module]: { ...state[module as ImagesModules], isLoading } };
    }

    case UPDATE_HAS_MORE_HISTORY_IMAGES: {
      const { hasMore } = action.payload;

      return {
        ...state,
        [ImagesModules.historyImages]: {
          ...state[ImagesModules.historyImages],
          hasMore,
        },
      };
    }

    case UPDATE_HAS_MORE_PROMPT_TO_VIDEO_LIST: {
      const { hasMore } = action.payload;

      return {
        ...state,
        [ImagesModules.promptToVideo]: {
          ...state[ImagesModules.promptToVideo],
          hasMore,
        },
      };
    }

    case SET_PAGE_HISTORY_IMAGES: {
      const { pageNumber } = action.payload;

      return {
        ...state,
        [ImagesModules.historyImages]: {
          ...state[ImagesModules.historyImages],
          pageNumber,
        },
      };
    }

    case SET_PAGE_PROMPT_TO_VIDEO_LIST: {
      const { pageNumber } = action.payload;

      return {
        ...state,
        [ImagesModules.promptToVideo]: {
          ...state[ImagesModules.promptToVideo],
          pageNumber,
        },
      };
    }

    case GET_ALL_HISTORY_IMAGES_SERVER: {
      return {
        ...state,
        [ImagesModules.historyImages]: {
          ...state[ImagesModules.historyImages],
          hasMore: true,
        },
      };
    }

    case `${GET_ALL_HISTORY_IMAGES_SERVER}_SUCCESS`: {
      const { isHistoryPage } = action.meta.previousAction.payload;
      const { pageNumber, data, totalPages, totalRecords } = action.payload.data;

      return {
        ...state,
        [ImagesModules.historyImages]: {
          ...state[ImagesModules.historyImages],
          data: !isHistoryPage
            ? pageNumber === 1
              ? data
              : [...state[ImagesModules.historyImages].data, ...data]
            : data,
          search: action.meta.previousAction.payload.request.data.keyword,
          pageNumber: !isHistoryPage ? state[ImagesModules.historyImages].pageNumber + 1 : pageNumber,
          totalPages,
          totalRecords,
        },
      };
    }

    case UPDATE_HAS_MORE_GALLERY_IMAGES: {
      const { hasMore } = action.payload;

      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          hasMore,
        },
      };
    }

    case UPDATE_HAS_MORE_AI_IMAGES_GROUPED_RECORDS: {
      const { hasMore } = action.payload;

      return {
        ...state,
        [ImagesModules.aiImagesGroupedRecords]: {
          ...state[ImagesModules.aiImagesGroupedRecords],
          hasMore,
        },
      };
    }

    case UPDATE_HAS_MORE_GALLERY_VIDEOS: {
      const { hasMore } = action.payload;

      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          hasMore,
        },
      };
    }

    case SET_PAGE_GALLERY_IMAGES: {
      const { pageNumber } = action.payload;

      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          pageNumber,
        },
      };
    }

    case SET_PAGE_GALLERY_VIDEOS: {
      const { pageNumber } = action.payload;

      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          pageNumber,
        },
      };
    }

    case GET_ALL_GALLERY_IMAGES_SERVER: {
      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          hasMore: true,
        },
      };
    }

    case `${GET_ALL_GALLERY_IMAGES_SERVER}_SUCCESS`: {
      const { pageNumber, data } = action.payload.data;

      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          data: pageNumber === 1 ? data : [...state[ImagesModules.galleryImages].data, ...data],
          search: action.meta.previousAction.payload.request.data.keyword,
          pageNumber: state[ImagesModules.galleryImages].pageNumber + 1,
        },
      };
    }

    case GET_AI_IMAGES_GROUPED_RECORDS_SERVER: {
      const { isSortLoading } = action.payload.request;

      return {
        ...state,
        [ImagesModules.aiImagesGroupedRecords]: {
          ...state[ImagesModules.aiImagesGroupedRecords],
          isSortLoading: isSortLoading || false,
        },
      };
    }

    case `${GET_AI_IMAGES_GROUPED_RECORDS_SERVER}_SUCCESS`: {
      const { pageNumber, data } = action.payload.data;

      return {
        ...state,
        [ImagesModules.aiImagesGroupedRecords]: {
          ...state[ImagesModules.aiImagesGroupedRecords],
          data: pageNumber === 1 ? data : [...state[ImagesModules.aiImagesGroupedRecords].data, ...data],
          pageNumber: state[ImagesModules.aiImagesGroupedRecords].pageNumber + 1,
          isSortLoading: false,
        },
      };
    }

    case `${GET_AI_IMAGES_GROUPED_RECORDS_SERVER}_FAIL`: {
      return {
        ...state,
        [ImagesModules.aiImagesGroupedRecords]: {
          ...state[ImagesModules.aiImagesGroupedRecords],
          isSortLoading: false,
        },
      };
    }

    case GET_ALL_GALLERY_VIDEOS_SERVER: {
      const { isSortLoading } = action.payload.request;

      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          hasMore: true,
          isSortLoading: isSortLoading || false,
        },
      };
    }

    case `${GET_ALL_GALLERY_VIDEOS_SERVER}_SUCCESS`: {
      const { pageNumber, data } = action.payload.data;

      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          data: pageNumber === 1 ? data : [...state[ImagesModules.galleryVideos].data, ...data],
          search: action.meta.previousAction.payload.request.data.keyword,
          pageNumber: state[ImagesModules.galleryVideos].pageNumber + 1,
          isSortLoading: false,
        },
      };
    }

    case `${GET_ALL_GALLERY_VIDEOS_SERVER}_FAIL`: {
      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          isSortLoading: false,
        },
      };
    }

    case CLEAR_HISTORY_IMAGES: {
      return {
        ...state,
        [ImagesModules.historyImages]: {
          ...state[ImagesModules.historyImages],
          data: [],
          pageNumber: 0,
        },
      };
    }

    case CLEAR_PROMPT_TO_VIDEO_LIST: {
      return {
        ...state,
        [ImagesModules.promptToVideo]: {
          ...state[ImagesModules.promptToVideo],
          data: [],
          pageNumber: 0,
        },
      };
    }

    case CLEAR_GALLERY_IMAGES: {
      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          data: [],
          pageNumber: 0,
        },
      };
    }

    case CLEAR_AI_IMAGES_GROUPED_RECORDS: {
      return {
        ...state,
        [ImagesModules.aiImagesGroupedRecords]: {
          ...state[ImagesModules.aiImagesGroupedRecords],
          data: [],
          pageNumber: 0,
        },
      };
    }

    case CLEAR_GALLERY_VIDEOS: {
      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          data: [],
          pageNumber: 0,
        },
      };
    }

    case SEARCH_HISTORY_IMAGES_LOADING: {
      return {
        ...state,
        [ImagesModules.historyImages]: {
          ...state[ImagesModules.historyImages],
          searchLoading: action.payload,
        },
      };
    }

    case SEARCH_GALLERY_IMAGES_LOADING: {
      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          searchLoading: action.payload,
        },
      };
    }

    case SEARCH_GALLERY_VIDEOS_LOADING: {
      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          searchLoading: action.payload,
        },
      };
    }

    case CLEAR_HISTORY_IMAGES_SEARCH: {
      return {
        ...state,
        [ImagesModules.historyImages]: {
          ...state[ImagesModules.historyImages],
          search: "",
        },
      };
    }

    case CLEAR_GALLERY_IMAGES_SEARCH: {
      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          search: "",
        },
      };
    }

    case CLEAR_GALLERY_VIDEOS_SEARCH: {
      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          search: "",
        },
      };
    }

    case UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER: {
      const { module = ImagesModules.uploadImageToVisualGallery } = action.payload;

      return {
        ...state,
        [module]: {
          ...state[module as ImagesModules],
          uploadedImage: "",
        },
      };
    }

    case CLEAR_IMAGE_FROM_MODULE: {
      const { module } = action.payload;

      return {
        ...state,
        [module]: {
          ...state[module as ImagesModules],
          uploadedImage: "",
        },
      };
    }

    case `${UPLOAD_IMAGE_TO_VISUAL_GALLERY_SERVER}_SUCCESS`: {
      const { module = ImagesModules.uploadImageToVisualGallery } = action.meta.previousAction.payload;

      return {
        ...state,
        [module]: {
          ...state[module as ImagesModules],
          uploadedImage: action.payload.data.data.path,
        },
      };
    }

    case `${INPAINT_RENDER_SERVER}_SUCCESS`: {
      const { isEdit } = action.meta.previousAction.payload;

      return {
        ...state,
        [ImagesModules.inPaint]: {
          ...state[ImagesModules.inPaint],
          inPaintImage: isEdit ? action.payload.data.data[0].path : "",
        },
      };
    }

    case DELETE_GALLERY_IMAGE_SERVER: {
      const { id } = action.payload;
      const data = state[ImagesModules.galleryImages].data.filter(({ visualGalleryID }) => visualGalleryID !== id);

      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          data,
          isDelete: true,
        },
      };
    }

    case DELETE_AI_IMAGES_GROUPED_RECORD_SERVER: {
      const { id } = action.payload;

      const data = state[ImagesModules.aiImagesGroupedRecords].data
        .map((group) => ({
          ...group,
          records: group.records.filter((record) => record.visualHistoryID !== id),
        }))
        .filter((group) => group.records.length > 0);

      return {
        ...state,
        [ImagesModules.aiImagesGroupedRecords]: {
          ...state[ImagesModules.aiImagesGroupedRecords],
          data,
          isDelete: true,
        },
      };
    }

    case DELETE_GALLERY_VIDEO_SERVER: {
      const { id } = action.payload;
      const data = state[ImagesModules.galleryVideos].data.filter(({ videoGalleryID }) => videoGalleryID !== id);

      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          data,
          isDelete: true,
        },
      };
    }

    case `${DELETE_GALLERY_IMAGE_SERVER}_FAIL`:
    case `${DELETE_GALLERY_IMAGE_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ImagesModules.galleryImages]: {
          ...state[ImagesModules.galleryImages],
          isDelete: false,
        },
      };
    }

    case `${DELETE_AI_IMAGES_GROUPED_RECORD_SERVER}_FAIL`:
    case `${DELETE_AI_IMAGES_GROUPED_RECORD_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ImagesModules.aiImagesGroupedRecords]: {
          ...state[ImagesModules.aiImagesGroupedRecords],
          isDelete: false,
        },
      };
    }

    case `${DELETE_GALLERY_VIDEO_SERVER}_FAIL`:
    case `${DELETE_GALLERY_VIDEO_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ImagesModules.galleryVideos]: {
          ...state[ImagesModules.galleryVideos],
          isDelete: false,
        },
      };
    }

    case UPDATE_DOWNLOAD_ALL_IMAGES_LOADING: {
      const { module, isLoading } = action.payload;

      return { ...state, [module]: { ...state[module as ImagesModules], isLoading } };
    }

    case `${GET_PROMPT_TO_VIDEO_LIST_SERVER}_SUCCESS`: {
      const { pageNumber, data, totalPages, totalRecords } = action.payload.data;

      return {
        ...state,
        [ImagesModules.promptToVideo]: {
          ...state[ImagesModules.promptToVideo],
          data: pageNumber === 1 ? data : [...state[ImagesModules.promptToVideo].data, ...data],
          search: action.meta.previousAction.payload.request.data.keyword,
          pageNumber: state[ImagesModules.promptToVideo].pageNumber + 1,
          totalPages,
          totalRecords,
        },
      };
    }

    case `${GET_PROMPT_TO_VIDEO_BY_ID_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      if (data.status === 4) {
        return { ...state, [ImagesModules.generatedVideo]: { ...state[ImagesModules.generatedVideo], data: null } };
      }

      return {
        ...state,
        [ImagesModules.generatedVideo]: { ...state[ImagesModules.generatedVideo], data },
      };
    }

    case `${UPSCALE_IMAGE_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;
      const { id } = action.meta.previousAction.payload;

      const newData = state[ImagesModules.generatedImages].data.map((image) =>
        image.visualHistoryID === id ? { ...image, path: data } : image,
      );

      return {
        ...state,
        [ImagesModules.generatedImages]: { ...state[ImagesModules.generatedImages], data: newData },
      };
    }

    case `${UPSCALE_VIDEO_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [ImagesModules.generatedVideo]: {
          ...state[ImagesModules.generatedVideo],
          data: { ...state[ImagesModules.generatedVideo].data, outputUrl: data },
        },
      };
    }

    case `${SWAP_FACE_VIDEO_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [ImagesModules.generatedVideo]: {
          ...state[ImagesModules.generatedVideo],
          data: { ...state[ImagesModules.generatedVideo].data, ...data },
        },
      };
    }

    case `${GET_SWAPPED_FACE_VIDEO_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [ImagesModules.generatedVideo]: {
          ...state[ImagesModules.generatedVideo],
          data: { ...state[ImagesModules.generatedVideo].data, ...data },
        },
      };
    }

    case UPDATE_FACE_SWAP_IS_LOADING: {
      const isLoading = action.payload;

      return {
        ...state,
        [ImagesModules.faceSwapImage]: {
          ...state[ImagesModules.faceSwapImage],
          isVideoBeingProcessed: isLoading,
        },
      };
    }

    case `${GET_LIPS_SYNCED_VIDEO_SERVER}_SUCCESS`:
    case `${SYNC_LIPS_VIDEO_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [ImagesModules.generatedVideo]: {
          ...state[ImagesModules.generatedVideo],
          data: { ...state[ImagesModules.generatedVideo].data, ...data },
        },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getGeneratedImages = (state: StoreType) => state.images[ImagesModules.generatedImages].data;
export const getGeneratedImagesLoading = (state: StoreType) => state.images[ImagesModules.generatedImages].isLoading;

export const getGeneratedVideos = (state: StoreType) => state.images[ImagesModules.generatedVideo].data;
export const getGeneratedVideosLoading = (state: StoreType) => state.images[ImagesModules.generatedVideo].isLoading;

export const getHistoryImages = (state: StoreType) => state.images[ImagesModules.historyImages].data;
export const getHistoryImagesLoading = (state: StoreType) => state.images[ImagesModules.historyImages].isLoading;
export const getHasMoreHistoryImages = (state: StoreType) => state.images[ImagesModules.historyImages].hasMore;
export const getCurrentPageHistoryImages = (state: StoreType) => state.images[ImagesModules.historyImages].pageNumber;
export const getSearchHistoryImagesLoading = (state: StoreType) =>
  state.images[ImagesModules.historyImages].searchLoading;
export const getHistoryImagesTotalPages = (state: StoreType) => state.images[ImagesModules.historyImages].totalPages;
export const getHistoryImagesTotalRecords = (state: StoreType) =>
  state.images[ImagesModules.historyImages].totalRecords;

export const getPromptToVideoList = (state: StoreType) => state.images[ImagesModules.promptToVideo].data;
export const getPromptToVideoListLoading = (state: StoreType) => state.images[ImagesModules.promptToVideo].isLoading;
export const getHasMorePromptToVideoList = (state: StoreType) => state.images[ImagesModules.promptToVideo].hasMore;
export const getCurrentPagePromptToVideoList = (state: StoreType) =>
  state.images[ImagesModules.promptToVideo].pageNumber;
export const getPromptToVideoListTotalPages = (state: StoreType) =>
  state.images[ImagesModules.promptToVideo].totalPages;
export const getPromptToVideoListTotalRecords = (state: StoreType) =>
  state.images[ImagesModules.promptToVideo].totalRecords;

export const getGalleryImages = (state: StoreType) => state.images[ImagesModules.galleryImages].data;
export const getGalleryImagesLoading = (state: StoreType) => state.images[ImagesModules.galleryImages].isLoading;
export const getHasMoreGalleryImages = (state: StoreType) => state.images[ImagesModules.galleryImages].hasMore;
export const getCurrentPageGalleryImages = (state: StoreType) => state.images[ImagesModules.galleryImages].pageNumber;
export const getSearchGalleryImagesLoading = (state: StoreType) =>
  state.images[ImagesModules.galleryImages].searchLoading;
export const getGalleryImageDeleteLoading = (state: StoreType) => state.images[ImagesModules.galleryImages].isDelete;

export const getAIImagesGroupedRecords = (state: StoreType) => state.images[ImagesModules.aiImagesGroupedRecords].data;
export const getAIImagesGroupedRecordsLoading = (state: StoreType) =>
  state.images[ImagesModules.aiImagesGroupedRecords].isLoading;
export const getHasMoreAIImagesGroupedRecords = (state: StoreType) =>
  state.images[ImagesModules.aiImagesGroupedRecords].hasMore;
export const getCurrentPageAIImagesGroupedRecords = (state: StoreType) =>
  state.images[ImagesModules.aiImagesGroupedRecords].pageNumber;
export const getAIImagesGroupedRecordDeleteLoading = (state: StoreType) =>
  state.images[ImagesModules.aiImagesGroupedRecords].isDelete;
export const getAIImagesGroupedRecordsSortLoading = (state: StoreType) =>
  state.images[ImagesModules.aiImagesGroupedRecords].isSortLoading;

export const getGalleryVideos = (state: StoreType) => state.images[ImagesModules.galleryVideos].data;
export const getGalleryVideosLoading = (state: StoreType) => state.images[ImagesModules.galleryVideos].isLoading;
export const getHasMoreGalleryVideos = (state: StoreType) => state.images[ImagesModules.galleryVideos].hasMore;
export const getCurrentPageGalleryVideos = (state: StoreType) => state.images[ImagesModules.galleryVideos].pageNumber;
export const getSearchGalleryVideosLoading = (state: StoreType) =>
  state.images[ImagesModules.galleryVideos].searchLoading;
export const getGalleryVideoDeleteLoading = (state: StoreType) => state.images[ImagesModules.galleryVideos].isDelete;
export const getGalleryVideoSortLoading = (state: StoreType) => state.images[ImagesModules.galleryVideos].isSortLoading;

export const getUploadedImage = (state: StoreType) =>
  state.images[ImagesModules.uploadImageToVisualGallery].uploadedImage;
export const getUploadImageToVisualGalleryLoading = (state: StoreType) =>
  state.images[ImagesModules.uploadImageToVisualGallery].isLoading;

export const getUploadStoryReferenceImage = (state: StoreType) =>
  state.images[ImagesModules.storyReferenceImage].uploadedImage;
export const getUploadStoryReferenceImageLoading = (state: StoreType) =>
  state.images[ImagesModules.storyReferenceImage].isLoading;

export const getUploadPersonalizeImage = (state: StoreType) =>
  state.images[ImagesModules.personalizeImage].uploadedImage;
export const getUploadPersonalizeImageLoading = (state: StoreType) =>
  state.images[ImagesModules.personalizeImage].isLoading;

export const getUploadFaceSwapImage = (state: StoreType) => state.images[ImagesModules.faceSwapImage].uploadedImage;
export const getUploadFaceSwapImageLoading = (state: StoreType) => state.images[ImagesModules.faceSwapImage].isLoading;
export const getFaceSwapProcessLoading = (state: StoreType) =>
  state.images[ImagesModules.faceSwapImage].isVideoBeingProcessed;
export const getUploadPoseImage = (state: StoreType) => state.images[ImagesModules.poseImage].uploadedImage;
export const getUploadPoseImageLoading = (state: StoreType) => state.images[ImagesModules.poseImage].isLoading;

export const getInPaintImage = (state: StoreType) => state.images[ImagesModules.inPaint].inPaintImage;
export const getInPaintGenerationLoading = (state: StoreType) => state.images[ImagesModules.inPaint].isLoading;
export const getDownloadImagesLoading = (state: StoreType) => state.images[ImagesModules.downloadAllImages].isLoading;

export const getImageGalleryLoading = (state: StoreType) => state.images[ImagesModules.imageGallery].isLoading;
export const getVideoGalleryLoading = (state: StoreType) => state.images[ImagesModules.videoGallery].isLoading;

export const getUpscaleImageLoading = (state: StoreType) => state.images[ImagesModules.upscaleImage].isLoading;
export const getUpscaleVideoLoading = (state: StoreType) => state.images[ImagesModules.upscaleVideo].isLoading;

export const getLipsSyncedVideoLoading = (state: StoreType) => state.images[ImagesModules.lipsync].isLoading;

export default imagesReducer;
